import {useEffect, useState} from "react";
import {OrgaAndRoles} from "../models/OrgaAndRoles";
import {getData} from "../utils/storage/Storage";
import {StorageKey} from "../utils/storage/Keys";

export function useSelectedOrganisation() {
    const [selectedOrganisation, setSelectedOrganisation] = useState<OrgaAndRoles | undefined>(undefined);

    useEffect(() => {
        let selectedOrganisation: OrgaAndRoles = JSON.parse(
            getData(StorageKey.SELECTED_ORGANISATION) as string
        );
        setSelectedOrganisation(selectedOrganisation);
    }, []);

    return selectedOrganisation;
}
