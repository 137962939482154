import {StorageKey} from "./Keys";

export function getData(key: StorageKey) {
    return localStorage.getItem(String(key));
}

export function storeData(key: StorageKey, value: any) {
    localStorage.setItem(String(key), String(value));
}

export function deleteData(key: StorageKey) {
    localStorage.removeItem(key);
}
