export default function PaymentSuccessful() {
    return (<div className="page page-center">
        <div className="container-tight py-4">
            <div className="empty">
                <div className="empty-header">Gastkarte erfolgreich erworben</div>
                <p className="empty-title">Bald kann es los gehen ans Wasser</p>
                <p className="empty-subtitle text-muted">
                    Deine Gastkarte wird dir in den nächsten Minuten an die von dir angegebene E-Mail Adresse geschickt.
                    Bitte überprüf auch deinen Spamordner.
                </p>
            </div>
        </div>
    </div>);
}
