import DocumentDTO from "../../../dtos/DocumentDTO";
import { Button } from "react-bootstrap";
import DeleteDialog from "../../../components/DeleteDialog";
import { useEffect, useState } from "react";
import { NetworkCalls } from "../../../NetworkCalls";

export default function Document({
  loading,
  documentDTO,
  guestCardEnabled,
  onDocumentDeleted,
}: {
  loading: boolean;
  documentDTO: DocumentDTO;
  guestCardEnabled: boolean;
  onDocumentDeleted: (dto: DocumentDTO) => void;
}) {
  const [showDeleteDialog, setShowDeleteDialog] = useState<boolean>(false);
  const [internDocument, setInternDocument] = useState<
    DocumentDTO | undefined
  >();

  useEffect(() => {
    setInternDocument(documentDTO);
  }, [documentDTO]);

  const getFileImage = (dto: DocumentDTO) => {
    switch (dto.fileType) {
      case "image/png":
      case "image/jpeg":
      case "image/gif":
        return (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="icon icon-tabler icon-tabler-photo"
            width="44"
            height="44"
            viewBox="0 0 24 24"
            strokeWidth="1.5"
            stroke="#ffffff"
            fill="none"
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
            <path d="M15 8h.01" />
            <path d="M3 6a3 3 0 0 1 3 -3h12a3 3 0 0 1 3 3v12a3 3 0 0 1 -3 3h-12a3 3 0 0 1 -3 -3v-12z" />
            <path d="M3 16l5 -5c.928 -.893 2.072 -.893 3 0l5 5" />
            <path d="M14 14l1 -1c.928 -.893 2.072 -.893 3 0l3 3" />
          </svg>
        );
      case "application/pdf":
        return (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="icon icon-tabler icon-tabler-file-type-pdf"
            width="44"
            height="44"
            viewBox="0 0 24 24"
            strokeWidth="1.5"
            stroke="#ff2825"
            fill="none"
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
            <path d="M14 3v4a1 1 0 0 0 1 1h4" />
            <path d="M5 12v-7a2 2 0 0 1 2 -2h7l5 5v4" />
            <path d="M5 18h1.5a1.5 1.5 0 0 0 0 -3h-1.5v6" />
            <path d="M17 18h2" />
            <path d="M20 15h-3v6" />
            <path d="M11 15v6h1a2 2 0 0 0 2 -2v-2a2 2 0 0 0 -2 -2h-1z" />
          </svg>
        );
      default:
        return (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="icon icon-tabler icon-tabler-file-neutral"
            width="44"
            height="44"
            viewBox="0 0 24 24"
            strokeWidth="1.5"
            stroke="#ffffff"
            fill="none"
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
            <path d="M14 3v4a1 1 0 0 0 1 1h4" />
            <path d="M17 21h-10a2 2 0 0 1 -2 -2v-14a2 2 0 0 1 2 -2h7l5 5v11a2 2 0 0 1 -2 2zm-7 -7h.01m3.99 0h.01m-4.01 3h4" />
          </svg>
        );
    }
  };

  const loadingAnimation = () => {
    return (
      <li className="list-group-item">
        <div className="row align-items-center" key={"1"}>
          <div className="col-auto">
            <div className="avatar avatar-rounded placeholder"></div>
          </div>
          <div className="col-7" key={"2"}>
            <div className="placeholder placeholder-xs col-9"></div>
            <div className="placeholder placeholder-xs col-7"></div>
          </div>
          <div className="col-2 ms-auto text-end" key={"3"}>
            <div className="placeholder placeholder-xs col-8"></div>
            <div className="placeholder placeholder-xs col-10"></div>
          </div>
        </div>
      </li>
    );
  };

  const formatBytes = (bytes: number) => {
    const marker = 1024; // Change to 1000 if required
    const decimal = 2; // Change as required
    const kiloBytes = marker; // One Kilobyte is 1024 bytes
    const megaBytes = marker * marker; // One MB is 1024 KB
    const gigaBytes = marker * marker * marker; // One GB is 1024 MB

    // return bytes if less than a KB
    if (bytes < kiloBytes) return bytes + " Bytes";
    // return KB if less than a MB
    else if (bytes < megaBytes)
      return (bytes / kiloBytes).toFixed(decimal) + " KB";
    // return MB if less than a GB
    else if (bytes < gigaBytes)
      return (bytes / megaBytes).toFixed(decimal) + " MB";
    // return GB if less than a TB
    else return (bytes / gigaBytes).toFixed(decimal) + " GB";
  };

  const showEntry = () => {
    if (internDocument === undefined) {
      return <></>;
    }
    return (
      <div className="list-group-item" key={internDocument.id}>
        <div className="row">
          <div className="col-1">
            <span className="avatar">{getFileImage(internDocument)}</span>
          </div>
          <div className="col-5 text-truncate">
            <a
              href={internDocument.url}
              className="text-body d-block"
              target="_blank"
              rel="noopener noreferrer"
            >
              {internDocument.downloadFileName}
            </a>
            <div className="text-secondary text-truncate mt-n1">
              {formatBytes(internDocument.fileSize)}
            </div>
          </div>
          <div className="col-5 text-secondary text-truncate mt-n1">
            {guestCardEnabled ? (
              <>
                <span className={"pe-1"}>
                  Soll das Dokument mit dem Kauf einer Gastkarte verschickt
                  werden?
                </span>
                <input
                  type={"checkbox"}
                  className={"form-check-input"}
                  checked={internDocument.sendWithGuestCard}
                  onChange={async (event) => {
                    event.preventDefault();
                    event.stopPropagation();
                    documentDTO.sendWithGuestCard = event.target.checked;
                    setInternDocument(
                      await NetworkCalls.updateDocument(documentDTO),
                    );
                  }}
                />
              </>
            ) : (
              ""
            )}
          </div>
          <div className="col-1">
            <Button
              className="btn btn-danger"
              onClick={() => setShowDeleteDialog(true)}
            >
              Löschen
            </Button>
            <DeleteDialog
              onDeleteConfirm={() => {
                NetworkCalls.deleteDocument(documentDTO).then(() => {
                  onDocumentDeleted(documentDTO);
                });
              }}
              onHandleClose={() => setShowDeleteDialog(false)}
              title={"Datensatz wirklich löschen?"}
              message={`Soll die Datei wirklich gelöscht werden`}
              show={showDeleteDialog}
            />
          </div>
        </div>
      </div>
    );
  };

  return <>{loading ? loadingAnimation() : showEntry()}</>;
}
