import { useParams } from 'react-router';
import { useNavigate } from 'react-router-dom';
import { ChangeEvent, FormEvent, useEffect, useState } from 'react';
import { Button, Card, Col, Form, Row } from 'react-bootstrap';
import { NetworkCalls } from "../NetworkCalls";
import { AxiosError } from "axios";

export default function ResetPassword() {

    const params = useParams();

    const navigate = useNavigate();
    const [token, setToken] = useState<string | undefined>();
    const [loading, setLoading] = useState<boolean>(false);
    const [validated, setValidated] = useState<boolean>(false);
    const [formData, setFormData] = useState<FormData>({password1:'', password2:''});
    const [error, setError] = useState<string>('');

    interface FormData {
        password1: string;
        password2: string;
    }

    useEffect(() => {
        if (params.resetToken)
            setToken(params.resetToken as string);
    }, [ params.resetToken ]);

    const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        event.stopPropagation();

        const form = event.currentTarget;
        let checkValidity = form.checkValidity();
        setValidated(true);

        if (!checkValidity) {
            return Promise.reject('Ungültige Eingabe');
        }

        if (formData.password1 !== formData.password2) {
            return Promise.reject('Passwörter stimmen nicht überein');
        }

        setLoading(true);
        try {
            NetworkCalls.resetPassword(token!, formData.password1)
                .then(v => {
                    alert('Passwort erfolgreich geändert.')
                    console.log('going login', v);
                    return navigate('/login');
                }, x => {
                    console.error('Fehler:', x);
                    if (x instanceof AxiosError) {
                        setError(`Fehler: ${x.response?.status}`);
                    }
                    setError('Fehler');
                    setLoading(false);
                })
                .catch((r) => {
                    console.error('Fehler:', r);
                    setError('Fehler');
                    setLoading(false);
                });
        } finally {
            // ignore
        }
    };

    const onPassword1Change = (event: ChangeEvent<HTMLInputElement>) => {
        setFormData(prevState => {
            return {...prevState, password1: event.target.value } as FormData;
        });
    }

    const onPassword2Change = (event: ChangeEvent<HTMLInputElement>) => {
        setFormData(prevState => {
            return {...prevState, password2: event.target.value } as FormData;
        });
    }

    const renderBody = () => {
        if (!token) {
            return <div className='strong text-center fs-2'>
                <p className=''>Bitte schauen Sie in Ihre E-Mails.</p>
                <p>Wenn Ihre E-Mail-Adresse bei uns im System hinterlegt ist, erhalten Sie in Kürze eine E-Mail mit einem Link.</p>
                <p>Über diesen Link können Sie dann Ihr Passwort zurücksetzen.</p>
                <p>Sie können dieses Fenster jetzt schließen.</p>
            </div>
        }

        return <>
            Hier können Sie ein neues Passwort setzen.
            <Form noValidate validated={validated} onSubmit={handleSubmit}>
                <Row>
                    <Form.Group as={Col} className='py-3'>
                        <Form.Label id='password-label-1'>Ihr neues Passwort</Form.Label>
                        <Form.Control type='password' minLength={8} required={true} aria-describedby='password-label-1' onChange={onPassword1Change} />
                    </Form.Group>
                </Row>
                <Row>
                    <Form.Group as={Col} className='py-3'>
                        <Form.Label id='password-label-2'>Ihr neues Passwort wiederholen</Form.Label>
                        <Form.Control type='password' minLength={8} required={true} aria-describedby='password-label-2' onChange={onPassword2Change} />
                    </Form.Group>
                </Row>
                <Row>
                    <Form.Text as={Col} className='text-danger mb-3'>{error}</Form.Text>
                    <Button type='submit' className='py-3' disabled={loading}>
                        Neues Passwort setzen
                    </Button>
                </Row>
            </Form>
        </>
    }

    return <>
        <div className='Auth-form-container App'>
            <Card>
                <Card.Header><h1>Password zurücksetzen</h1></Card.Header>
                <Card.Body>
                    {renderBody()}
                </Card.Body>
            </Card>
        </div>
    </>
}
