import LoadingPageIndicator from "../components/LoadingPageIndicator";
import React, {forwardRef, useEffect, useState} from "react";
import {useParams} from "react-router";
import {NetworkCalls} from "../NetworkCalls";
import {GuestCard} from "../dtos/GuestCard";
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import {WaterRegulationFile} from "../dtos/WaterRegulationFile";
import {NetworkErrorHandling} from "../components/NetworkErrorHandling";
import {AxiosError} from "axios";
import {useNavigate} from "react-router-dom";

export default function GuestCardPaymentForm() {
    const params = useParams();

    const [loading, isLoading] = useState<boolean>(false);
    const [guestCards, setGuestCards] = useState<GuestCard[]>();
    const [selectedGuestCard, setSelectedGuestCard] = useState<GuestCard>();
    const [startDate, setStartDate] = useState(new Date());
    const [waterRegulationFiles, setWaterRegulationFiles] = useState<WaterRegulationFile[]>();
    const [acceptAgb, setAcceptAgb] = useState<boolean>(false);
    const navigate = useNavigate();

    useEffect(() => {
        if (params.organisationId) {
            isLoading(true);
            const fetchGuestCards = async (organisationId: string) => {
                return await NetworkCalls.getGuestCardsFromOrganisation(organisationId);
            }

            const fetchWaterRegulationFiles = async (organisationId: string) => {
                return await NetworkCalls.loadWaterRegulationFiles(organisationId);
            }

            Promise.all([fetchGuestCards(params.organisationId), fetchWaterRegulationFiles(params.organisationId)])
                .then(value => {
                    setGuestCards(value[0]);
                    setWaterRegulationFiles(value[1]);
                })
                .catch(console.error)
                .finally(() => isLoading(false));
        }
    }, [params.organisationId]);

    const renderGuestCards = () => {
        const selects = guestCards?.map(guestCard => (
            <option key={guestCard.id} value={guestCard.id}>{guestCard.description} {guestCard.price} €</option>)); // TODO format anpassen

        return (
            <div className="mb-3 ps-5 pe-5">
                <label className="form-label">Auswahl der Gastkarte</label>
                <select className="form-select" onChange={guestCardSelectionChange} id="guestCardSelection">
                    <option key="" value=""></option>
                    { guestCards && guestCards.length > 0 ? selects : '' }
                </select>
                {/*{ selectedGuestCard ? guestCardDescription() : ''}*/}
                { selectedGuestCard ? waterList() : '' }
            </div>
        );
    };

    // const guestCardDescription = () => { // TODO Beschreibung anzeigen
    //     return (
    //         <div className="mt-3">
    //             <h2>Beschreibung der Gastkarte</h2>
    //             <p>{ selectedGuestCard?.termsAndConditions }</p>
    //         </div>
    //     );
    // };

    const waterList = () => {
        return (
            <div className="accordion" id="accordion-waterlist">
                <div className="accordion-item  border-0">
                    <h2 className="accordion-header" id="heading-1">
                        <button className="accordion-button collapsed text-info" type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#collapse-waterlist" aria-expanded="false">
                            Klicke hier, um die Gewässer anzuzeigen, die mit der ausgewählten Gastkarte beangelt werden dürfen.
                        </button>
                    </h2>
                    <div id="collapse-waterlist" className="accordion-collapse collapse"
                         data-bs-parent="#accordion-waterlist">
                        <div className="accordion-body pt-0">
                            { selectedGuestCard?.waterObj.map(wo => <div>{wo.name}</div>)}
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    const guestCardSelectionChange = () => {
        let guestCardSelection = document.querySelector('#guestCardSelection') as HTMLInputElement | null;
        if (guestCardSelection) {
            let selectedGuestCardId = guestCardSelection.value;
            if (selectedGuestCardId !== '') {
                setSelectedGuestCard(guestCards?.find(guestCard => guestCard.id === selectedGuestCardId));
            } else {
                setSelectedGuestCard(undefined);
            }
        }
    };

    interface InputProps {
        onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
        value: Date;
    }

    const ExampleCustomInput = forwardRef<HTMLButtonElement, InputProps>(
        (props, ref) => {
            const {onClick, value} = props;
            return (
                <button className="btn btn-primary w-100" onClick={onClick} ref={ref}>
                    {value ? String(value) : ''}
                </button>
            );
        }
    );

    const checkHandler = () => {
        setAcceptAgb(!acceptAgb);
    }

    const renderUserInformationFormular = () => { // TODO Dokumente ausblenden, wenn keine vorhanden + Text anpassen
        return (<div>
                <div className="card-body">
                    <h2 className="card-title text-center mb-4">Deine Daten</h2>
                    <div className="alert alert-danger d-none" role="alert" id="alertInformation">
                        Bitte die Daten korrekt ausfüllen und den AGB zustimmen. Start der Gastkarte muss in der Zukunft
                        liegen.
                    </div>
                    <div className="mb-3">
                        <label className="form-label text-primary">Vorname</label>
                        <input type="text" className="form-control" placeholder="Vorname" id="firstName"/>
                    </div>
                    <div className="mb-3">
                        <label className="form-label text-primary">Nachname</label>
                        <input type="text" className="form-control" placeholder="Nachname" id="lastName"/>
                    </div>
                    <div className="mb-3">
                        <label className="form-label text-primary">Straße / Hausnummer</label>
                        <input type="text" className="form-control" placeholder="Straße" id="street"/>
                    </div>
                    <div className="mb-3">
                        <label className="form-label text-primary">PLZ / Ort</label>
                        <div className="row g-2">
                            <div className="col-3">
                                <input maxLength={5} className="form-control" placeholder="PLZ" id="plz"/>
                            </div>
                            <div className="col-9">
                                <input type="text" className="form-control" placeholder="Ort" id="city"/>
                            </div>
                        </div>
                    </div>
                    <div className="mb-3">
                        <label className="form-label text-primary">E-Mail Adresse</label>
                        <input type="email" className="form-control" placeholder="E-Mail Adresse" id="email"/>
                    </div>
                    <div className="mb-3">
                        <label className="form-label text-primary">Start der Gastkarte</label>
                        <DatePicker customInput={<ExampleCustomInput value={startDate}/>}
                                    selected={startDate}
                                    minDate={new Date()}
                                    dateFormat="dd.MM.yyyy HH:mm"
                                    showTimeSelect
                                    onChange={(date) => {
                                        setStartDate(date ?? new Date());
                                    }}
                        />
                    </div>
                    <div className="mb-3">
                        <div className="mb-2">
                            <a href="https://www.fangkarte.de/de/tos" target="_blank" rel="noreferrer">Hier geht es zu
                                den AGB</a>
                        </div>
                        <div>
                            <a href="https://www.fangkarte.de/de/privacy" target="_blank" rel="noreferrer">Hier geht es
                                zur
                                Datenschutzerklärung</a>
                        </div>

                        <div className="accordion" id="accordion-example">
                            <div className="accordion-item  border-0">
                                <h2 className="accordion-header" id="heading-1">
                                    <button className="accordion-button collapsed text-primary" type="button"
                                            data-bs-toggle="collapse"
                                            data-bs-target="#collapse-1" aria-expanded="false">
                                        Klicke hier um die Dokumente des Vereins zu sehen
                                    </button>
                                </h2>
                                <div id="collapse-1" className="accordion-collapse collapse"
                                     data-bs-parent="#accordion-example">
                                    <div className="accordion-body pt-0">
                                        {waterRegulationFiles?.map(file => <div><a href={file.url}
                                                                                   target="_blank"
                                                                                   rel="noreferrer">{file.downloadFileName}</a>
                                        </div>)}
                                    </div>
                                </div>
                            </div>

                        </div>

                        <label className="form-check">
                            <input type="checkbox" className="form-check-input" checked={acceptAgb}
                                   onChange={checkHandler}/>
                            <span className="form-check-label text-dark">Ich habe die AGB, Datenschutzerklärung und die Gewässerordnung gelesen, verstanden und bin damit einverstanden.</span>
                        </label>
                    </div>
                    {renderPaymentButton()}
                </div>
            </div>
        );
    };

    const renderPaymentButton = () => {
        return (
            <div className="card-footer text-end">
                <button type="submit" className="btn btn-primary" onClick={pressPay}>Weiter</button>
            </div>
        );
    };

    const pressPay = async () => {
        if (!validateFormular() || !acceptAgb) {
            findContainerById('alertInformation')?.classList.remove('d-none');
            return;
        }

        findContainerById('alertInformation')?.classList.add('d-none');

        let paymentIntent = {
            id: '',
            firstName: findInputById('firstName')!.value,
            lastName: findInputById('lastName')!.value,
            city: findInputById('city')!.value,
            postalCode: findInputById('plz')!.value,
            birthDate: 0,
            email: findInputById('email')!.value,
            street: findInputById('street')!.value,
            guestCardId: selectedGuestCard!.id,
            startGuestCardDate: startDate.getTime(),
            dateBought: 0,
            platform: '',
            sourceId: '',
            clientSecret: '',
        };

        try {
            let createdIntent = await NetworkCalls.createPaymentIntent(paymentIntent);
            let secret = await NetworkCalls.getIntent(createdIntent);
            navigate('/checkout', {
                state: {secret: secret, intent: createdIntent, guestCard: selectedGuestCard}
            });

        } catch (error) {
            NetworkErrorHandling.handle(error as AxiosError);
        }
    };

    const validateFormular = () => {
        const firstNameValid = validateInputField('firstName');
        const lastNameValid = validateInputField('lastName');
        const streetValid = validateInputField('street');
        let plzValid = validateInputField('plz');
        const cityValid = validateInputField('city');
        let emailValid = validateInputField('email');

        if (plzValid) {
            let inputField = findInputById('plz');
            if (inputField) {
                if (inputField.value.length !== 5) {
                    invalidateField('plz');
                    plzValid = false;
                }
            }
        }

        if (emailValid) {
            let inputField = findInputById('email');
            if (inputField) {
                const emailRegex = new RegExp(
                    /^.+@.+$/i,
                );
                if (!emailRegex.test(inputField.value)) {
                    invalidateField('email');
                    emailValid = false;
                }
            }
        }

        const guestCardValid = startDate.getTime() > new Date().getTime();

        return firstNameValid && lastNameValid && streetValid && plzValid && cityValid && emailValid && guestCardValid;
    };

    const validateInputField = (id: string) => {
        let inputFieldValid = true;
        let inputField = findInputById(id);
        if (inputField) {
            if (inputField.value.length === 0) {
                invalidateField(id);
                inputFieldValid = false;
            } else {
                inputField.classList.remove("is-invalid");
                inputField.classList.remove("is-invalid-lite");
            }
        } else {
            inputFieldValid = false;
        }
        return inputFieldValid;
    };

    const invalidateField = (id: string) => {
        let inputField = findInputById(id);
        if (inputField) {
            inputField.classList.add("is-invalid");
            inputField.classList.add("is-invalid-lite");
        }
    };

    const findInputById = (inputId: string) => {
        return document.querySelector(`#${inputId}`) as HTMLInputElement | null;
    };

    const findContainerById = (inputId: string) => {
        return document.querySelector(`#${inputId}`) as HTMLDivElement | null;
    };

    const showContent = () => {
        return (
            <div className="page page-center">
                <div className="container container-tight py-4">
                    <div className="card card-md">
                        <div className="card-body text-center py-4 p-sm-5">
                            <h1 className="mt-5">Gastkartenverkauf</h1>
                            <p className="text-muted">Vielen Dank, dass Du eine Gastkarte erwerben möchtest. Bitte wähle die Gastkarte aus, die Du erwerben möchtest.</p>
                        </div>
                        {guestCards && guestCards.length > 0 ? renderGuestCards() : ''}
                        {selectedGuestCard !== undefined ? renderUserInformationFormular() : ''}
                    </div>
                </div>
            </div>
        );
    }

    return (<div>
        {loading ? (<LoadingPageIndicator/>) : showContent()}
    </div>);
}
