import {Organisation} from "../dtos/Organisation";
import {Alert, Button, Col, Form, Row, Spinner} from "react-bootstrap";
import {ChangeEvent, useId, useState} from "react";
import {useNavigate} from "react-router-dom";
import {NetworkCalls} from "../NetworkCalls";

export default function CatchReportGuestInputForm({organisation}: { organisation: Organisation }) {

    const [formData, setFormData] = useState<string>('');
    const helpId = useId();
    const [validated, setValidated] = useState<boolean>(false);

    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<string>('');

    const navigate = useNavigate();

    const handleSubmit = (event: ChangeEvent<HTMLFormElement>) => {
        const form = event.currentTarget;

        event.preventDefault();
        event.stopPropagation();

        let checkValidity = form.checkValidity();

        setValidated(true);

        if (!checkValidity)
            return;

        setLoading(true);
        setError('');

        let guestCardToken = formData.trim();
        NetworkCalls.getGuestCardCatchReports({
            organisationToken: organisation.organisationToken,
            guestCardToken: guestCardToken
        })
            .then(_ => {
                navigate(`/catch-report/${organisation.organisationToken}/guest-card/${guestCardToken}`)
            })
            .catch(e => {
                console.error(e);
                setError('Gastkarte nicht gefunden')
            }).finally(() => {
                setLoading(false);
            });
    }

    return <>
        <Alert variant='info'>Bitte nutzen Sie den Link, den Sie mit dem Kauf Ihrer Gastkarte per Mail erhalten haben,
            um die Nummer nicht selbst eingeben zu müssen.</Alert>
        <Form noValidate validated={validated} onSubmit={handleSubmit}>
            <Form.Group as={Row} className='mb-3' controlId='member-number'>
                <Form.Label column lg={2} className='required'>Ihre Gastkartennummer</Form.Label>
                <Col lg={10}>
                    <Form.Control type='text' placeholder='Gastkartennummer' required size='lg'
                                  value={formData} onChange={e => setFormData(e.target.value)} autoFocus={true}
                                  aria-describedby={helpId}/>
                    <Form.Control.Feedback type='invalid'>Bitte geben Sie hier die Nummer, die auf Ihrer Gastkarte
                        steht, ein.</Form.Control.Feedback>
                    <Form.Text muted id={helpId}>
                        Die Nummer der Gastkarte, die Sie beim {organisation.organisationName} gekauft haben und für die
                        Sie Ihre Fänge melden wollen.
                    </Form.Text>
                </Col>
            </Form.Group>
            <Form.Group as={Row}>
                <Col lg={{span: 10, offset: 2}} className='d-grid gap-2'>
                    <Button variant='primary' type='submit' disabled={loading || !formData}>
                        Weiter
                        <Spinner animation="border" role="status" className={'ms-2' + (loading ? '' : ' d-none')}>
                            <span className="visually-hidden">Lade...</span>
                        </Spinner>
                    </Button>
                    <span className='text-red'>
                        {error}
                    </span>
                </Col>
            </Form.Group>
        </Form>
    </>;
}
