import React, { useEffect, useId, useState } from "react";
import uuid from "react-uuid";
import { Button, Col, Form, Modal } from "react-bootstrap";
import SunEditor from "suneditor-react";
import AppointmentCategoryDTO from "../../dtos/AppointmentCategoryDTO";
import { NetworkCalls } from "../../NetworkCalls";
import AppointmentDTO from "../../dtos/AppointmentDTO";
import NewsDTO from "../../dtos/NewsDTO";
import TomSelect from "tom-select";
import DeleteDialog from "../../components/DeleteDialog";

export default function Appointments() {
  const [show, setShow] = useState<boolean>(false);
  const [title, setTitle] = useState<string>("");
  const [description, setDescription] = useState<string>("");
  const [address, setAddress] = useState<string>("");
  const [categories, setCategories] = useState<AppointmentCategoryDTO[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [appointments, setAppointments] = useState<AppointmentDTO[]>();
  const [date, setDate] = useState<string>("");
  const [minDate, setMinDate] = useState<string>("");
  const [showDeleteDialog, setShowDeleteDialog] = useState<boolean>(false);
  const [selectedAppointment, setSelectedAppointment] =
    useState<AppointmentDTO>();

  useEffect(() => {
    const fetchData = async () => {
      if (!loading && categories.length === 0) {
        try {
          setLoading(true);

          const categories = await NetworkCalls.getCategories();
          setCategories(categories);
          const appointments = await NetworkCalls.getAppointments();
          setAppointments(appointments);
          const date = new Date();

          setDate(formatToInputDateTimeValue(date));
          setMinDate(`${formatToInputDateTimeValue(date)}.00z`);

          setAppointments(await NetworkCalls.getAppointments());
        } catch (error) {
          // @ts-ignore
          setError(error);
        } finally {
          setLoading(false);
        }
      }
    };

    fetchData().then();
  }, [loading, categories.length]);

  const formatToInputDateTimeValue = (value: Date) => {
    const month = ("0" + (value.getUTCMonth() + 1)).slice(-2);
    const day = ("0" + value.getDate()).slice(-2);
    const minutes = ("0" + value.getMinutes()).slice(-2);
    const hours = ("0" + value.getHours()).slice(-2);

    let v1 = `${value.getFullYear()}-${month}-${day}T${hours}:${minutes}`;
    value.setSeconds(0, 0);
    let v2 = value.toISOString();
    console.log('formatToInputDateTimeValue', v1, v2);
    return v1;
  };

  const showCatchReportTable = () => {
    return (
      <table className="table table-hover">
        <thead>
          <tr key={uuid()}>
            <th scope="col">Titel</th>
            <th scope="col">Datum</th>
            <th scope="col">Adresse</th>
            <th scope="col">Sichtbar für</th>
          </tr>
        </thead>
        <tbody id="table-content">{createRows()}</tbody>
      </table>
    );
  };

  const formatToReadableTime = (value: Date) => {
    const month = ("0" + (value.getUTCMonth() + 1)).slice(-2);
    const day = ("0" + value.getDate()).slice(-2);
    const minutes = ("0" + value.getMinutes()).slice(-2);
    const hours = ("0" + value.getHours()).slice(-2);

    return `${day}.${month}.${value.getFullYear()} ${hours}:${minutes} Uhr`;
  };

  const createRows = () => {
    return appointments?.map((elem) => {
      return (
        <tr
          key={uuid()}
          className="user-select-auto"
          onClick={(e) => {
            setSelectedAppointment(elem);
            setShow(true);
            setTitle(elem.title);
            setDescription(elem.description);
            setAddress(elem.address);
            setDate(formatToInputDateTimeValue(new Date(elem.appointmentDate)));
          }}
        >
          <td>{elem.title}</td>
          <td>{formatToReadableTime(new Date(elem.appointmentDate))}</td>
          <td>{elem.address}</td>
          <td>{newsVisibleFor(elem)}</td>
        </tr>
      );
    });
  };

  const newsVisibleFor = (news: NewsDTO) => {
    if (news.tags === null || news.tags?.length === 0) {
      return "Alle";
    } else {
      return news.tags?.map((tag) => tag.name).join(", ");
    }
  };

  const handleClose = () => {
    setShow(false);
    setTitle("");
    setDescription("");
    setDate(minDate);
    setAddress("");
    setSelectedAppointment(undefined);
  };

  const onEntered = () => {
    const tagsSelect = document.querySelector("#tags");

    // @ts-ignore
    if (tagsSelect != null && !tagsSelect.tomselect) {
      const bla = categories.map((category) => ({
        id: category.id,
        name: category.name,
      }));
      // @ts-ignore
      new TomSelect("#tags", {
        valueField: "id",
        labelField: "name",
        searchField: ["name"],
        // @ts-ignore
        options: bla,
        // @ts-ignore
        items: selectedAppointment?.tags?.map((tag) => tag.id),
      });
    }
  };

  const ModalDialog = () => {
    const modalId = useId();
    return (
      <Modal
        show={show}
        onHide={handleClose}
        id={modalId}
        onEntered={onEntered}
        size={"xl"}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {selectedAppointment !== undefined
              ? "Termin bearbeiten"
              : "Neuer Termin"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group>
              <Form.Label className="required">Titel</Form.Label>
              <Form.Control
                id="title"
                disabled={loading}
                type="text"
                placeholder="Titel"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label className="required">Beschreibung</Form.Label>
              <SunEditor
                setContents={description}
                onChange={(description) => setDescription(description)}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label className="required">Adresse</Form.Label>
              <Form.Control
                id="address"
                disabled={loading}
                type="text"
                placeholder="Adresse"
                value={address}
                onChange={(e) => setAddress(e.target.value)}
              />
            </Form.Group>
            <Form.Group>
              <label className="form-label text-dark">
                Wann findet der Termin statt
              </label>
              <input
                type="datetime-local"
                onChange={onChangeDate}
                value={date}
                min={minDate}
              />
            </Form.Group>
            <Form.Group>
              <Col>
                <label className="form-label text-dark">Tags zuordnen</label>
                <select
                  id="tags"
                  className="form-control"
                  multiple
                  placeholder="Wähle Tags aus..."
                ></select>
              </Col>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant={"danger"}
            disabled={!selectedAppointment}
            onClick={(e) => {
              setShowDeleteDialog(true);
              setShow(false);
            }}
          >
            Löschen
          </Button>
          <Button
            variant="primary"
            disabled={!title || !description || !date || !address}
            onClick={async () => {
              if (title && description && date && address) {
                setLoading(true);
                let appointment: AppointmentDTO = {
                  title,
                  description,
                  appointmentDate: date,
                  address,
                };

                const tagsSelect = document.querySelector("#tags");
                // @ts-ignore
                if (tagsSelect && tagsSelect["tomselect"]) {
                  // @ts-ignore
                  const tomselect = tagsSelect["tomselect"] as TomSelect;
                  appointment.tags = categories.filter((category) =>
                    tomselect.items.find((id) => id === category.id)
                  );
                }

                if (selectedAppointment !== undefined) {
                  appointment.id = selectedAppointment.id;
                  setAppointments(
                    await NetworkCalls.updateAppointment(appointment)
                  );
                } else {
                  setAppointments(
                    await NetworkCalls.persistAppointment(appointment)
                  );
                }

                setShow(false);
                setLoading(false);
                setSelectedAppointment(undefined);
              }
            }}
          >
            Speichern
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  const onChangeDate = (e: { target: { value: any; valueAsNumber: any } }) => {
    console.log("e.target.value: ", e.target.value, e.target.valueAsNumber);
    setDate(e.target.value + ":00");
  };

  const showContent = () => {
    return (
      <div className="m-5 col row justify-content-center">
        <div className="card border-0">
          <div className="card-header">
            <div className="row" style={{ minWidth: "100%" }}>
              <div className="col-5">
                <Button variant="primary" onClick={(elem) => setShow(true)}>
                  Neuer Termin
                </Button>
              </div>
            </div>
          </div>
        </div>
        {showCatchReportTable()}
        {ModalDialog()}
        <DeleteDialog
          onDeleteConfirm={async () => {
            setShowDeleteDialog(false);
            if (selectedAppointment !== undefined) {
              setLoading(true);
              setAppointments(
                await NetworkCalls.deleteAppointment(selectedAppointment)
              );
              setSelectedAppointment(undefined);
              setLoading(false);
            }
          }}
          title="Löschen"
          message="Datensatz wirklich löschen?"
          show={showDeleteDialog}
          onHandleClose={() => setShowDeleteDialog(false)}
        />
      </div>
    );
  };

  return <div>{showContent()}</div>;
}
