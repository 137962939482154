import React, { useEffect, useState } from "react";
import { MemberDTO } from "../../dtos/MemberDTO";
import { NetworkCalls } from "../../NetworkCalls";
import LoadingPageIndicator from "../../components/LoadingPageIndicator";
import uuid from "react-uuid";
import HandleHttpResponses from "../../components/NetworkErrorHandling";
import { AxiosError } from "axios";
import { Link } from "react-router-dom";
import { Button } from "react-bootstrap";
import AddNewMemberDialog from "../../components/AddNewMemberDialog";

export default function MemberList() {
  const [loading, isLoading] = useState<boolean>(false);
  const [members, setMembers] = useState<MemberDTO[]>([]);
  const [error, setError] = useState<AxiosError>();
  const [filteredMembers, setFilteredMembers] = useState<MemberDTO[]>([]);
  const [show, setShow] = useState<boolean>(false);

  useEffect(() => {
    loadData();
  }, []);

  const loadData = () => {
    isLoading(true);
    NetworkCalls.getMemberList()
      .then((result) => {
        setMembers(result);
        setFilteredMembers(result);
        isLoading(false);
      })
      .catch((error) => {
        isLoading(false);
        setError(error);
      });
  };

  function search(value: string) {
    setFilteredMembers(
      members.filter(
        (member) =>
          contains(member.number, value) ||
          contains(member.lastname, value) ||
          contains(member.firstname, value) ||
          contains(member.street, value) ||
          contains(member.postalCode, value) ||
          contains(member.city, value)
      )
    );
  }

  function contains(field: string, value: string) {
    return field !== null && field.toUpperCase().includes(value.toUpperCase());
  }

  function showContent() {
    return (
      <div>
        <div className="m-5 col row justify-content-center">
          <div className="card border-0">
            <div className="card-header">
              <div className="row" style={{ minWidth: "100%" }}>
                <div className="col-2">
                  <input
                    type="text"
                    className="form-control"
                    aria-label="Default"
                    aria-describedby="inputGroup-sizing-default"
                    style={{ minWidth: "100%" }}
                    onChange={(elem) => search(elem.target.value.toUpperCase())}
                  />
                </div>
                <div className="col-6">
                  <Button variant="primary" onClick={() => setShow(true)}>
                    Mitglied hinzufügen
                  </Button>
                </div>
              </div>
            </div>
            {showInspectionTable()}
          </div>
        </div>
        <AddNewMemberDialog
          show={show}
          onClose={() => setShow(false)}
          onMemberSaved={loadData}
        />
      </div>
    );
  }

  function showInspectionTable() {
    return (
      <table className="table table-hover">
        <thead>
          <tr key={uuid()}>
            <th scope="col">Mitgliedsnummer</th>
            <th scope="col">Vorname</th>
            <th scope="col">Nachname</th>
            <th scope="col">Adresse</th>
            <th scope="col">Arbeitsdienstpflichtig</th>
            <th scope="col"></th>
            <th scope="col"></th>
          </tr>
        </thead>
        <tbody id="table-content">{createRows()}</tbody>
      </table>
    );
  }

  function createRows() {
    return filteredMembers.map((elem) => {
      return (
        <tr key={uuid()} className="user-select-auto">
          <td>{elem.number}</td>
          <td> {elem.firstname}</td>
          <td>{elem.lastname}</td>
          <td>
            {elem.street} {elem.postalCode} {elem.city}
          </td>
          <td>{memberWork(elem.memberWork)}</td>
          <td>
            <Link
              to={`/dashboard/administration/member-list/${elem.number}`}
              className="btn btn-primary"
            >
              Details anzeigen
            </Link>
          </td>
          <td>
            <Link
              to={`/dashboard/administration/member-list/${elem.number}/edit`}
              className="btn btn-primary"
            >
              Bearbeiten
            </Link>
          </td>
        </tr>
      );
    });
  }

  const memberWork = (memberWork: boolean | undefined) => {
    if (memberWork) {
      return <span className="status status-green">Ja</span>;
    } else {
      return <span className="status status-red">Nein</span>;
    }
  };

  return (
    <div>
      {loading ? (
        <LoadingPageIndicator />
      ) : error ? (
        HandleHttpResponses(error)
      ) : (
        showContent()
      )}
    </div>
  );
}
