export default class DateFormat {

    static toDateTimeReadableFormat(date: Date) {
        return new Intl.DateTimeFormat("de", DateFormat.getDateTimeOptions()).format(date);
    }

    static toDateOnlyReadableFormat(date: Date) {
        return new Intl.DateTimeFormat("de", DateFormat.getDateOnlyOptions()).format(date);
    }

    private static getDateTimeOptions() {
        return {
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
            hour: "2-digit",
            minute: "2-digit",
            second: "2-digit",
            hour12: false,
            timeZone: "UTC",
        } as Intl.DateTimeFormatOptions;
    }

    private static getDateOnlyOptions() {
        return {
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
            hour12: false,
            timeZone: "UTC",
        } as Intl.DateTimeFormatOptions;
    }
}