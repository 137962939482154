import React, { useEffect, useState } from "react";
import HandleHttpResponses from "../../components/NetworkErrorHandling";
import { AxiosError } from "axios";
import { NetworkCalls } from "../../NetworkCalls";
import { CatchYear } from "../../dtos/CatchYear";
import "tom-select/dist/css/tom-select.bootstrap5.css";
import TimestampFormat from "../../utils/TimestampFormat";
import ReportedCatchYears from "./ReportedCatchYears";
import FangkarteList from "../../components/FangkarteList";
import { FangkarteListItem } from "../../models/FangkarteListItem";
import DeleteDialog from "../../components/DeleteDialog";
import EmptyPage from "../../components/EmptyPage";
import AddEditCatchYear from "./AddEditCatchYear";
import { Mode } from "../../dtos/Mode";

export default function EditCatchYear() {
  const [loading, isLoading] = useState<boolean>(false);
  const [error, setError] = useState<AxiosError>();
  const [catchYear, setCatchYear] = useState<CatchYear[]>([]);
  const [selectedCatchYear, setSelectedCatchYear] = useState<
    CatchYear | undefined
  >(undefined);
  const [showDeleteDialog, setShowDeleteDialog] = useState<boolean>(false);
  const [showAddEditDialog, setShowAddEditDialog] = useState<boolean>(false);
  const [editMode, setEditMode] = useState<Mode>(Mode.ADD);

  useEffect(() => {
    isLoading(true);
    NetworkCalls.getCatchYears()
      .then((result) => {
        isLoading(false);
        setCatchYear(result);
      })
      .catch((error) => {
        isLoading(false);
        setError(error);
      });
  }, []);

  function showContent() {
    return (
      <div>
        <AddEditCatchYear
          onClose={(result) => {
            setShowAddEditDialog(false);
            if (result) {
              setCatchYear(result);
            }
          }}
          show={showAddEditDialog}
          editMode={editMode}
          selectedCatchYear={selectedCatchYear}
        />
        <div className="float-end w-50 m10">{alert()}</div>
        <div className="page-header d-print-none">
          {!catchYear || catchYear.length === 0 ? (
            <EmptyPage
              addNewElement={true}
              onItemAdd={() => {
                setEditMode(Mode.ADD);
                setShowAddEditDialog(true);
                setSelectedCatchYear(undefined);
              }}
            />
          ) : (
            showData()
          )}
        </div>
      </div>
    );
  }

  const showData = () => {
    return (
      <div className="container-xl">
        <div className="row g-2 align-items-center">
          <div className="col">
            <h2 className="page-title">Fangjahre Übersicht</h2>
          </div>
          <div className="container-xl pt-5">
            <div className="row row-deck row-cards">
              <div className="col-4">
                <FangkarteList
                  listItems={createListItems()}
                  onItemClicked={setSelectedCatchYear}
                  updateElement={true}
                  onItemUpdate={(elem) => {
                    setEditMode(Mode.EDIT);
                    setSelectedCatchYear(elem);
                    setShowAddEditDialog(true);
                  }}
                  title="Fangjahre"
                  deleteElement={true}
                  addNewElement={true}
                  onItemAdd={() => {
                    setEditMode(Mode.ADD);
                    setShowAddEditDialog(true);
                    setSelectedCatchYear(undefined);
                  }}
                  onItemDelete={async (elem) => {
                    setShowDeleteDialog(true);
                    setSelectedCatchYear(elem);
                  }}
                  exportListEnabled={false}
                />
              </div>
              <div className="col-8">
                <ReportedCatchYears reportedCatchYear={selectedCatchYear} />
              </div>
            </div>
          </div>
        </div>
        <DeleteDialog
          onDeleteConfirm={async () => {
            setShowDeleteDialog(false);
            if (selectedCatchYear) {
              isLoading(true);
              setCatchYear(
                await NetworkCalls.deleteCatchYear(selectedCatchYear)
              );
              isLoading(false);
            }
          }}
          title="Löschen"
          message="Datensatz wirklich löschen?"
          show={showDeleteDialog}
          onHandleClose={() => setShowDeleteDialog(false)}
        />
      </div>
    );
  };

  const createListItems = () => {
    return catchYear.map((year) => {
      const date = `${TimestampFormat.toDateOnlyReadableFormat(
        year.startReport
      )} - ${TimestampFormat.toDateOnlyReadableFormat(year.endReport)}`;
      return {
        title: year.name,
        subTitle: date,
        originalElement: year,
      } as FangkarteListItem;
    });
  };

  const loadingPage = () => {
    return (
      <div className="page-header d-print-none">
        <div className="container-xl">
          <div className="row row-cards">
            <div className="col-12">
              <div className="card">
                <ul className="list-group list-group-flush placeholder-glow">
                  <li className="list-group-item">
                    <div className="row align-items-center">
                      <div className="col-auto">
                        <div className="avatar avatar-rounded placeholder"></div>
                      </div>
                      <div className="col-7">
                        <div className="placeholder placeholder-xs col-9"></div>
                        <div className="placeholder placeholder-xs col-7"></div>
                      </div>
                      <div className="col-2 ms-auto text-end">
                        <div className="placeholder placeholder-xs col-8"></div>
                        <div className="placeholder placeholder-xs col-10"></div>
                      </div>
                    </div>
                  </li>
                  <li className="list-group-item">
                    <div className="row align-items-center">
                      <div className="col-auto">
                        <div className="avatar avatar-rounded placeholder"></div>
                      </div>
                      <div className="col-7">
                        <div className="placeholder placeholder-xs col-9"></div>
                        <div className="placeholder placeholder-xs col-7"></div>
                      </div>
                      <div className="col-2 ms-auto text-end">
                        <div className="placeholder placeholder-xs col-8"></div>
                        <div className="placeholder placeholder-xs col-10"></div>
                      </div>
                    </div>
                  </li>
                  <li className="list-group-item">
                    <div className="row align-items-center">
                      <div className="col-auto">
                        <div className="avatar avatar-rounded placeholder"></div>
                      </div>
                      <div className="col-7">
                        <div className="placeholder placeholder-xs col-9"></div>
                        <div className="placeholder placeholder-xs col-7"></div>
                      </div>
                      <div className="col-2 ms-auto text-end">
                        <div className="placeholder placeholder-xs col-8"></div>
                        <div className="placeholder placeholder-xs col-10"></div>
                      </div>
                    </div>
                  </li>
                  <li className="list-group-item">
                    <div className="row align-items-center">
                      <div className="col-auto">
                        <div className="avatar avatar-rounded placeholder"></div>
                      </div>
                      <div className="col-7">
                        <div className="placeholder placeholder-xs col-9"></div>
                        <div className="placeholder placeholder-xs col-7"></div>
                      </div>
                      <div className="col-2 ms-auto text-end">
                        <div className="placeholder placeholder-xs col-8"></div>
                        <div className="placeholder placeholder-xs col-10"></div>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const alert = () => {
    return (
      <div
        className={
          !error
            ? "alert alert-danger alert-dismissible d-none"
            : "alert alert-danger alert-dismissible"
        }
        role="alert"
        id="alert"
      >
        <div className="d-flex">
          <div>
            <h4 className="alert-title">Problem</h4>
            <div className="text-muted">
              Da ist etwas schief gelaufen. Versuche es nochmal oder kontaktiere
              uns
            </div>
          </div>
        </div>
        <button
          className="btn-close"
          data-bs-dismiss="alert"
          aria-label="close"
        ></button>
      </div>
    );
  };

  return (
    <div>
      {loading
        ? loadingPage()
        : error
        ? HandleHttpResponses(error)
        : showContent()}
    </div>
  );
}
