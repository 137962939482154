import { Button, Col, Form, InputGroup, Row, Spinner } from "react-bootstrap";
import LitePicker from "../components/LitePicker";
import React, { ChangeEvent, FormEvent, useEffect, useState } from "react";
import { Organisation } from "../dtos/Organisation";
import { Fish } from "../dtos/Fish";
import { WaterDTO } from "../dtos/WaterDTO";
import { NetworkCalls } from "../NetworkCalls";
import { UserCatchReportAddFormData } from "../dtos/UserCatchReportAddFormData";

export default function NewCatchInputForm({
  onSubmit,
  validated,
  formLabelCols,
  formInputCols,
  organisation,
}: {
  onSubmit: (
    event: FormEvent<HTMLFormElement>,
    formData: UserCatchReportAddFormData
  ) => Promise<void>;
  validated: boolean;
  formLabelCols: number;
  formInputCols: number;
  organisation: Organisation;
}) {
  const defaultFormData = {
    organisationToken: organisation.organisationToken,
    catchTime: new Date().getTime(),
    fishId: "",
    waterId: "",
  } as UserCatchReportAddFormData;

  const [formData, setFormData] = useState<UserCatchReportAddFormData>({
    ...defaultFormData,
  });
  const [fishes, setFishes] = useState<Fish[]>([]);
  const [waters, setWaters] = useState<WaterDTO[]>([]);

  const [error, setError] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);

  const handleError = (error: any) => {
    console.error(error);
    setError(error.message);
  };

  useEffect(() => {
    const fetchWaters = async () => {
      let waters1 = await NetworkCalls.getOrganisationWaters(organisation.id);
      setWaters(waters1);
    };

    const fetchFishes = async () => {
      let fishes1 = await NetworkCalls.getOrganisationFishes(organisation.id);
      setFishes(fishes1);
    };

    fetchWaters().catch(handleError);
    fetchFishes().catch(handleError);
  }, [organisation.id]);

  const onSubmitInternal = (event: FormEvent<HTMLFormElement>) => {
    setError("");
    setLoading(true);
    onSubmit(event, formData)
      .then(() => {
        setFormData({ ...defaultFormData });
      })
      .catch((error) => {
        if (error.response.status === 406) {
          setError(
            "Sie können nur Fänge innerhalb des gebuchten Zeitraumes Ihrer Gastkarte melden."
          );
        } else {
          handleError(error);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const dateFromCatchTime = (milliseconds: number) => {
    return new Date(milliseconds);
  };

  const onFishLengthChange = (event: ChangeEvent<HTMLInputElement>) =>
    setFormData((prevState) => {
      return { ...prevState, fishLength: event.target.valueAsNumber };
    });

  const onDateSelected = (date: Date) => {
    setFormData({ ...formData, catchTime: date.getTime() });
  };

  return (
    <>
      <Form noValidate validated={validated} onSubmit={onSubmitInternal}>
        <Form.Group as={Row} className="mb-3">
          <Form.Label column lg={formLabelCols} className="required">
            Gefangener Fisch
          </Form.Label>
          <Col lg={formInputCols}>
            <Form.Select
              required
              title="Gefangenen Fisch auswählen"
              aria-label="Gefangenen Fisch auswählen"
              value={formData.fishId}
              onChange={(e) =>
                setFormData({ ...formData, fishId: e.target.value })
              }
            >
              <option value="">Gefangenen Fisch auswählen</option>
              {fishes.map((f) => (
                <option value={f.fishId}>{f.name}</option>
              ))}
            </Form.Select>
          </Col>
        </Form.Group>

        <Form.Group as={Row} className="mb-3">
          <Form.Label column lg={formLabelCols} className="required">
            Fischlänge (in cm)
          </Form.Label>
          <Col lg={formInputCols}>
            <InputGroup>
              <Form.Control
                type="number"
                placeholder="Fischlänge in cm"
                required
                value={formData.fishLength || ""}
                onChange={onFishLengthChange}
              />
              <InputGroup.Text>cm</InputGroup.Text>
            </InputGroup>
          </Col>
        </Form.Group>
        <Form.Group as={Row} className="mb-3">
          <Form.Label column lg={formLabelCols} className="required">
            Gewässer
          </Form.Label>
          <Col lg={formInputCols}>
            <Form.Select
              aria-label="Gewässer"
              required
              value={formData.waterId}
              onChange={(e) =>
                setFormData({ ...formData, waterId: e.target.value })
              }
            >
              <option value="">Gewässer auswählen</option>
              {waters.map((w) => (
                <option value={w.id}>{w.name}</option>
              ))}
            </Form.Select>
          </Col>
        </Form.Group>
        <Form.Group as={Row} className="mb-3">
          <Form.Label column lg={formLabelCols} className="required">
            Fangzeitpunkt
          </Form.Label>
          <Col lg={formInputCols}>
            <LitePicker
              onDateSelected={onDateSelected}
              value={dateFromCatchTime(formData.catchTime)}
            />
          </Col>
        </Form.Group>
        <Form.Group className="d-grid gap-2">
          <Button
            variant="primary"
            type="submit"
            disabled={
              !formData.fishId || !formData.fishLength || !formData.waterId
            }
          >
            Hinzufügen
            <Spinner
              animation="border"
              role="status"
              className={"ms-2" + (loading ? "" : " d-none")}
            >
              <span className="visually-hidden">Laden...</span>
            </Spinner>
          </Button>
          <Form.Text className="text-danger">{error}</Form.Text>
        </Form.Group>
      </Form>
    </>
  );
}
