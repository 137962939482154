import {useEffect, useRef, useState} from "react";
import {WaterDTO} from "../../dtos/WaterDTO";
import {useSelectedOrganisation} from "../../custom-hooks/useSelectedOrganisation";
import {AxiosError} from "axios";
import {NetworkCalls} from "../../NetworkCalls";
import {Alert, Button, Card, Col} from "react-bootstrap";
import {Link, useNavigate} from "react-router-dom";
import AddEditGuestCardDialog from "./AddEditGuestCardDialog";
import {GuestCardDTO} from "../../dtos/GuestCardDTO";
import LoadingPageIndicator from "../../components/LoadingPageIndicator";

export default function AddGuestCard() {
    const [loading, isLoading] = useState<boolean>(false);
    const [waters, setWaters] = useState<WaterDTO[]>([]);
    const selectedOrganisation = useSelectedOrganisation();
    const navigate = useNavigate();
    const [card] = useState<GuestCardDTO>({
        active: true,
        description: '',
        duration: 1,
        organisationId: '',
        price: 10,
        sendWaterRegulation: true,
        termsAndConditions: '',
        waterMappings: [],
        id: ''
    } as GuestCardDTO);
    const [error, setError] = useState<AxiosError | undefined>(undefined);

    const submitRef = useRef();

    useEffect(() => {
        if (!selectedOrganisation) {
            return;
        }

        isLoading(true);

        card.organisationId = selectedOrganisation.id;

        NetworkCalls.listWater()
            .then(result => {
                result = result.sort((a, b) => a.name.localeCompare(b.name))
                setWaters(result);
            })
            .catch(err => {
                setError(err);
                console.error(err);
            })
            .finally(() => isLoading(false));

    }, [card, selectedOrganisation]);

    const afterSubmit = (card: GuestCardDTO) => {
        setError(undefined);
        NetworkCalls.addGuestCard(card)
            .then(() => {
                navigate('..', {relative: 'path'});
            })
            .catch(e => {
                console.error(e);
                setError(e);
            });
    }

    const renderError = () => {
        if (!error)
            return <></>;
        return <>
            <Alert variant='danger'>{error.message}</Alert>
        </>;
    }

    const render = () => {
        if (loading) {
            return <LoadingPageIndicator />
        }

        return <>
            {renderError()}
            <AddEditGuestCardDialog card={card} afterSubmitAction={afterSubmit} waters={waters} submitRef={submitRef}/>
        </>;
    }

    const submitForm = () => {
        // @ts-ignore
        submitRef.current.click();
    }

    return <>
        <Card className='m-5 justify-content-center border-0' as={Col}>
            <Card.Header><h1>Gastkarte hinzufügen</h1></Card.Header>
            <Card.Body className='border-bottom'>
                {render()}
            </Card.Body>
            <Card.Footer className='space-x'>
                <Button onClick={submitForm}>Speichern</Button>
                <Link to={'/dashboard/administration/guest-cards'} className='btn btn-secondary'>Abbrechen</Link>
            </Card.Footer>
        </Card>
    </>;

}
