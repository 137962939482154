import GeoCoordinatesDTO from "../../../dtos/GeoCoordinatesDTO";
import { useApiIsLoaded, useMap } from "@vis.gl/react-google-maps";
import { useEffect } from "react";

const CenterMaps = ({ coordinates }: { coordinates: GeoCoordinatesDTO[] }) => {
  const map = useMap();
  const apiIsLoaded = useApiIsLoaded();

  useEffect(() => {
    if (!apiIsLoaded) return;
    if (!map) return;
    if (!coordinates) return;

    const bounds = new google.maps.LatLngBounds();

    coordinates.forEach((coordinate) => {
      bounds.extend({
        lat: coordinate.latitude,
        lng: coordinate.longitude,
      });
    });

    map.fitBounds(bounds);

    // here you can interact with the imperative maps API
  }, [map, coordinates, apiIsLoaded]);

  return <></>;
};

export default CenterMaps;
