import axios, {AxiosResponse} from "axios";
import {Jwt} from "./dtos/Jwt";
import {MemberDTO} from "./dtos/MemberDTO";
import {getData} from "./utils/storage/Storage";
import {StorageKey} from "./utils/storage/Keys";
import {OrgaAndRoles} from "./models/OrgaAndRoles";
import {Inspection} from "./dtos/Inspection";
import {CatchReport} from "./dtos/CatchReport";
import {GuestCard} from "./dtos/GuestCard";
import {WaterRegulationFile} from "./dtos/WaterRegulationFile";
import {PaymentDraft} from "./dtos/PaymentDraft";
import {StripePaymentInformation} from "./dtos/StripePaymentInformation";
import {Organisation} from "./dtos/Organisation";
import {WaterDTO} from "./dtos/WaterDTO";
import {Fish} from "./dtos/Fish";
import {UserCatchReport} from "./dtos/UserCatchReport";
import {UserCatchReportAddFormData} from "./dtos/UserCatchReportAddFormData";
import {MyOrganisation} from "./dtos/MyOrganisation";
import {BoughtGuestCard} from "./dtos/BoughtGuestCard";
import {Refund} from "./dtos/Refund";
import {getBearerToken, getOrganisation} from "./service/LoadStorageData";
import {CatchYear} from "./dtos/CatchYear";
import FeatureDTO from "./dtos/FeatureDTO";
import {Features} from "./dtos/Features";
import {ReportedCatchYearDoneDTO} from "./dtos/ReportedCatchYearDoneDTO";
import {UserDTO} from "./dtos/UserDTO";
import {MemberWorkMappingDTO} from "./dtos/MemberWorkMappingDTO";
import {MemberWorkResponseDTO} from "./dtos/MemberWorkResponseDTO";
import {PasswordDTO} from "./dtos/PasswordDTO";
import {MemberWorkExtendedDTO} from "./dtos/MemberWorkExtendedDTO";
import MemberWorkAttendeeDTO from "./dtos/MemberWorkAttendeeDTO";
import ReportMemberWorkDTO from "./dtos/ReportMemberWorkDTO";
import {ReportCatchYearDTO} from "./dtos/ReportCatchYearDTO";
import AddMemberWorkAttendeeDTO from "./dtos/AddMemberWorkAttendeeDTO";
import MemberWorkAttendanceDTO from "./dtos/MemberWorkAttendanceDTO";
import AppointmentDTO from "./dtos/AppointmentDTO";
import AppointmentCategoryDTO from "./dtos/AppointmentCategoryDTO";
import NewsDTO from "./dtos/NewsDTO";
import {GuestCardDTO} from "./dtos/GuestCardDTO";
import {WaterContactPersonDTO} from "./dtos/WaterContactPersonDTO";
import {OrgaFishDTO} from "./dtos/OrgaFishDTO";
import DocumentDTO from "./dtos/DocumentDTO";
import GeoCoordinatesDTO from "./dtos/GeoCoordinatesDTO";
import {MoveWaterShapeDTO} from "./dtos/MoveWaterShapeDTO";
import {WaternameDTO} from "./dtos/WaternameDTO";
import {WaterShapeProtectionTimeDTO} from "./dtos/WaterShapeProtectionTimeDTO";

export class NetworkCalls {
    private static isoDateFormat =
        /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(?:\.\d*)?(?:[-+]\d{2}:?\d{2}|Z)?$/;

    static login = (email: string, password: string): Promise<Jwt> => {
        return new Promise<Jwt>(function (resolve, reject) {
            const data = {
                username: email,
                password: password,
            };
            axios({
                method: "post",
                url: "authenticate",
                data: data,
            })
                .then((res) => resolve(res.data as Jwt))
                .catch((error) => reject(error));
        });
    };

    static updateMemberList = (members: MemberDTO[]) => {
        return new Promise((resolve, reject) => {
            let organisationId: OrgaAndRoles = JSON.parse(
                getData(StorageKey.SELECTED_ORGANISATION) as string
            );
            let token = getData(StorageKey.TOKEN) as string;
            axios({
                method: "post",
                url: `organisation/${organisationId.id}/upload-member-list`,
                headers: {Authorization: "Bearer " + token},
                data: members,
            })
                .then((res) => resolve(res.data))
                .catch((error) => reject(error));
        });
    };

    static getMemberList = () => {
        return new Promise<MemberDTO[]>((resolve, reject) => {
            let organisationId: OrgaAndRoles = JSON.parse(
                getData(StorageKey.SELECTED_ORGANISATION) as string
            );
            let token = getData(StorageKey.TOKEN) as string;
            axios({
                method: "get",
                url: `organisation/${organisationId.id}/list-members`,
                headers: {Authorization: "Bearer " + token},
            })
                .then((res) => resolve(res.data as MemberDTO[]))
                .catch((error) => reject(error));
        });
    };

    static getMember = (memberId: string) => {
        return new Promise<MemberDTO>((resolve, reject) => {
            let organisationId: OrgaAndRoles = JSON.parse(
                getData(StorageKey.SELECTED_ORGANISATION) as string
            );
            let token = getData(StorageKey.TOKEN) as string;
            axios({
                method: "get",
                url: `organisation/${organisationId.id}/list-members/${memberId}`,
                headers: {Authorization: "Bearer " + token},
            })
                .then((res) => resolve(res.data as MemberDTO))
                .catch((error) => reject(error));
        });
    };

    static updateMember = (member: MemberDTO) => {
        return new Promise<MemberDTO>((resolve, reject) => {
            let token = getData(StorageKey.TOKEN) as string;
            axios({
                method: "put",
                url: `organisation/${getOrganisation().id}/list-members/${
                    member.number
                }`,
                headers: {Authorization: "Bearer " + token},
                data: member,
            })
                .then((res) => {
                    resolve(res.data as MemberDTO);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    };

    static inspectionReportedYears = () => {
        return new Promise<number[] | any>((resolve, reject) => {
            let organisationId: OrgaAndRoles = JSON.parse(
                getData(StorageKey.SELECTED_ORGANISATION) as string
            );
            let token = getData(StorageKey.TOKEN) as string;
            const data = {
                organisationId: organisationId.id,
            };
            axios({
                method: "get",
                url: `inspection/years`,
                headers: {Authorization: "Bearer " + token},
                params: data,
            })
                .then((res) => resolve(res.data as number[]))
                .catch((error) => reject(error));
        });
    };

    static listWater = () => {
        return new Promise<WaterDTO[]>((resolve, reject) => {
            let organisation: OrgaAndRoles = JSON.parse(
                getData(StorageKey.SELECTED_ORGANISATION) as string
            );
            let token = getData(StorageKey.TOKEN) as string;
            axios({
                method: "get",
                url: `water/list-by-organisation/${organisation.id}`,
                headers: {Authorization: "Bearer " + token},
            })
                .then((res) => resolve(res.data as WaterDTO[]))
                .catch((error) => reject(error));
        });
    };

    static getGeoCoordinates = (waterId: string) => {
        return new Promise<GeoCoordinatesDTO[]>((resolve, reject) => {
            axios({
                method: "get",
                url: `water/${waterId}/geo-coordinates`,
            })
                .then((res) => resolve(res.data as GeoCoordinatesDTO[]))
                .catch((error) => reject(error));
        });
    };

    static getShapeProtectionZone = (waterId: string) => {
        const organisation = getOrganisation();
        const token = getBearerToken();
        return new Promise<WaterShapeProtectionTimeDTO[]>((resolve, reject) => {
            axios({
                method: "get",
                headers: {Authorization: `Bearer ${token}`},
                url: `water/geo-coordinates/${organisation.id}/${waterId}/protectionTime`,
            })
                .then((res) => resolve(res.data as WaterShapeProtectionTimeDTO[]))
                .catch((error) => reject(error));
        });
    };

    static persistWater = (water: WaterDTO) => {
        return new Promise<WaterDTO>((resolve, reject) => {
            let token = getData(StorageKey.TOKEN) as string;
            axios({
                method: "post",
                url: `water/persist-water`,
                headers: {Authorization: "Bearer " + token},
                data: water,
            })
                .then((res) => resolve(res.data as WaterDTO))
                .catch((error) => reject(error));
        });
    };

    static deleteAppointment = (appointmentDTO: AppointmentDTO) => {
        return new Promise<AppointmentDTO[]>((resolve, reject) => {
            let token = getData(StorageKey.TOKEN) as string;
            let organisation: OrgaAndRoles = JSON.parse(
                getData(StorageKey.SELECTED_ORGANISATION) as string
            );
            axios({
                method: "delete",
                url: `appointment/${organisation.id}`,
                headers: {Authorization: "Bearer " + token},
                data: appointmentDTO,
            })
                .then((res) => {
                    resolve(res.data as AppointmentDTO[]);
                })
                .catch((error) => reject(error));
        });
    };

    static updateAppointment = (appointmentDTO: AppointmentDTO) => {
        return new Promise<AppointmentDTO[]>((resolve, reject) => {
            let token = getData(StorageKey.TOKEN) as string;
            let organisation: OrgaAndRoles = JSON.parse(
                getData(StorageKey.SELECTED_ORGANISATION) as string
            );
            axios({
                method: "put",
                url: `appointment/${organisation.id}`,
                headers: {Authorization: "Bearer " + token},
                data: appointmentDTO,
            })
                .then((res) => {
                    resolve(res.data as AppointmentDTO[]);
                })
                .catch((error) => reject(error));
        });
    };

    static persistAppointment = (appointmentDTO: AppointmentDTO) => {
        return new Promise<AppointmentDTO[]>((resolve, reject) => {
            let token = getData(StorageKey.TOKEN) as string;
            let organisation: OrgaAndRoles = JSON.parse(
                getData(StorageKey.SELECTED_ORGANISATION) as string
            );
            axios({
                method: "post",
                url: `appointment/${organisation.id}`,
                headers: {Authorization: "Bearer " + token},
                data: appointmentDTO,
            })
                .then((res) => {
                    resolve(res.data as AppointmentDTO[]);
                })
                .catch((error) => reject(error));
        });
    };

    static listInspections = (calendarYear: number) => {
        return new Promise<Inspection[]>((resolve, reject) => {
            let organisationId: OrgaAndRoles = JSON.parse(
                getData(StorageKey.SELECTED_ORGANISATION) as string
            );
            let token = getData(StorageKey.TOKEN) as string;
            const data = {
                calendarYear: calendarYear,
                organisationId: organisationId.id,
            };
            axios({
                method: "get",
                url: `inspection/list-inspections`,
                headers: {Authorization: "Bearer " + token},
                params: data,
            })
                .then((res) => resolve(res.data as Inspection[]))
                .catch((error) => reject(error));
        });
    };

    static getInspection = (id: string) => {
        return new Promise<Inspection>((resolve, reject) => {
            let token = getData(StorageKey.TOKEN) as string;
            let organisationId: OrgaAndRoles = JSON.parse(
                getData(StorageKey.SELECTED_ORGANISATION) as string
            );
            const data = {
                organisationId: organisationId.id,
            };
            axios({
                method: "get",
                url: `inspection/list-inspections/${id}`,
                headers: {Authorization: "Bearer " + token},
                params: data,
            })
                .then((res) => resolve(res.data as Inspection))
                .catch((error) => reject(error));
        });
    };

    static getCatchReports = (year: number) => {
        return new Promise<CatchReport[]>((resolve, reject) => {
            let organisationId: OrgaAndRoles = JSON.parse(
                getData(StorageKey.SELECTED_ORGANISATION) as string
            );
            let token = getData(StorageKey.TOKEN) as string;
            axios({
                method: "get",
                url: `/usercatch/${organisationId.id}/catch-report-list`,
                headers: {Authorization: "Bearer " + token},
                params: {
                    year: year,
                },
            })
                .then((res) => resolve(res.data as CatchReport[]))
                .catch((error) => reject(error));
        });
    };

    static deleteCatchReport = (dto: CatchReport) => {
        return new Promise<void>((resolve, reject) => {
            let token = getData(StorageKey.TOKEN) as string;
            axios({
                method: "delete",
                url: `/usercatch/${getOrganisation().id}/catch-report-list/${dto.id}`,
                headers: {Authorization: "Bearer " + token},
            })
                .then((res) => resolve())
                .catch((error) => reject(error));
        });
    };

    static getCatchReportYears = () => {
        return new Promise<number[]>((resolve, reject) => {
            let organisationId: OrgaAndRoles = JSON.parse(
                getData(StorageKey.SELECTED_ORGANISATION) as string
            );
            let token = getData(StorageKey.TOKEN) as string;
            axios({
                method: "get",
                url: `/usercatch/${organisationId.id}/catch-report-years`,
                headers: {Authorization: "Bearer " + token},
            })
                .then((res) => resolve(res.data as number[]))
                .catch((error) => reject(error));
        });
    };

    static getReportedCatchYears = (catchYear: CatchYear) => {
        return new Promise<ReportedCatchYearDoneDTO[]>((resolve, reject) => {
            let organisation: OrgaAndRoles = JSON.parse(
                getData(StorageKey.SELECTED_ORGANISATION) as string
            );
            let token = getData(StorageKey.TOKEN) as string;
            axios({
                method: "get",
                url: `/report-catch-year/list/${organisation.id}/${catchYear.id}`,
                headers: {Authorization: "Bearer " + token},
            })
                .then((res) => resolve(res.data as ReportedCatchYearDoneDTO[]))
                .catch((error) => reject(error));
        });
    };

    static getGuestCardsFromOrganisation = (organisationId: String) => {
        return new Promise<GuestCard[]>((resolve, reject) => {
            axios({
                method: "get",
                url: "guestcard/list",
                params: {
                    organisationId: organisationId,
                },
            })
                .then((res) => resolve(res.data as GuestCard[]))
                .catch((error) => reject(error));
        });
    };

    static getGuestCardById = (id: string) => {
        return new Promise<GuestCardDTO>(function (resolve, reject) {
            axios({
                method: "get",
                url: `guestcard/id/${id}`,
            })
                .then((res) => resolve(res.data as GuestCardDTO))
                .catch((error) => reject(error));
        });
    };

    static addGuestCard = (card: GuestCardDTO) => {
        return new Promise<void>(function (resolve, reject) {
            const token = getBearerToken();
            const organisation = getOrganisation();
            axios({
                method: "post",
                url: `/organisation/${organisation.id}/guestcard`,
                headers: {Authorization: `Bearer ${token}`},
                data: card,
            })
                .then(async () => {
                    resolve();
                })
                .catch((error) => reject(error));
        });
    };

    static updateGuestCard = (card: GuestCardDTO) => {
        return new Promise<void>(function (resolve, reject) {
            const token = getBearerToken();
            const organisation = getOrganisation();
            axios({
                method: "put",
                url: `/organisation/${organisation.id}/guestcard`,
                headers: {Authorization: `Bearer ${token}`},
                data: card,
            })
                .then(async () => {
                    resolve();
                })
                .catch((error) => reject(error));
        });
    };

    static deleteGuestCard = (cardId: string) => {
        return new Promise<void>(function (resolve, reject) {
            const token = getBearerToken();
            const organisation = getOrganisation();
            axios({
                method: "delete",
                url: `/organisation/${organisation.id}/guestcard/${cardId}`,
                headers: {Authorization: `Bearer ${token}`},
            })
                .then(async () => {
                    resolve();
                })
                .catch((error) => reject(error));
        });
    };

    static listGuestCardsByOrganisationId = () => {
        const organisation = getOrganisation();
        return new Promise<GuestCardDTO[]>((resolve, reject) => {
            const token = getBearerToken();
            axios({
                method: "get",
                url: `organisation/${organisation.id}/guestcard`,
                headers: {Authorization: `Bearer ${token}`},
            })
                .then((res) => resolve(res.data as GuestCardDTO[]))
                .catch((error) => reject(error));
        });
    };

    static loadWaterRegulationFiles = (organisationId: string) => {
        return new Promise<WaterRegulationFile[]>(function (resolve, reject) {
            axios({
                method: "get",
                url: `/organisation/${organisationId}/water-regulation-files`,
            })
                .then((res) => {
                    resolve(res.data);
                })
                // if error log the error
                .catch((error) => {
                    reject(error);
                });
        });
    };

    static createPaymentIntent = (draft: PaymentDraft) => {
        return new Promise<PaymentDraft>(function (resolve, reject) {
            axios({
                method: "post",
                url: "/stripe/payment/draft",
                data: draft,
            })
                .then(async (res) => {
                    resolve(res.data);
                })
                .catch((error) => reject(error));
        });
    };

    static getIntent = (draft: PaymentDraft) => {
        return new Promise<StripePaymentInformation>(function (resolve, reject) {
            axios({
                method: "get",
                url: "/stripe/payment/intent",
                params: {
                    draftId: draft.id,
                },
            })
                .then(async (res) => {
                    resolve(res.data);
                })
                .catch((error) => reject(error));
        });
    };

    static getOrganisationByToken = (organisationToken: string) => {
        return new Promise<Organisation>((resolve, reject) => {
            axios({
                method: "get",
                url: `/organisation/token/${organisationToken}`,
            })
                .then(async (res) => {
                    resolve(res.data);
                })
                .catch((error) => reject(error));
        });
    };

    static getUserCatchReports = (data: {
        organisationToken: string;
        memberNumber: string;
        lastName: string;
    }) => {
        return new Promise<UserCatchReport[]>((resolve, reject) => {
            axios({
                method: "get",
                url: `/usercatch/listAll`,
                params: {
                    mnr: data.memberNumber,
                    lastName: data.lastName,
                    organisationToken: data.organisationToken,
                },
            })
                .then(async (res) => {
                    resolve(res.data);
                })
                .catch((error) => reject(error));
        });
    };

    static reportCatchYearAvailable = (data: { organisationId: string }) => {
        return new Promise<CatchYear>((resolve, reject) => {
            axios({
                method: "get",
                url: `/report-catch-year/available-reports/${data.organisationId}`,
            })
                .then(async (res) => {
                    resolve(res.data);
                })
                .catch((error) => reject(error));
        });
    };

    static listMyReportedCatchYears = async (data: {
        organisationId: string;
        memberNumber: string;
        lastName: string;
    }) => {
        const result: AxiosResponse<ReportCatchYearDTO[]> = await axios({
            method: "GET",
            url: `/report-catch-year/${data.organisationId}/${data.memberNumber}/${data.lastName}`,
        });
        return result.data;
    };

    static reportCatchYear = async (data: {
        organisationId: string;
        memberNumber: string;
    }) => {
        const result: AxiosResponse = await axios({
            method: "POST",
            url: `/report-catch-year`,
            data: {
                organisationId: data.organisationId,
                number: data.memberNumber,
            },
        });
        return result;
    };

    static getOrganisationWaters = (organisationId: string) => {
        return new Promise<WaterDTO[]>((resolve, reject) => {
            axios({
                method: "get",
                url: `organisation/${organisationId}/waters`,
            })
                .then(async (res) => {
                    resolve(res.data);
                })
                .catch((error) => reject(error));
        });
    };

    static getOrganisationFishes = (organisationId: string) => {
        return new Promise<Fish[]>((resolve, reject) => {
            axios({
                method: "get",
                url: `orgafish/flat-list`,
                params: {
                    orgaId: organisationId,
                },
            })
                .then(async (res) => {
                    resolve(res.data);
                })
                .catch((error) => reject(error));
        });
    };

    static addUserCatchResult = (data: UserCatchReportAddFormData) => {
        return new Promise((resolve, reject) => {
            axios({
                method: "post",
                url: "usercatch/",
                data: data,
            })
                .then(async (res) => {
                    resolve(res.data);
                })
                .catch((error) => reject(error));
        });
    };

    static getGuestCardCatchReports = (data: {
        guestCardToken: string;
        organisationToken: string;
    }) => {
        return new Promise<UserCatchReport[]>((resolve, reject) => {
            axios({
                method: "get",
                url: "usercatch/",
                params: data,
            })
                .then(async (res) => {
                    resolve(res.data);
                })
                .catch((error) => reject(error));
        });
    };

    static listGuestCardsSellingOrganisations = () => {
        return new Promise<Organisation[]>(function (resolve, reject) {
            axios({
                method: "get",
                url: "/guestcard/listGuestCardsSellingOrganisations",
            })
                .then(async (res) => {
                    resolve(res.data);
                })
                .catch((error) => reject(error));
        });
    };

    static getBoughtGuestCards = () => {
        let organisation: MyOrganisation = getOrganisation();
        let token = getBearerToken();
        return new Promise<BoughtGuestCard[]>(function (resolve, reject) {
            axios({
                method: "get",
                url: "/stripe/payment/payments",
                params: {
                    organisationId: organisation.id,
                },
                headers: {Authorization: "Bearer " + token},
            })
                .then(async (res) => {
                    resolve(res.data);
                })
                .catch((error) => reject(error));
        });
    };

    static refundGuestCard = (transactionId: string) => {
        let organisation: MyOrganisation = getOrganisation();
        let token = getBearerToken();
        let refund: Refund = {
            organisationId: organisation.id,
            transactionId: transactionId,
        };
        return new Promise(function (resolve, reject) {
            axios({
                method: "post",
                url: "/stripe/payment/refund",
                data: refund,
                headers: {Authorization: "Bearer " + token},
            })
                .then(async (res) => {
                    resolve(res.data);
                })
                .catch((error) => reject(error));
        });
    };

    static getCatchYears = () => {
        return new Promise<CatchYear[]>((resolve, reject) => {
            let organisationId: OrgaAndRoles = getOrganisation();
            let token = getBearerToken();
            axios({
                method: "get",
                url: `/catch-year/${organisationId.id}`,
                headers: {Authorization: "Bearer " + token},
            })
                .then((res) => resolve(res.data as CatchYear[]))
                .catch((error) => reject(error));
        });
    };

    static getDocuments = () => {
        return new Promise<DocumentDTO[]>((resolve, reject) => {
            let organisationId: OrgaAndRoles = getOrganisation();
            axios({
                method: "get",
                url: `/document/organisation/${organisationId.id}`,
            })
                .then((res) => resolve(res.data as DocumentDTO[]))
                .catch((error) => reject(error));
        });
    };

    static updateDocument = (document: DocumentDTO) => {
        return new Promise<DocumentDTO>((resolve, reject) => {
            let organisationId: OrgaAndRoles = getOrganisation();
            let token = getBearerToken();
            axios({
                url: `/document/organisation/${organisationId.id}`,
                method: "put",
                data: document,
                headers: {
                    Authorization: "Bearer " + token,
                },
            })
                .then((res) => resolve(res.data as DocumentDTO))
                .catch((error) => reject(error));
        });
    };

    static deleteDocument = (document: DocumentDTO) => {
        return new Promise<void>((resolve, reject) => {
            let organisationId: OrgaAndRoles = getOrganisation();
            let token = getBearerToken();
            axios({
                url: `/document/organisation/${organisationId.id}/document/${document.id}`,
                method: "delete",
                headers: {
                    "Content-Type": "multipart/form-data",
                    Authorization: "Bearer " + token,
                },
            })
                .then((res) => resolve())
                .catch((error) => reject(error));
        });
    };

    static uploadDocument = (file: File) => {
        return new Promise<DocumentDTO[]>((resolve, reject) => {
            const formData = new FormData();
            formData.append("file", file);
            let organisationId: OrgaAndRoles = getOrganisation();
            let token = getBearerToken();
            axios
                .post(`/document/organisation/${organisationId.id}`, formData, {
                    headers: {
                        "Content-Type": "multipart/form-data",
                        Authorization: "Bearer " + token,
                    },
                })
                .then((res) => resolve(res.data as DocumentDTO[]))
                .catch((error) => reject(error));
        });
    };

    static getOrgaFishes = () => {
        return new Promise<OrgaFishDTO[]>((resolve, reject) => {
            let organisationId: OrgaAndRoles = getOrganisation();
            let token = getBearerToken();
            axios({
                method: "get",
                url: `/orgaFish/by-organisation/${organisationId.id}`,
                headers: {Authorization: "Bearer " + token},
            })
                .then((res) => resolve(res.data as OrgaFishDTO[]))
                .catch((error) => reject(error));
        });
    };

    static getWaterContactPerson = (waterId: string) => {
        return new Promise<WaterContactPersonDTO[]>((resolve, reject) => {
            axios({
                method: "GET",
                url: `/water-contact-person/water/${waterId}`,
            })
                .then((res) => resolve(res.data as WaterContactPersonDTO[]))
                .catch((error) => reject(error));
        });
    };

    static saveWaterContactPerson = (dto: WaterContactPersonDTO) => {
        return new Promise<WaterContactPersonDTO[]>((resolve, reject) => {
            let organisationId: OrgaAndRoles = getOrganisation();
            let token = getBearerToken();
            axios({
                method: "POST",
                url: `/water-contact-person/${organisationId}`,
                headers: {Authorization: "Bearer " + token},
                data: dto,
            })
                .then((res) => resolve(res.data as WaterContactPersonDTO[]))
                .catch((error) => reject(error));
        });
    };

    static deleteCatchYear = (catchYear: CatchYear) => {
        return new Promise<CatchYear[]>((resolve, reject) => {
            let organisationId: OrgaAndRoles = getOrganisation();
            let token = getBearerToken();
            axios({
                method: "DELETE",
                url: `/catch-year/${organisationId.id}/${catchYear.id}`,
                headers: {Authorization: "Bearer " + token},
            })
                .then((res) => resolve(res.data as CatchYear[]))
                .catch((error) => reject(error));
        });
    };

    static saveCatchYears = (catchYear: CatchYear) => {
        return new Promise<CatchYear[]>((resolve, reject) => {
            let organisationId: OrgaAndRoles = getOrganisation();
            let token = getBearerToken();
            axios({
                method: "post",
                url: `/catch-year/${organisationId.id}`,
                headers: {Authorization: "Bearer " + token},
                data: catchYear,
            })
                .then((res) => resolve(res.data as CatchYear[]))
                .catch((error) => reject(error));
        });
    };

    static updateCatchYear = (catchYear: CatchYear) => {
        return new Promise<CatchYear[]>((resolve, reject) => {
            let organisationId: OrgaAndRoles = getOrganisation();
            let token = getBearerToken();
            axios({
                method: "PUT",
                url: `/catch-year/${organisationId.id}`,
                headers: {Authorization: "Bearer " + token},
                data: catchYear,
            })
                .then((res) => resolve(res.data as CatchYear[]))
                .catch((error) => reject(error));
        });
    };

    static getFeatures = () => {
        return new Promise<FeatureDTO[]>((resolve, reject) => {
            let organisationId: OrgaAndRoles = getOrganisation();
            let token = getBearerToken();
            axios({
                method: "GET",
                url: `/features/${organisationId.id}`,
                headers: {Authorization: "Bearer " + token},
            })
                .then((res) => resolve(res.data as FeatureDTO[]))
                .catch((error) => reject(error));
        });
    };

    static getAppointmentFeatureOrganisations = () => {
        return new Promise<Organisation[]>((resolve, reject) => {
            let token = getBearerToken();
            axios({
                method: "GET",
                url: `/appointment-category/get-organisations`,
                headers: {Authorization: "Bearer " + token},
            })
                .then((res) => resolve(res.data as Organisation[]))
                .catch((error) => reject(error));
        });
    };

    static saveCategory = (dto: AppointmentCategoryDTO) => {
        return new Promise<AppointmentCategoryDTO[]>((resolve, reject) => {
            let organisation: OrgaAndRoles = getOrganisation();
            let token = getBearerToken();
            axios({
                method: "POST",
                url: `/appointment-category/${organisation.id}`,
                headers: {Authorization: "Bearer " + token},
                data: dto,
            })
                .then((res) => resolve(res.data as AppointmentCategoryDTO[]))
                .catch((error) => reject(error));
        });
    };

    static getCategories = () => {
        return new Promise<AppointmentCategoryDTO[]>((resolve, reject) => {
            let organisation: OrgaAndRoles = getOrganisation();
            let token = getBearerToken();
            axios({
                method: "GET",
                url: `/appointment-category/${organisation.id}`,
                headers: {Authorization: "Bearer " + token},
            })
                .then((res) => resolve(res.data as AppointmentCategoryDTO[]))
                .catch((error) => reject(error));
        });
    };

    static deleteCategory = (dto: AppointmentCategoryDTO) => {
        return new Promise<AppointmentCategoryDTO[]>((resolve, reject) => {
            let organisation: OrgaAndRoles = getOrganisation();
            let token = getBearerToken();
            axios({
                method: "DELETE",
                url: `/appointment-category/${organisation.id}/${dto.id}`,
                headers: {Authorization: "Bearer " + token},
            })
                .then((res) => resolve(res.data as AppointmentCategoryDTO[]))
                .catch((error) => reject(error));
        });
    };

    static setFeature = (feature: FeatureDTO) => {
        return new Promise<FeatureDTO[]>((resolve, reject) => {
            let organisationId: OrgaAndRoles = getOrganisation();
            let token = getBearerToken();
            axios({
                method: "POST",
                url: `/features/${organisationId.id}`,
                headers: {Authorization: "Bearer " + token},
                data: feature,
            })
                .then((res) => resolve(res.data as FeatureDTO[]))
                .catch((error) => reject(error));
        });
    };

    static featureEnabled = (feature: Features) => {
        return new Promise<boolean>((resolve, reject) => {
            let organisationId: OrgaAndRoles = getOrganisation();
            let token = getBearerToken();
            axios({
                method: "GET",
                url: `/features/${organisationId.id}/${feature}`,
                headers: {Authorization: "Bearer " + token},
            })
                .then((res) => resolve(res.data as boolean))
                .catch((error) => reject(error));
        });
    };

    static getUserList = () => {
        return new Promise<UserDTO[]>((resolve, reject) => {
            let organisationId: OrgaAndRoles = getOrganisation();
            let token = getBearerToken();
            axios({
                method: "GET",
                url: `/organisation/${organisationId.id}/list-users`,
                headers: {Authorization: "Bearer " + token},
            })
                .then((res) => resolve(res.data as UserDTO[]))
                .catch((error) => reject(error));
        });
    };

    static addUser = (user: UserDTO) => {
        return new Promise<UserDTO[]>((resolve, reject) => {
            let organisationId: OrgaAndRoles = getOrganisation();
            let token = getBearerToken();
            axios({
                method: "POST",
                url: `/organisation/${organisationId.id}/list-users`,
                headers: {Authorization: "Bearer " + token},
                data: user,
            })
                .then((res) => resolve(res.data as UserDTO[]))
                .catch((error) => reject(error));
        });
    };

    static updateUser = (user: UserDTO) => {
        return new Promise<UserDTO[]>((resolve, reject) => {
            let organisationId: OrgaAndRoles = getOrganisation();
            let token = getBearerToken();
            axios({
                method: "PUT",
                url: `/organisation/${organisationId.id}/list-users`,
                headers: {Authorization: "Bearer " + token},
                data: user,
            })
                .then((res) => resolve(res.data as UserDTO[]))
                .catch((error) => reject(error));
        });
    };

    static deleteUser = (user: UserDTO) => {
        return new Promise<UserDTO[]>((resolve, reject) => {
            let organisationId: OrgaAndRoles = getOrganisation();
            let token = getBearerToken();
            axios({
                method: "DELETE",
                url: `/organisation/${organisationId.id}/list-users/${user.userId}`,
                headers: {Authorization: "Bearer " + token},
            })
                .then((res) => resolve(res.data as UserDTO[]))
                .catch((error) => reject(error));
        });
    };

    static addMemberWorkMapping = (dto: MemberWorkMappingDTO) => {
        return new Promise<MemberWorkMappingDTO[]>((resolve, reject) => {
            let organisationId: OrgaAndRoles = getOrganisation();
            let token = getBearerToken();
            axios({
                method: "POST",
                url: `/member-work/${organisationId.id}`,
                headers: {Authorization: "Bearer " + token},
            })
                .then((res) => resolve(res.data as MemberWorkMappingDTO[]))
                .catch((error) => reject(error));
        });
    };

    static getMemberWorkMapping = () => {
        return new Promise<MemberWorkMappingDTO[]>((resolve, reject) => {
            let organisationId: OrgaAndRoles = getOrganisation();
            let token = getBearerToken();
            axios({
                method: "GET",
                url: `/member-work/${organisationId.id}`,
                headers: {Authorization: "Bearer " + token},
            })
                .then((res) => resolve(res.data as MemberWorkMappingDTO[]))
                .catch((error) => reject(error));
        });
    };

    static deleteMemberWorkMapping = (dto: MemberWorkMappingDTO) => {
        return new Promise<MemberWorkMappingDTO[]>((resolve, reject) => {
            let organisationId: OrgaAndRoles = getOrganisation();
            let token = getBearerToken();
            axios({
                method: "DELETE",
                url: `/member-work/${organisationId.id}`,
                headers: {Authorization: "Bearer " + token},
                data: dto,
            })
                .then((res) => resolve(res.data as MemberWorkMappingDTO[]))
                .catch((error) => reject(error));
        });
    };

    static getMemberWorkResponse = () => {
        return new Promise<MemberWorkResponseDTO[]>((resolve, reject) => {
            let organisationId: OrgaAndRoles = getOrganisation();
            let token = getBearerToken();
            axios({
                method: "GET",
                url: `/member-work/${organisationId.id}/response`,
                headers: {Authorization: "Bearer " + token},
            })
                .then((res) => resolve(res.data as MemberWorkResponseDTO[]))
                .catch((error) => reject(error));
        });
    };

    static activateUser = (token: string, password: PasswordDTO) => {
        return new Promise<void>((resolve, reject) => {
            axios({
                method: "POST",
                url: `/user/activate-user`,
                data: password,
                params: {
                    activationToken: token,
                },
            })
                .then((res) => resolve())
                .catch((error) => reject(error));
        });
    };

    static tokenValid = (token: string) => {
        return new Promise<void>((resolve, reject) => {
            axios({
                method: "GET",
                url: `/user/token-valid/${token}`,
            })
                .then((res) => resolve())
                .catch((error) => reject(error));
        });
    };

    static requestPasswordResetMail = (email: string) => {
        return new Promise<void>(function (resolve, reject) {
            axios({
                method: "post",
                url: `/user/resetPassword?email=${encodeURI(email)}`,
            })
                .then((_) => resolve())
                .catch((error) => reject(error));
        });
    };

    static resetPassword = (token: string, password: string): Promise<string> => {
        return new Promise<string>(function (resolve, reject) {
            axios({
                method: "put",
                url: `/user/resetPassword`,
                data: {
                    token,
                    password,
                },
            })
                .then((v) => {
                    if (v) resolve(v.statusText);
                    reject("Keine Antwort vom Server erhalten.");
                })
                .catch((error) => reject(error));
        });
    };

    static addMemberWorkExtended = (dto: MemberWorkExtendedDTO) => {
        return new Promise<MemberWorkExtendedDTO[]>((resolve, reject) => {
            axios({
                method: "POST",
                url: `/member-work-extended`,
                headers: {Authorization: "Bearer " + getBearerToken()},
                data: dto,
            })
                .then((res) => {
                    if (res) resolve(res.data as MemberWorkExtendedDTO[]);
                    reject(new Error("error response"));
                })
                .catch((error) => reject(error));
        });
    };

    static updateMemberWorkExtended = (dto: MemberWorkExtendedDTO) => {
        return new Promise<MemberWorkExtendedDTO[]>((resolve, reject) => {
            axios({
                method: "PUT",
                url: `/member-work-extended`,
                headers: {Authorization: "Bearer " + getBearerToken()},
                data: dto,
            })
                .then((res) => resolve(res.data as MemberWorkExtendedDTO[]))
                .catch((error) => reject(error));
        });
    };

    static getMemberWorkExtended = (bygone: boolean) => {
        const bygoneUrl = bygone ? "/bygone" : "";
        const url = `/member-work-extended/${getOrganisation().id}${bygoneUrl}`;
        return new Promise<MemberWorkExtendedDTO[]>((resolve, reject) => {
            axios({
                method: "GET",
                url: url,
                headers: {Authorization: "Bearer " + getBearerToken()},
            })
                .then((res) => resolve(res.data as MemberWorkExtendedDTO[]))
                .catch((error) => reject(error));
        });
    };

    static deleteMemberWorkExtended = (memberWorkExtendedId: string) => {
        return new Promise<MemberWorkExtendedDTO[]>((resolve, reject) => {
            axios({
                method: "DELETE",
                url: `/member-work-extended/${
                    getOrganisation().id
                }/${memberWorkExtendedId}`,
                headers: {Authorization: "Bearer " + getBearerToken()},
            })
                .then((res) => resolve(res.data as MemberWorkExtendedDTO[]))
                .catch((error) => reject(error));
        });
    };

    static applyForMemberWork = (attendee: AddMemberWorkAttendeeDTO) => {
        return new Promise<void>((resolve, reject) => {
            axios({
                method: "POST",
                url: `/member-work-extended/${getOrganisation().id}`,
                data: attendee,
            })
                .then((res) => resolve())
                .catch((error) => reject(error));
        });
    };

    static getMemberWorkAttendees = (
        memberWorkExtended: MemberWorkExtendedDTO
    ) => {
        return new Promise<MemberWorkAttendeeDTO[]>((resolve, reject) => {
            axios({
                method: "GET",
                url: `/member-work-extended/${getOrganisation().id}/${
                    memberWorkExtended.id
                }`,
                headers: {Authorization: "Bearer " + getBearerToken()},
            })
                .then((res) => resolve(res.data as MemberWorkAttendeeDTO[]))
                .catch((error) => reject(error));
        });
    };

    static removeMemberWorkAttendee = (
        memberWorkExtended: MemberWorkExtendedDTO,
        attendee: MemberWorkAttendeeDTO
    ) => {
        return new Promise<MemberWorkAttendeeDTO[]>((resolve, reject) => {
            axios({
                method: "DELETE",
                url: `/member-work-extended/attendee/${getOrganisation().id}/${
                    memberWorkExtended.id
                }/${attendee.id}`,
                headers: {Authorization: "Bearer " + getBearerToken()},
            })
                .then((res) => resolve(res.data as MemberWorkAttendeeDTO[]))
                .catch((error) => reject(error));
        });
    };

    static getMemberWorkReport = (selectedYear: number) => {
        return new Promise<ReportMemberWorkDTO[]>((resolve, reject) => {
            axios({
                method: "GET",
                url: `/reports/member-work/${getOrganisation().id}/${selectedYear}`,
                headers: {Authorization: "Bearer " + getBearerToken()},
            })
                .then((res) => resolve(res.data as ReportMemberWorkDTO[]))
                .catch((error) => reject(error));
        });
    };

    static getMemberWorkReportYears = () => {
        return new Promise<number[]>((resolve, reject) => {
            axios({
                method: "GET",
                url: `/reports/member-work/${getOrganisation().id}/years`,
                headers: {Authorization: "Bearer " + getBearerToken()},
            })
                .then((res) => resolve(res.data as number[]))
                .catch((error) => reject(error));
        });
    };

    static addNews = (dto: NewsDTO) => {
        return new Promise<NewsDTO[]>((resolve, reject) => {
            axios({
                method: "POST",
                url: `/news/${getOrganisation().id}`,
                headers: {Authorization: "Bearer " + getBearerToken()},
                data: dto,
            })
                .then((res) => resolve(res.data as NewsDTO[]))
                .catch((error) => reject(error));
        });
    };

    static updateNews = (dto: NewsDTO) => {
        return new Promise<NewsDTO[]>((resolve, reject) => {
            axios({
                method: "PUT",
                url: `/news/${getOrganisation().id}`,
                headers: {Authorization: "Bearer " + getBearerToken()},
                data: dto,
            })
                .then((res) => resolve(res.data as NewsDTO[]))
                .catch((error) => reject(error));
        });
    };

    static deleteNews = (dto: NewsDTO) => {
        return new Promise<NewsDTO[]>((resolve, reject) => {
            axios({
                method: "DELETE",
                url: `/news/${getOrganisation().id}`,
                headers: {Authorization: "Bearer " + getBearerToken()},
                data: dto,
            })
                .then((res) => resolve(res.data as NewsDTO[]))
                .catch((error) => reject(error));
        });
    };

    static getNews = () => {
        return new Promise<NewsDTO[]>((resolve, reject) => {
            axios({
                method: "GET",
                url: `/news/${getOrganisation().id}`,
                headers: {Authorization: "Bearer " + getBearerToken()},
            })
                .then((res) => resolve(res.data as NewsDTO[]))
                .catch((error) => reject(error));
        });
    };

    static getMemberNews = (data: { number: string; lastname: string }) => {
        return new Promise<NewsDTO[]>((resolve, reject) => {
            axios({
                method: "GET",
                url: `/user/${getOrganisation().id}/member-news`,
                headers: {Authorization: "Bearer " + getBearerToken()},
                params: {
                    number: data.number,
                    lastname: data.lastname,
                },
            })
                .then((res) => resolve(res.data as NewsDTO[]))
                .catch((error) => reject(error));
        });
    };

    static getAppointments = () => {
        return new Promise<AppointmentDTO[]>((resolve, reject) => {
            axios({
                method: "GET",
                url: `/appointment/${getOrganisation().id}`,
                headers: {Authorization: "Bearer " + getBearerToken()},
            })
                .then((res) => resolve(res.data as AppointmentDTO[]))
                .catch((error) => reject(error));
        });
    };

    static addUserMember = (dto: MemberDTO) => {
        return new Promise<void>((resolve, reject) => {
            axios({
                method: "POST",
                url: `/organisation/${getOrganisation().id}/add-member`,
                headers: {Authorization: "Bearer " + getBearerToken()},
                data: dto,
            })
                .then((res) => resolve())
                .catch((error) => reject(error));
        });
    };

    static checkIfMemberExists = (dto: MemberDTO) => {
        return new Promise<void>((resolve, reject) => {
            axios({
                method: "GET",
                url: `/organisation/${getOrganisation().id}/check-member-exists`,
                headers: {Authorization: "Bearer " + getBearerToken()},
                params: {
                    number: dto.number,
                },
            })
                .then((response) => {
                    resolve();
                })
                .catch((error) => {
                    reject(error);
                });
        });
    };

    static deleteMember = (number: string) => {
        return new Promise<void>((resolve, reject) => {
            axios({
                method: "DELETE",
                url: `/organisation/${getOrganisation().id}/delete-member/${number}`,
                headers: {Authorization: "Bearer " + getBearerToken()},
            })
                .then((response) => {
                    resolve();
                })
                .catch((error) => {
                    reject(error);
                });
        });
    };

    static searchMember = (searchValue: string) => {
        return new Promise<MemberDTO[]>((resolve, reject) => {
            let token = getData(StorageKey.TOKEN) as string;
            axios({
                method: "post",
                url: `member/${getOrganisation().id}/search`,
                headers: {Authorization: "Bearer " + token},
                data: {searchValue},
            })
                .then((res) => resolve(res.data as MemberDTO[]))
                .catch((error) => reject(error));
        });
    };

    static addWater = (dto: WaternameDTO) => {
        return new Promise<void>((resolve, reject) => {
            let token = getData(StorageKey.TOKEN) as string;
            dto.organisationId = getOrganisation().id;
            axios({
                method: "post",
                url: `water/add-water`,
                headers: {Authorization: "Bearer " + token},
                data: dto,
            })
                .then((res) => resolve())
                .catch((error) => reject(error));
        });
    };

    static addAttendanceToMemberWork = (dto: MemberWorkAttendanceDTO) => {
        return new Promise<void>((resolve, reject) => {
            let token = getBearerToken();
            axios({
                method: "POST",
                url: `/member-work-extended/attendance/apply`,
                headers: {Authorization: "Bearer " + token},
                data: dto,
            })
                .then((res) => resolve())
                .catch((error) => reject(error));
        });
    };

    static removeAttendanceToMemberWork = (dto: MemberWorkAttendanceDTO) => {
        return new Promise<void>((resolve, reject) => {
            let token = getBearerToken();
            axios({
                method: "DELETE",
                url: `/member-work-extended/attendance/remove`,
                headers: {Authorization: "Bearer " + token},
                data: dto,
            })
                .then((res) => resolve())
                .catch((error) => reject(error));
        });
    };

    static moveWaterShape = (dto: MoveWaterShapeDTO) => {
        return new Promise<void>((resolve, reject) => {
            let token = getBearerToken();
            let org = getOrganisation();
            axios({
                method: "POST",
                url: `/water/move/${org.id}`,
                headers: {Authorization: "Bearer " + token},
                data: dto,
            })
                .then((res) => resolve())
                .catch((error) => reject(error));
        });
    };

    static handleDates(body: any) {
        if (body === null || body === undefined || typeof body !== "object")
            return body;

        if (Array.isArray(body)) {
            for (const elem of body) NetworkCalls.handleDates(elem);
            return body;
        }

        for (const key of Object.keys(body)) {
            const value = body[key];
            if (NetworkCalls.isIsoDateString(value)) body[key] = new Date(value);
            else if (typeof value === "object") NetworkCalls.handleDates(value);
        }
    }

    private static isIsoDateString(value: any): boolean {
        return (
            value &&
            typeof value === "string" &&
            NetworkCalls.isoDateFormat.test(value)
        );
    }
}
