import { useEffect, useState } from "react";
import { NetworkCalls } from "../../../NetworkCalls";
import Document from "./Document";
import DocumentDTO from "../../../dtos/DocumentDTO";
import EmptyPage from "../../../components/EmptyPage";
import { Features } from "../../../dtos/Features";

export default function DocumentList({ docs }: { docs: DocumentDTO[] }) {
  const [loading, isLoading] = useState<boolean>(false);
  const [documents, setDocuments] = useState<DocumentDTO[]>([]);
  const [guestCardEnabled, setGuestCardEnabled] = useState<boolean>(false);

  useEffect(() => {
    const fetchData = async () => {
      setGuestCardEnabled(
        await NetworkCalls.featureEnabled(Features.GUEST_CARD),
      );
      await loadData();
    };
    fetchData().then();
  }, []);

  useEffect(() => {
    setDocuments(docs);
  }, [docs]);

  const loadData = async () => {
    isLoading(true);
    setDocuments([
      {
        id: "",
        downloadFileName: "",
        fileSize: 0,
        fileType: "",
        url: "",
      },
      {
        id: "",
        downloadFileName: "",
        fileSize: 0,
        fileType: "",
        url: "",
      },
      {
        id: "",
        downloadFileName: "",
        fileSize: 0,
        fileType: "",
        url: "",
      },
    ]);
    NetworkCalls.getDocuments()
      .then((result) => {
        setDocuments(result);
        isLoading(false);
      })
      .catch((error) => {
        isLoading(false);
        //setError(error);
      });
  };

  const showLoadingPage = () => {
    return (
      <ul className="list-group list-group-flush placeholder-glow">
        {documents.map((doc: DocumentDTO) => (
          <Document
            loading={loading}
            documentDTO={doc}
            onDocumentDeleted={(dto) => {}}
            guestCardEnabled={guestCardEnabled}
          />
        ))}
      </ul>
    );
  };

  return (
    <div className="card">
      <div
        className="list-group list-group-flush overflow-auto"
        style={{ maxHeight: "35rem" }}
      >
        {documents.length === 0 ? <EmptyPage /> : ""}
        {loading
          ? showLoadingPage()
          : documents.map((doc: DocumentDTO) => (
              <Document
                loading={loading}
                documentDTO={doc}
                guestCardEnabled={guestCardEnabled}
                onDocumentDeleted={(dto) => {
                  loadData().then();
                }}
              />
            ))}
      </div>
    </div>
  );
}
