import LoadingPageIndicator from "../../../components/LoadingPageIndicator";
import HandleHttpResponses from "../../../components/NetworkErrorHandling";
import React, { useEffect, useState } from "react";
import { AxiosError } from "axios";
import uuid from "react-uuid";
import { NetworkCalls } from "../../../NetworkCalls";
import { UserDTO } from "../../../dtos/UserDTO";
import TomSelect from "tom-select";
import { Roles } from "../../../dtos/Roles";
import { getOrganisation } from "../../../service/LoadStorageData";
import { Button } from "react-bootstrap";

export default function UserList() {
  const [loading, isLoading] = useState<boolean>(false);
  const [error, setError] = useState<AxiosError>();
  const [filteredMembers, setFilteredMembers] = useState<UserDTO[]>([]);
  const [preSelectedUser, setPreSelectedUser] = useState<UserDTO | undefined>(
    undefined
  );
  const [users, setUsers] = useState<UserDTO[]>([]);
  const [modalOpenCount, setModalOpenCount] = useState<number>(0);
  const [formField, setFormField] = useState<UserDTO>({
    userId: "",
    organisationId: getOrganisation().id,
    firstName: "",
    lastName: "",
    email: "",
    active: false,
    roles: [],
  });

  useEffect(() => {
    isLoading(true);
    NetworkCalls.getUserList()
      .then((result) => {
        setUsers(result.sort(sortUsers));
        setFilteredMembers(result.sort(sortUsers));
        isLoading(false);
      })
      .catch((error) => {
        isLoading(false);
        setError(error);
      });
  }, []);

  useEffect(() => {
    const roleSelect = document.querySelector("#rollen");
    // @ts-ignore
    if (roleSelect && !roleSelect.tomselect && modalOpenCount !== 0) {
      // @ts-ignore
      new TomSelect("#rollen", {});
      new TomSelect("#rollen-update", {});
    }
  }, [modalOpenCount]);

  function search(value: string) {
    setFilteredMembers(
      users.filter(
        (user) =>
          contains(user.email, value) ||
          contains(user.lastName, value) ||
          contains(user.firstName, value)
      )
    );
  }

  function contains(field: string, value: string) {
    return field !== null && field.toUpperCase().includes(value.toUpperCase());
  }

  const sortUsers = (a: UserDTO, b: UserDTO) => {
    const nameA = a.lastName.toUpperCase(); // ignore upper and lowercase
    const nameB = b.lastName.toUpperCase(); // ignore upper and lowercase
    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }

    // names must be equal
    return 0;
  };

  const formFieldHandler = (e: { target: { name: any; value: any } }) => {
    let name = e.target.name;
    let value = e.target.value;
    setFormField({ ...formField, [name]: value });
  };

  const preSelectedUserHandler = (e: { target: { name: any; value: any } }) => {
    let name = e.target.name;
    let value = e.target.value;
    setPreSelectedUser({ ...preSelectedUser!, [name]: value });
  };

  function showContent() {
    return (
      <div>
        {addUserModal()}
        {editUserModal()}
        {deleteUserModalYesNo()}
        <div className="m-5 col row justify-content-center">
          <div className="card border-0">
            <div className="card-header">
              <h3>Übersicht Benutzer</h3>
            </div>
            <div className="card-body border-bottom py-3">
              <div className="d-flex">
                <div className="text-secondary">
                  Suche
                  <div className="mx-2 d-inline-block">
                    <input
                      type="text"
                      className="form-control"
                      aria-label="Default"
                      aria-describedby="inputGroup-sizing-default"
                      onChange={(elem) =>
                        search(elem.target.value.toUpperCase())
                      }
                    />
                  </div>
                </div>
                <div className="ms-auto text-secondary">
                  <div className="ms-2 d-inline-block">
                    <Button
                      className="btn btn-primary d-none d-sm-inline-block"
                      data-bs-toggle="modal"
                      data-bs-target="#modal-add-user"
                      onClick={(_) => {
                        setModalOpenCount(Math.floor(Math.random() * 10000));
                      }}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="icon icon-tabler icon-tabler-user-plus"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        strokeWidth="2"
                        stroke="currentColor"
                        fill="none"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      >
                        <path
                          stroke="none"
                          d="M0 0h24v24H0z"
                          fill="none"
                        ></path>
                        <path d="M8 7a4 4 0 1 0 8 0a4 4 0 0 0 -8 0"></path>
                        <path d="M16 19h6"></path>
                        <path d="M19 16v6"></path>
                        <path d="M6 21v-2a4 4 0 0 1 4 -4h4"></path>
                      </svg>
                      Eintrag hinzufügen
                    </Button>
                  </div>
                </div>
              </div>
            </div>
            {showUserTable()}
          </div>
        </div>
      </div>
    );
  }

  function showUserTable() {
    return (
      <table className="table table-hover">
        <thead>
          <tr key={uuid()}>
            <th scope="col">E-Mail</th>
            <th scope="col">Vorname</th>
            <th scope="col">Nachname</th>
            <th scope="col">Rollen</th>
            <th scope="col">Status</th>
            <th scope="col">Aktionen</th>
          </tr>
        </thead>
        <tbody id="table-content">{createRows()}</tbody>
      </table>
    );
  }

  async function deleteUser() {
    if (preSelectedUser) {
      isLoading(true);
      const result = await NetworkCalls.deleteUser(preSelectedUser);
      setUsers(result.sort(sortUsers));
      setFilteredMembers(result.sort(sortUsers));
      isLoading(false);
    }
  }

  function createRows() {
    return filteredMembers.map((elem) => {
      return (
        <tr key={uuid()} className="user-select-auto">
          <td>{elem.email}</td>
          <td> {elem.firstName}</td>
          <td>{elem.lastName}</td>
          <td>
            {elem.roles.map((e) => (
              <div className="mt-1">
                {getTranslationForRole(e)}
                <br />
              </div>
            ))}
          </td>
          <td>{elem.active ? "Aktiv" : "Inaktiv"}</td>
          <td>
            <div className="btn-list">
              <Button
                className="btn btn-outline-azure d-none d-sm-inline-block"
                data-bs-toggle="modal"
                data-bs-target="#modal-edit-user"
                onClick={(_) => {
                  setPreSelectedUser(elem);
                  setModalOpenCount(Math.floor(Math.random() * 10000));
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="icon icon-tabler icon-tabler-pencil"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  strokeWidth="2"
                  stroke="currentColor"
                  fill="none"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                  <path d="M4 20h4l10.5 -10.5a2.828 2.828 0 1 0 -4 -4l-10.5 10.5v4"></path>
                  <path d="M13.5 6.5l4 4"></path>
                </svg>
                Bearbeiten
              </Button>
              <Button
                className="btn btn-outline-red d-none d-sm-inline-block"
                data-bs-toggle="modal"
                data-bs-target="#modal-delete-user"
                onClick={() => setPreSelectedUser(elem)}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="icon icon-tabler icon-tabler-trash"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  strokeWidth="2"
                  stroke="currentColor"
                  fill="none"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                  <path d="M4 7l16 0"></path>
                  <path d="M10 11l0 6"></path>
                  <path d="M14 11l0 6"></path>
                  <path d="M5 7l1 12a2 2 0 0 0 2 2h8a2 2 0 0 0 2 -2l1 -12"></path>
                  <path d="M9 7v-3a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v3"></path>
                </svg>
                Löschen
              </Button>
            </div>
          </td>
        </tr>
      );
    });
  }

  const getTranslationForRole = (role: string) => {
    switch (role) {
      case "ORGANISATION_INSPECTOR_ADMINISTRATOR":
      case "ROLE_ORGANISATION_INSPECTOR_ADMINISTRATOR":
        return "Fischereiaufseher Administrator";
      case "ORGANISATION_MEMBER_LIST":
      case "ROLE_ORGANISATION_MEMBER_LIST":
        return "Mitgliederliste";
      case "ORGANISATION_GUESTCARD_ADMINISTRATOR":
      case "ROLE_ORGANISATION_GUESTCARD_ADMINISTRATOR":
        return "Gastkarten Administrator";
      case "ORGANISATION_INSPECTOR":
      case "ROLE_ORGANISATION_INSPECTOR":
        return "Fischereiaufseher";
      case "ORGANISATION_ADMINISTRATOR":
      case "ROLE_ORGANISATION_ADMINISTRATOR":
        return "Administrator";
      case "ORGANISATION_CATCHREPORT_ADMINISTRATOR":
      case "ROLE_ORGANISATION_CATCHREPORT_ADMINISTRATOR":
        return "Fangmeldung Administrator";
      case "ORGANISATION_MEMBERWORK_ADMINISTRATOR":
      case "ROLE_ORGANISATION_MEMBERWORK_ADMINISTRATOR":
        return "Arbeitsdienst Administrator";
      default:
        return role;
    }
  };

  function addNewUser() {
    const selectedRoles = document.querySelector("#rollen");
    // @ts-ignore
    if (selectedRoles && selectedRoles["tomselect"]) {
      // @ts-ignore
      const tomselect = selectedRoles["tomselect"] as TomSelect;
      formField.roles = tomselect.items.map(
        (role) => role.replace("ROLE_", "") as Roles
      );

      const fetchData = async () => {
        const data = await NetworkCalls.addUser(formField);
        setFilteredMembers(data);
        setUsers(data);
      };

      fetchData().then();
    }
  }

  const addUserModal = () => {
    return (
      <div
        className="modal modal-blur"
        id="modal-add-user"
        role="dialog"
        aria-modal="true"
      >
        <div
          className="modal-dialog modal-lg modal-dialog-centered"
          role="document"
        >
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Neuer Benutzer</h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <div className="mb-3">
                <label className="form-label">Vorname</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Vorname"
                  name="firstName"
                  value={formField.firstName}
                  onChange={formFieldHandler}
                />
              </div>
              <div className="mb-3">
                <label className="form-label">Nachname</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Nachname"
                  name="lastName"
                  value={formField.lastName}
                  onChange={formFieldHandler}
                />
              </div>
              <div className="mb-3">
                <label className="form-label">E-Mail</label>
                <input
                  type="text"
                  className="form-control"
                  name="email"
                  value={formField.email}
                  onChange={formFieldHandler}
                  placeholder="E-Mail"
                />
              </div>
              <div className="mb-3">
                <label className="form-label">Rollen</label>
                <select
                  id="rollen"
                  className="form-control"
                  multiple
                  placeholder="Wähle Rollen aus..."
                >
                  {Object.values(Roles).map((role) => (
                    <option value={role}>{getTranslationForRole(role)}</option>
                  ))}
                </select>
              </div>
            </div>
            <div className="modal-footer">
              <Button
                className="btn btn-link link-secondary"
                data-bs-dismiss="modal"
              >
                Abbrechen
              </Button>
              <Button
                className="btn btn-primary ms-auto"
                data-bs-dismiss="modal"
                onClick={addNewUser}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="icon"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  strokeWidth="2"
                  stroke="currentColor"
                  fill="none"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                  <path d="M12 5l0 14"></path>
                  <path d="M5 12l14 0"></path>
                </svg>
                Neuen Benutzer hinzufügen
              </Button>
            </div>
          </div>
        </div>
      </div>
    );
  };

  function createOptions(roles: Roles[] | undefined) {
    return Object.values(Roles).map((role) => {
      return (
        <option
          value={role}
          selected={
            roles?.filter((elem) => role.split("ROLE_")[1] === elem).length ===
            1
          }
        >
          {getTranslationForRole(role)}
        </option>
      );
    });
  }

  function updateUser() {
    isLoading(true);
    const selectedRoles = document.querySelector("#rollen-update");
    // @ts-ignore
    if (selectedRoles && selectedRoles["tomselect"]) {
      // @ts-ignore
      const tomselect = selectedRoles["tomselect"] as TomSelect;
      preSelectedUser!.roles = tomselect.items.map(
        (role) => role.replace("ROLE_", "") as Roles
      );

      const fetchData = async () => {
        const data = await NetworkCalls.updateUser(preSelectedUser!);
        setFilteredMembers(data);
        setUsers(data);
        isLoading(false);
      };

      fetchData().then();
    }
  }

  const editUserModal = () => {
    return (
      <div
        className="modal modal-blur"
        id="modal-edit-user"
        role="dialog"
        aria-modal="true"
      >
        <div
          className="modal-dialog modal-lg modal-dialog-centered"
          role="document"
        >
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Benutzer bearbeiten</h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <div className="mb-3">
                <label className="form-label">Vorname</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Vorname"
                  name="firstName"
                  value={preSelectedUser?.firstName}
                  onChange={preSelectedUserHandler}
                />
              </div>
              <div className="mb-3">
                <label className="form-label">Nachname</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Nachname"
                  name="lastName"
                  value={preSelectedUser?.lastName}
                  onChange={preSelectedUserHandler}
                />
              </div>
              <div className="mb-3">
                <label className="form-label">E-Mail</label>
                <input
                  disabled={true}
                  type="text"
                  className="form-control"
                  name="email"
                  value={preSelectedUser?.email}
                  onChange={formFieldHandler}
                  placeholder="E-Mail"
                />
              </div>
              <div className="mb-3">
                <label className="form-label">Rollen</label>
                <select
                  id="rollen-update"
                  className="form-control"
                  multiple
                  placeholder="Wähle Rollen aus..."
                >
                  {createOptions(preSelectedUser?.roles)}
                </select>
              </div>
            </div>
            <div className="modal-footer">
              <Button
                className="btn btn-link link-secondary"
                data-bs-dismiss="modal"
              >
                Abbrechen
              </Button>
              <Button
                className="btn btn-primary ms-auto"
                data-bs-dismiss="modal"
                onClick={updateUser}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="icon icon-tabler icon-tabler-upload"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  strokeWidth="2"
                  stroke="currentColor"
                  fill="none"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                  <path d="M4 17v2a2 2 0 0 0 2 2h12a2 2 0 0 0 2 -2v-2"></path>
                  <path d="M7 9l5 -5l5 5"></path>
                  <path d="M12 4l0 12"></path>
                </svg>
                Speichern
              </Button>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const deleteUserModalYesNo = () => {
    return (
      <div
        className="modal modal-blur"
        id="modal-delete-user"
        role="dialog"
        aria-modal="true"
      >
        <div
          className="modal-dialog modal-lg modal-dialog-centered"
          role="document"
        >
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Benutzer löschen?</h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              Benutzer {preSelectedUser?.firstName} {preSelectedUser?.lastName}{" "}
              wirklich löschen?
            </div>
            <div className="modal-footer">
              <Button
                className="btn btn-link link-secondary"
                data-bs-dismiss="modal"
              >
                Abbrechen
              </Button>
              <Button
                className="btn btn-primary ms-auto"
                data-bs-dismiss="modal"
                onClick={deleteUser}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="icon icon-tabler icon-tabler-trash"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  strokeWidth="2"
                  stroke="currentColor"
                  fill="none"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                  <path d="M4 7l16 0"></path>
                  <path d="M10 11l0 6"></path>
                  <path d="M14 11l0 6"></path>
                  <path d="M5 7l1 12a2 2 0 0 0 2 2h8a2 2 0 0 0 2 -2l1 -12"></path>
                  <path d="M9 7v-3a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v3"></path>
                </svg>
                Benutzer löschen
              </Button>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div>
      {loading ? (
        <LoadingPageIndicator />
      ) : error ? (
        HandleHttpResponses(error)
      ) : (
        showContent()
      )}
    </div>
  );
}
