import {Button, Form, Modal} from "react-bootstrap";
import React, {useId, useState} from "react";
import {NetworkCalls} from "../NetworkCalls";
import {WaternameDTO} from "../dtos/WaternameDTO";

export default function AddNewWaterDialog({
                                              afterSuccess,
                                              onClose,
                                              show,
                                          }: {
    afterSuccess: () => void,
    onClose: () => void,
    show: boolean;
}) {
    const [waterName, setWaterName] = useState<string>("");
    const modalId = useId();

    const onCloseInternal = () => {
        onClose();
    }

    const onConfirm = async () => {
        await NetworkCalls.addWater({name: waterName, organisationId: ""} as WaternameDTO);
    }

    return (
        <Modal show={show} id={modalId} onHide={onCloseInternal}>
            <Modal.Header closeButton>
                <Modal.Title>Umrandung verschieben</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Form.Group>
                        <Form.Label className="required">Gewässername</Form.Label>
                        <Form.Control
                            id="name"
                            type="text"
                            placeholder="Gewässername"
                            value={waterName}
                            onChange={(e) => setWaterName(e.target.value)}
                        />
                    </Form.Group>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="danger" onClick={onConfirm}>
                    Speichern
                </Button>
                <Button variant="secondary" onClick={onCloseInternal}>
                    Abbrechen
                </Button>
            </Modal.Footer>
        </Modal>
    );
}
