import { Shape, Umrandung } from "./Umrandung";
import { Polyline } from "./polyline";
import React from "react";
import { Polygon } from "./polygon";
import GeoCoordinatesDTO from "../../../dtos/GeoCoordinatesDTO";

const Gewaesserumrandung = ({
  umrandung,
  visible,
}: {
  umrandung: Umrandung;
  visible: boolean;
}) => {
  const createPaths = (coordinates: GeoCoordinatesDTO[]) => {
    return coordinates.sort((a,b) => a.groupPos - b.groupPos).map((elem) => {
      return {
        lat: elem.latitude,
        lng: elem.longitude,
      };
    });
  };
  const showContent = (umrandung: Umrandung) => {
    switch (umrandung.shape) {
      case Shape.SHAPE_POLYGON:
        return (
          <Polygon
            strokeColor={"#00ff00"}
            paths={createPaths(umrandung.coordinates)}
          />
        );
      case Shape.PROTECTION_POLYGON:
        return (
          <Polygon
            strokeColor={"#ff0000"}
            paths={createPaths(umrandung.coordinates)}
          />
        );

      case Shape.SHAPE_POLYLINE:
        return (
          <Polyline
            strokeColor={"#00ff00"}
            path={createPaths(umrandung.coordinates)}
          />
        );
      case Shape.PROTECTION_POLYLINE:
        return (
          <Polyline
            strokeColor={"#ff0000"}
            path={createPaths(umrandung.coordinates)}
          />
        );
    }
  };

  return <>{visible ? showContent(umrandung) : ""}</>;
};

export default Gewaesserumrandung;
