import { Form } from "react-bootstrap";
import React, { useEffect } from "react";
import { WaterDTO } from "../../dtos/WaterDTO";
import {
  Control,
  FieldErrors,
  UseFormRegister,
  UseFormSetValue,
} from "react-hook-form";
import Editor from "../../components/Editor";

export default function WaterNameAndDescriptionView({
  water,
  visible,
  register,
  setValue,
  control,
  errors,
}: {
  water: WaterDTO | undefined;
  visible: boolean;
  register: UseFormRegister<WaterDTO>;
  setValue?: UseFormSetValue<WaterDTO>;
  control?: Control<WaterDTO, any>;
  errors: FieldErrors<WaterDTO>;
}) {
  useEffect(() => {
    if (water && setValue) {
      setValue("name", water.name, { shouldValidate: true });
      setValue("description", water.description);
    }
  }, [water, setValue]);

  return (
    <>
      {visible ? (
        <>
          <Form.Group>
            <Form.Label className="required">Gewässername</Form.Label>
            <input
              className={"form-control"}
              {...register("name", { required: true, maxLength: 255 })}
              aria-invalid={errors.name ? "true" : "false"}
            />
            {errors.name && <p className={"text-danger"}>Gewässername fehlt</p>}
          </Form.Group>
          <Form.Group>
            <Form.Label>Gewässerbeschreibung</Form.Label>
            <input type="hidden" {...register("hidden_id", {})} value={1} />
            <Editor
              name="description"
              defaultValue={water?.description}
              control={control}
              placeholder="Write a Description..."
            />
          </Form.Group>
        </>
      ) : (
        ""
      )}
    </>
  );
}
