import { useParams } from "react-router";
import React, { ChangeEvent, useEffect, useId, useState } from "react";
import { Button, Card, Col, Form, InputGroup, Row } from "react-bootstrap";
import { PasswordDTO } from "../dtos/PasswordDTO";
import { NetworkCalls } from "../NetworkCalls";
import { Link, useNavigate } from "react-router-dom";

export default function ActivateEmailVerification() {
  const params = useParams();

  let navigate = useNavigate();

  const [token, setToken] = useState<string | undefined>();
  const [loading, isLoading] = useState<boolean>(false);
  const [password, setPassword] = useState<PasswordDTO>({
    oldPassword: "",
    newPassword: "",
  });
  const [passwordChanged, setPasswordChanged] = useState<boolean>(false);

  const formLabelCols = 3;
  const formInputCols = 12 - formLabelCols;

  const helpId = useId();

  useEffect(() => {
    setToken(params.activationToken as string);
  }, [params.activationToken]);

  useEffect(() => {
    if (token) {
      NetworkCalls.tokenValid(token)
        .then()
        .catch((error) => {
          if (error.response.status === 400) {
            return navigate("/login");
          }
        });
    }
  }, [navigate, token]);

  const activationSuccessful = () => {
    return (
      <div className="page page-center">
        <div className="container-tight py-4">
          <div className="empty">
            <div className="empty-header">E-Mail erfolgreich aktiviert</div>
            <p className="empty-title">Du kannst dich jetzt einloggen</p>
            <p className="empty-subtitle text-muted">
              Dein Account wurde aktiviert und du hast dein Passwort hinterlegt.
              Ab jetzt kannst du dich in Fangkarte einloggen.
            </p>
            <p>
              <Link to='login' className='btn btn-primary'>Zum Login</Link>
            </p>
          </div>
        </div>
      </div>
    );
  };

  const onOldPasswordFieldChange = (event: ChangeEvent<HTMLInputElement>) =>
    setPassword((prevState) => {
      return { ...prevState, oldPassword: event.target.value };
    });

  const onNewPasswordFieldChange = (event: ChangeEvent<HTMLInputElement>) =>
    setPassword((prevState) => {
      return { ...prevState, newPassword: event.target.value };
    });

  const showContent = () => {
    return (
      <>
        <div className="page page-center">
          <div className="container py-4">
            <div className="m-5 col row justify-content-center">
              <Card>
                <Card.Header>
                  <h1>Passwort setzen</h1>
                </Card.Header>
                <Card.Body>
                  <Row>
                    <Col md={12}>
                      <Form>
                        <Form.Group as={Row} className="mb-3">
                          <Form.Label
                            column
                            lg={formLabelCols}
                            className="required"
                          >
                            Passwort
                          </Form.Label>
                          <Col lg={formInputCols}>
                            <InputGroup>
                              <Form.Control
                                type="password"
                                placeholder="Neues Passwort"
                                required
                                value={password.oldPassword || ""}
                                onChange={onOldPasswordFieldChange}
                              />
                            </InputGroup>
                          </Col>
                        </Form.Group>
                        <Form.Group as={Row} className="mb-3">
                          <Form.Label
                            column
                            lg={formLabelCols}
                            className="required"
                          >
                            Passwort wiederholen
                          </Form.Label>
                          <Col lg={formInputCols}>
                            <Form.Control
                              type="password"
                              placeholder="Neues Passwort wiederholen"
                              required
                              value={password.newPassword || ""}
                              onChange={onNewPasswordFieldChange}
                              aria-describedby={helpId}
                            />
                            <Form.Text muted id={helpId}>
                              Das Passwort muss mindestens 8 Zeichen lang sein
                            </Form.Text>
                          </Col>
                        </Form.Group>
                      </Form>
                    </Col>
                  </Row>
                </Card.Body>
                {loading ? renderCardFooterPleaseWait() : renderCardFooter()}
              </Card>
            </div>
          </div>
        </div>
      </>
    );
  };

  function savePassword() {
    isLoading(true);
    const fetchData = async () => {
      await NetworkCalls.activateUser(token!, password);
      setPasswordChanged(true);
      isLoading(false);
    };
    fetchData().then();
  }

  const renderCardFooterPleaseWait = () => {
    return (
      <Card.Footer>
        <Button disabled={true}>Speichern</Button>
      </Card.Footer>
    );
  };

  const renderCardFooter = () => {
    return (
      <Card.Footer>
        <Button
          disabled={
            password.oldPassword !== password.newPassword ||
            !password.oldPassword ||
            !password.newPassword ||
            password.newPassword.length < 8
          }
          onClick={savePassword}
        >
          Speichern
        </Button>
      </Card.Footer>
    );
  };

  return <>{passwordChanged ? activationSuccessful() : showContent()}</>;
}
