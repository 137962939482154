import React, { useEffect, useState } from "react";
import { NetworkCalls } from "../../NetworkCalls";
import LoadingPageIndicator from "../../components/LoadingPageIndicator";
import { Inspection } from "../../dtos/Inspection";
import uuid from "react-uuid";
import TimestampFormat from "../../utils/TimestampFormat";
import axios from "axios";
import { NetworkErrorHandling } from "../../components/NetworkErrorHandling";
import { getData } from "../../utils/storage/Storage";
import { StorageKey } from "../../utils/storage/Keys";
import { OrgaAndRoles } from "../../models/OrgaAndRoles";
import { Link } from "react-router-dom";

export default function InspectionAreaOverview() {
  const [loading, isLoading] = useState<boolean>(false);
  const [inspectionCalendarYears, setInspectionCalendarYears] = useState<
    number[]
  >([]);
  const [inspections, setInspections] = useState<Inspection[]>([]);
  const [selectedYear, setSelectedYear] = useState<number>(-1);
  const [excelDownloadURL, setExcelDownloadURL] = useState<string>("");

  useEffect(() => {
    isLoading(true);
    NetworkCalls.inspectionReportedYears()
      .then((result) => {
        const inspectionCalendarYears = result.sort(sortByYear);
        setInspectionCalendarYears(inspectionCalendarYears);
        setSelectedYear(inspectionCalendarYears[0]);
      })
      .catch((error) => {
        isLoading(false);
        NetworkErrorHandling.handle(error);
      });
  }, []);

  useEffect(() => {
    setInspections([]);
    isLoading(true);

    let selectedOrganisation: OrgaAndRoles = JSON.parse(
      getData(StorageKey.SELECTED_ORGANISATION) as string
    );
    setExcelDownloadURL(
      `${axios.defaults.baseURL}/inspection/inspection-list-excel?organisationId=${selectedOrganisation.id}&year=${selectedYear}`
    );
    NetworkCalls.listInspections(selectedYear)
      .then((res) => {
        setInspections(res.sort(sortByInspectionTime));
        isLoading(false);
      })
      .catch((error) => {
        isLoading(false);
        NetworkErrorHandling.handle(error);
      });
  }, [selectedYear]);

  function sortByYear(a: number, b: number) {
    return b - a;
  }

  function sortByInspectionTime(a: Inspection, b: Inspection) {
    return b.inspectionTime - a.inspectionTime;
  }

  const options = inspectionCalendarYears.map((item) => {
    return (
      <option key={item} value={item}>
        {item}
      </option>
    );
  });

  function changeYear(selectedYear: string) {
    setSelectedYear(Number(selectedYear));
  }

  function showContent() {
    return (
      <div className="m-5 col row justify-content-center">
        <div className="card border-0">
          <div className="card-header">
            <div className="row">
              <div className="col-sm-7">
                <h4>Excel Download</h4>
                <a
                  href={excelDownloadURL}
                  className="link-secondary"
                  title="Passwort anzeigen"
                  data-bs-placement="top"
                  data-bs-toggle="tooltip"
                  data-event-click="/js/password-reveal.js"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="icon icon-tabler icon-tabler-cloud-download"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    strokeWidth="2"
                    stroke="currentColor"
                    fill="none"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  >
                    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                    <path d="M19 18a3.5 3.5 0 0 0 0 -7h-1a5 4.5 0 0 0 -11 -2a4.6 4.4 0 0 0 -2.1 8.4"></path>
                    <path d="M12 13l0 9"></path>
                    <path d="M9 19l3 3l3 -3"></path>
                  </svg>
                </a>
              </div>
              <div className="col-sm-5">
                <div className="form-inline">
                  <select
                    id="yearControl"
                    title="Jahr"
                    className="form-control"
                    onChange={(elem) => changeYear(elem.target.value)}
                    defaultValue={selectedYear}
                  >
                    {options}
                  </select>
                </div>
              </div>
            </div>
          </div>
          {showInspectionTable()}
        </div>
      </div>
    );
  }

  function showInspectionTable() {
    return (
      <table className="table">
        <thead>
          <tr key={uuid()}>
            <th scope="col"></th>
            <th scope="col">Aufseher</th>
            <th scope="col">Datum</th>
            <th scope="col">Gewässer</th>
            <th scope="col">Mitgliedsnummer</th>
            <th scope="col">Bemerkung</th>
            <th scope="col"></th>
          </tr>
        </thead>
        <tbody id="table-content">{createRows()}</tbody>
      </table>
    );
  }

  function createRows() {
    return inspections.map((elem) => {
      return (
        <tr key={uuid()}>
          <td>{showAttachment(elem)}</td>
          <td>
            {" "}
            {elem.firstName} {elem.lastName}
          </td>
          <td>
            {TimestampFormat.toDateTimeReadableFormat(elem.inspectionTime)}
          </td>
          <td>{elem.water.waterName}</td>
          <td>{elem.userNumber}</td>
          <td>{elem.description}</td>
          <td>
            <Link
              to={`/dashboard/inspections/details/${elem.id}`}
              className="btn btn-primary"
            >
              Details anzeigen
            </Link>
          </td>
        </tr>
      );
    });
  }

  function showAttachment(inspection: Inspection) {
    if (inspection.hasPhoto) {
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="icon icon-tabler icon-tabler-files"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          strokeWidth="2"
          stroke="currentColor"
          fill="none"
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
          <path d="M15 3v4a1 1 0 0 0 1 1h4"></path>
          <path d="M18 17h-7a2 2 0 0 1 -2 -2v-10a2 2 0 0 1 2 -2h4l5 5v7a2 2 0 0 1 -2 2z"></path>
          <path d="M16 17v2a2 2 0 0 1 -2 2h-7a2 2 0 0 1 -2 -2v-10a2 2 0 0 1 2 -2h2"></path>
        </svg>
      );
    } else {
      return;
    }
  }

  return <div>{loading ? <LoadingPageIndicator /> : showContent()}</div>;
}
