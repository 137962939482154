import DocumentList from "./DocumentList";
import DocumentUpload from "./DocumentUpload";
import {useState} from "react";
import DocumentDTO from "../../../dtos/DocumentDTO";

export default function DocumentManagement() {
    const [documents, setDocuments] = useState<DocumentDTO[]>([]);
    const showContent = () => {
        return (
            <div className="page-body">
                <div className="container-xl">
                    <div className="card">
                        <div className="card-header">
                            <ul className="nav nav-pills card-header-pills">
                                <li className="nav-item">
                                    <h3>Dokumentenverwaltung</h3>
                                </li>
                                <li className="nav-item ms-auto">
                                    <DocumentUpload onDocumentsUpdate={setDocuments}/>
                                </li>
                            </ul>
                        </div>
                        <div className="card-body">
                            <DocumentList docs={documents}/>
                        </div>
                    </div>
                </div>
            </div>
        );
    };
    return showContent();
}
