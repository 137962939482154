import { Navigate } from "react-router-dom";
import { AxiosError } from "axios";

export class NetworkErrorHandling {
  static handle = (error: AxiosError) => {
    switch (error.response?.status) {
      case 400:
      case 401:
        return <Navigate to="/login" replace={true} />;
      case 500:
        return <Navigate to="/ups" replace={true} />;
    }
  };
}

export default function HandleHttpResponses(error: AxiosError) {
  switch (error.response?.status) {
    case 400:
      return <Navigate to="/lost" replace={true} />;
    case 401:
      return <Navigate to="/login" replace={true} />;
    case 500:
      return <Navigate to="/ups" replace={true} />;
  }
}
