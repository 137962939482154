import {useParams} from "react-router";
import {Link, useLoaderData, useNavigate} from "react-router-dom";
import {Organisation} from "../dtos/Organisation";
import React, {FormEvent, useCallback, useEffect, useState} from "react";
import {UserCatchReport} from "../dtos/UserCatchReport";
import {NetworkCalls} from "../NetworkCalls";
import {Alert, Card, Col, Row} from "react-bootstrap";
import CatchesTable from "./CatchesTable";
import NewCatchInputForm from "./NewCatchInputForm";
import {UserCatchReportAddFormData} from "../dtos/UserCatchReportAddFormData";

export default function CatchReportGuestCardPage() {

    const { guestCardToken } = useParams();
    const organisation = useLoaderData() as Organisation;
    const [catches, setCatches] = useState<UserCatchReport[]>([]);
    const [validated, setValidated] = useState<boolean>(false);

    const navigate = useNavigate();

    const formLabelCols = 3;
    const formInputCols = 12 - formLabelCols;

    const updateCatches = useCallback(() => {
        if (!guestCardToken)
            return Promise.resolve();
        return NetworkCalls.getGuestCardCatchReports({
            organisationToken: organisation.organisationToken,
            guestCardToken: guestCardToken,
        }).then(result => {
            result.sort((a,b) => b.catchTime - a.catchTime);
            setCatches(result);
        });
    }, [ guestCardToken, organisation.organisationToken ]);

    useEffect(() => {
        updateCatches().catch(error => {
            console.error(error);
            navigate(`/catch-report/${organisation.organisationToken}`);
        });
    }, [ updateCatches, organisation.organisationToken, navigate ]);


    const renderH1 = () => {
        return <>
            Fangrückmeldung - {organisation.organisationName} -
            Gastkarte: { guestCardToken }
        </>
    }

    const renderCardFooter = () => {
        return <Card.Footer>
            <Link to={`/catch-report/${organisation.organisationToken}`} className='btn btn-secondary' >Fertig</Link>
        </Card.Footer>;
    }

    const onSubmit = (event: FormEvent<HTMLFormElement>, formData: UserCatchReportAddFormData): Promise<void> => {
        const form = event.currentTarget;

        event.stopPropagation();
        event.preventDefault();

        let checkValidity = form.checkValidity();
        setValidated(true);

        if (!checkValidity) {
            return Promise.reject('Ungültige Eingabe');
        }

        formData.guestCardTransaction = guestCardToken;

        return NetworkCalls.addUserCatchResult(formData)
            .then(async _ => {
                setValidated(false);
                await updateCatches();
            });
    }

    const showContent = () => {
        return <>
            <div className="page page-center">
                <div className="container py-4">
                    <div className="m-5 col row justify-content-center">
                        <Card>
                            <Card.Header>
                                <h1>{renderH1()}</h1>
                            </Card.Header>
                            <Card.Body>
                                <Row>
                                    <Col md={6}>
                                        <CatchesTable catches={catches}/>
                                    </Col>
                                    <hr className='d-md-none'/>
                                    <Col md={6}>
                                        <h2>Neuen Fang eintragen</h2>
                                        <NewCatchInputForm onSubmit={onSubmit} validated={validated} formLabelCols={formLabelCols} formInputCols={formInputCols} organisation={organisation} />
                                    </Col>
                                </Row>
                            </Card.Body>
                            {renderCardFooter()}
                        </Card>
                    </div>
                </div>
            </div>
        </>;
    }

    if (!guestCardToken) {
        return <>
            <Alert variant='danger'>Keine Gastkarte angegeben</Alert>
            <Link to={`/catch-report/${organisation.organisationToken}`} className='btn btn-secondary'>Zurück</Link>
        </>
    }

    return <>
        {showContent()}
    </>;
}
