import {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {getData, storeData} from "../utils/storage/Storage";
import {StorageKey} from "../utils/storage/Keys";
import {OrgaAndRoles} from "../models/OrgaAndRoles";

export default function SelectOrganisation() {
    const [orgaAndRoles, setOrgaAndRoles] = useState<OrgaAndRoles[]>([]);
    const navigate = useNavigate();

    useEffect(() => {
        let orgaAndRoles = getData(StorageKey.ORGA_AND_ROLES);
        if (orgaAndRoles) {
            setOrgaAndRoles(JSON.parse(orgaAndRoles));
        }
    }, []);

    function selectOrganisation() {
        let itemList = document.getElementById("organisation");
        // @ts-ignore
        let collection = itemList.selectedOptions;

        if (collection.length === 1) {
            // @ts-ignore
            const selectedOrganisation = orgaAndRoles.filter(elem => elem.id === collection[0].value);
            if (selectedOrganisation.length === 1) {
                storeData(StorageKey.SELECTED_ORGANISATION, JSON.stringify(selectedOrganisation[0]));
                navigate("/dashboard");
            } else {
                // TODO show error
            }
        }
    }

    const options = orgaAndRoles.map((item) => {
        return (
            <option key={item.id} value={item.id}>
                {item.organisationName}
            </option>
        )
    })

    return (<div className="Auth-form-container App">
        <div className="Auth-form">
            <div className="Auth-form-content">
                <h3 className="Auth-form-title text-color-sidebar-primary">Verein auswählen</h3>
                <div className="form-group mt-3">
                    <select className="form-select" id="organisation">
                        {orgaAndRoles.length > 0 ? options : ''}
                    </select>
                </div>
                <div className="d-block d-grid gap-2 mt-3">
                    <button type="submit" className="btn btn-primary fk-color" onClick={selectOrganisation}>
                        OK
                    </button>
                </div>
            </div>
        </div>
    </div>);
}
