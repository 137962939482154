//getReportedCatchYears

import React, { useEffect, useState } from "react";
import EmptyPage from "../../components/EmptyPage";
import { CatchYear } from "../../dtos/CatchYear";
import { NetworkCalls } from "../../NetworkCalls";
import { ReportedCatchYearDoneDTO } from "../../dtos/ReportedCatchYearDoneDTO";
import FangkarteList from "../../components/FangkarteList";
import TimestampFormat from "../../utils/TimestampFormat";
import { FangkarteListItem } from "../../models/FangkarteListItem";
import axios from "axios";
import { OrgaAndRoles } from "../../models/OrgaAndRoles";
import { getData } from "../../utils/storage/Storage";
import { StorageKey } from "../../utils/storage/Keys";

export default function ReportedCatchYears({
  reportedCatchYear,
}: {
  reportedCatchYear: CatchYear | undefined;
}) {
  const [reportedCatchYears, setReportedCatchYears] = useState<
    ReportedCatchYearDoneDTO[]
  >([]);

  useEffect(() => {
    if (reportedCatchYear !== undefined) {
      const fetchData = async () => {
        setReportedCatchYears(
          await NetworkCalls.getReportedCatchYears(reportedCatchYear)
        );
      };

      fetchData().catch(console.error);
    }
  }, [reportedCatchYear]);

  const createListItems = () => {
    return reportedCatchYears.map((report) => {
      const date = TimestampFormat.toDateOnlyReadableFormat(report.yearClosed);
      const title = `${report.firstName} ${report.lastName} (${report.number})`;
      return {
        title: title,
        subTitle: date,
        originalElement: report,
      } as FangkarteListItem;
    });
  };

  const reportSelected = (report: ReportedCatchYearDoneDTO) => {};

  const getContent = () => {
    let organisation: OrgaAndRoles = JSON.parse(
      getData(StorageKey.SELECTED_ORGANISATION) as string
    );

    return (
      <FangkarteList
        listItems={createListItems()}
        onItemClicked={reportSelected}
        title="Einträge"
        customDownloadLink={`${axios.defaults.baseURL}/report-catch-year/list/${organisation.id}/${reportedCatchYear?.id}/isl-export-csv`}
      />
    );
  };

  return (
    <>
      {reportedCatchYear === undefined || reportedCatchYears.length === 0 ? (
        <EmptyPage />
      ) : (
        getContent()
      )}
    </>
  );
}
