import {useEffect, useRef, useState} from "react";
import {useParams} from "react-router";
import {Link, useNavigate} from "react-router-dom";
import {NetworkCalls} from "../../NetworkCalls";
import {useSelectedOrganisation} from "../../custom-hooks/useSelectedOrganisation";
import {Alert, Button, Card, Col} from "react-bootstrap";
import AddEditGuestCardDialog from "./AddEditGuestCardDialog";
import {AxiosError} from "axios";
import {GuestCardDTO} from "../../dtos/GuestCardDTO";
import {WaterDTO} from "../../dtos/WaterDTO";
import LoadingPageIndicator from "../../components/LoadingPageIndicator";

export default function EditGuestCard() {

    const params = useParams();
    const [loading, isLoading] = useState<boolean>(false);

    const [cardId, setCardId] = useState<string | undefined>();
    const [card, setCard] = useState<GuestCardDTO | undefined>();
    const [waters, setWaters] = useState<WaterDTO[]>([]);
    const selectedOrganisation = useSelectedOrganisation();
    const navigate = useNavigate();

    const [error, setError] = useState<AxiosError | undefined>(undefined);

    const submitRef = useRef();

    useEffect(() => {
        setCardId(params.cardId ?? undefined);
    }, [params.cardId]);

    useEffect(() => {
        if (!selectedOrganisation || !cardId) {
            return;
        }

        isLoading(true);
        const p1 = NetworkCalls.getGuestCardById(cardId)
            .then((result) => {
                setCard(result);
            });

        const p2 = NetworkCalls.listWater()
            .then(result => {
                result = result.sort((a, b) => a.name.localeCompare(b.name))
                setWaters(result);
            });

        Promise.allSettled([p1, p2])
            .catch(err => {
                setError(err);
                console.error(err);
            })
            .finally(() => isLoading(false));

    }, [selectedOrganisation, cardId]);

    const afterSubmit = (card: GuestCardDTO) => {
        setError(undefined);
        NetworkCalls.updateGuestCard(card)
            .then(
                () => navigate("../..", {relative: 'path'})
            ).catch(e => {
            console.error(e);
            setError(e);
        });
    }

    const submitForm = () => {
        // @ts-ignore
        submitRef.current.click();
    }

    const renderError = () => {
        if (!error) {
            return <></>;
        }

        return <>
            <Alert variant='danger'>{error.message}</Alert>
        </>;
    }

    const renderCard = () => {
        if (!card) {
            return <>
                <p>Nichts ausgewählt</p>
            </>;
        }

        return <>
            {renderError()}
            <AddEditGuestCardDialog card={card} afterSubmitAction={afterSubmit} waters={waters} submitRef={submitRef} />
        </>;
    }

    const render = () => {
        if (loading || !cardId) {
            return <LoadingPageIndicator />
        }

        return renderCard();
    }

    return <>
        <Card className='m-5 justify-content-center border-0' as={Col}>
            <Card.Header><h1>Gastkarte bearbeiten</h1></Card.Header>
            <Card.Body className='border-bottom'>
                {render()}
            </Card.Body>
            <Card.Footer className='space-x'>
                <Button onClick={submitForm}>Speichern</Button>
                <Link to={'/dashboard/administration/guest-cards'} className='btn btn-secondary'>Abbrechen</Link>
            </Card.Footer>
        </Card>
    </>;
}
