import {Col, Form, InputGroup, Row} from "react-bootstrap";
import React, {FormEvent, useEffect, useLayoutEffect, useState} from "react";
import {GuestCardDTO} from "../../dtos/GuestCardDTO";
import SunEditor from "suneditor-react";
import {WaterDTO} from "../../dtos/WaterDTO";
import 'suneditor/dist/css/suneditor.min.css';
import TomSelect from "tom-select";

const onlyUnique = (value: any, index: number, array: any[]) => array.indexOf(value) === index;

export default function AddEditGuestCardDialog({card, afterSubmitAction, waters, submitRef}: {
    card: GuestCardDTO,
    afterSubmitAction: (card: GuestCardDTO) => void,
    waters: WaterDTO[],
        submitRef: any,
}) {

    const [description, setDescription] = useState<string>('');
    const [duration, setDuration] = useState<number>(1);
    const [price, setPrice] = useState<number>(30);
    const [sendWaterRegulation, setSendWaterRegulation] = useState<boolean>(false);
    const [validated, setValidated] = useState<boolean>(false);
    const [active, setActive] = useState<boolean>(false);
    const [termsAndConditions, setTermsAndConditions] = useState<string>('');
    const [waterMappings, setWaterMappings] = useState<string[]>([]);
    const [init, setInit] = useState<boolean>(false);

    const waterSelectId = 'water-select';

    useLayoutEffect(() => {
        const selector = `#${waterSelectId}`;
        const watersSelect = document.querySelector(selector);

        if (!card || !waterMappings || !init) {
            return;
        }

        // @ts-ignore
        if (watersSelect && !watersSelect.tomselect) {
            new TomSelect(selector, {
                plugins: {
                    remove_button: {
                        title: 'Entfernen'
                    }
                },
                items: waterMappings,
                onItemRemove: (e: string) => {
                    setWaterMappings(m => m.filter(v => v !== e));
                },
                onItemAdd: (e: string) => {
                    setWaterMappings(m => {
                        if (!m.includes(e))
                            m.push(e);
                        return m;
                    });
                }
            });
        }
    }, [ card, waters, waterSelectId, waterMappings, init ]);

    useEffect(() => {
        setDescription(card.description);
        setDuration(card.duration);
        setPrice(card.price);
        setSendWaterRegulation(card.sendWaterRegulation);
        setActive(card.active);
        setTermsAndConditions(card.termsAndConditions);
        setWaterMappings(card.waterMappings.filter(onlyUnique));
        setInit(true);
    }, [card]);

    const onSubmitHandler = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        e.stopPropagation();

        const form = e.currentTarget;
        let isValid = form.checkValidity();

        setValidated(true);

        if (isValid) {
            card.description = description;
            card.active = active;
            card.price = price;
            card.duration = duration;
            card.sendWaterRegulation = sendWaterRegulation;
            card.termsAndConditions = termsAndConditions;
            card.waterMappings = waterMappings;
            afterSubmitAction(card);
        }
    };

    const renderWaterOption = (water: WaterDTO) => {
        return <option value={water.id}>{water.name}</option>
    }

    if (!card) {
        return <></>;
    }

    return <>
        <Form noValidate validated={validated} onSubmit={onSubmitHandler} >
            <Row sm={1} md={3}>
                <Form.Group as={Col} className="mb-3" controlId="guestCardForm.name">
                    <Form.Label>Name</Form.Label>
                    <InputGroup hasValidation>
                        <Form.Control type="text" placeholder="Beispiel: Tageskarte Kanal" value={description}
                                      onChange={e => setDescription(e.target.value)} required/>
                        <Form.Control.Feedback type='invalid'>Sie müssen einen Namen angeben.</Form.Control.Feedback>
                    </InputGroup>
                </Form.Group>
                <Form.Group as={Col} controlId="guestCardForm.duration">
                    <Form.Label>Dauer der Gültigkeit (in Tagen)</Form.Label>
                    <InputGroup className="mb-3">
                        <Form.Control
                            type="number"
                            placeholder="5"
                            min={1}
                            value={duration}
                            required
                            onChange={e => setDuration(Number(e.target.value))}
                            aria-label="Dauer in Tagen"
                            aria-describedby="basic-addon2"
                        />
                        <InputGroup.Text id="basic-addon2">Tage</InputGroup.Text>
                    </InputGroup>
                </Form.Group>
                <Form.Group as={Col} controlId="guestCardForm.price">
                    <Form.Label>Preis</Form.Label>
                    <InputGroup>
                        <Form.Control
                            type="number"
                            placeholder="50"
                            value={price}
                            required
                            onChange={e => setPrice(Number(e.target.value))}
                            aria-label="Preis"
                            aria-describedby="basic-addon3"
                        />
                        <InputGroup.Text id="basic-addon3">€</InputGroup.Text>
                    </InputGroup>
                </Form.Group>
                <Form.Switch checked={sendWaterRegulation} onChange={e => setSendWaterRegulation(e.target.checked)}
                             label='Gewässerordnung als Anhang mit verschicken?'/>
                <Form.Switch checked={active} onChange={e => setActive(e.target.checked)} label='Kann die Gastkarte jetzt gekauft werden?'/>
            </Row>
            <Row sm={1} className='my-3'>
                <Form.Group as={Col} className="mb-3" controlId="guestCardForm.terms">
                    <Form.Label>Beschreibungstext</Form.Label>
                    <InputGroup hasValidation>
                        <SunEditor
                            setContents={termsAndConditions}
                            onChange={setTermsAndConditions}
                            height='200px'
                        />
                        <Form.Control.Feedback type='invalid'>Sie müssen einen Namen angeben.</Form.Control.Feedback>
                    </InputGroup>
                </Form.Group>
            </Row>

            <Form.Group as={Row} className='mb-3'>
                <Form.Label as="legend" column sm={2}>Gewässer</Form.Label>
                <Form.Select id={waterSelectId} multiple>
                    {waters.map(renderWaterOption)}
                </Form.Select>
            </Form.Group>

            <button ref={submitRef} type="submit" style={{ display: 'none' }} />

            {/*<div className='space-x border-top pt-2'>*/}
            {/*    <Button type="submit">Speichern</Button>*/}
            {/*    <Button type="button" variant='secondary' onClick={onAbortAction}>Abbrechen</Button>*/}
            {/*</div>*/}
        </Form>
    </>;
}
