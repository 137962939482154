import {UserCatchReport} from "../dtos/UserCatchReport";
import {Table} from "react-bootstrap";
import TimestampFormat from "../utils/TimestampFormat";
import React from "react";

export default function CatchesTable({catches}: {catches: UserCatchReport[]}) {

    if (catches.length === 0) {
        return <h3>Bisher wurden keine Fänge eingetragen</h3>
    }

    return <>
        <h2>Eingetragene Fänge</h2>
        <Table hover>
            <thead>
            <tr>
                <th>Fisch</th>
                <th>Gewässer</th>
                <th>Länge (cm)</th>
                <th>Fangzeitpunkt</th>
            </tr>
            </thead>
            <tbody>
            {catches.map(c => <tr id={c.id}>
                <td>{c.fishName}</td>
                <td>{c.waterName}</td>
                <td>{c.fishLength}</td>
                <td>{TimestampFormat.toDateOnlyReadableFormat(c.catchTime)}</td>
            </tr>)}
            </tbody>
        </Table>
    </>
}