import { useEffect, useId } from "react";
import { FormControl } from "react-bootstrap";
import { Litepicker } from "litepicker";

export default function DateRangeLitePicker({
  onDateSelected,
  startDate,
  endDate,
  maxDate = undefined,
  minDate = undefined,
  disabled = false,
  singleMode = true,
  numberOfColumns = 1,
  numberOfMonths = 1,
  firstDay = 0,
}: {
  onDateSelected: (date: Date, end: Date) => void;
  startDate?: Date;
  endDate?: Date;
  maxDate?: Date | number | undefined;
  minDate?: Date | number | undefined;
  disabled?: boolean;
  singleMode?: boolean;
  numberOfColumns?: number;
  numberOfMonths?: number;
  firstDay?: number;
}) {
  const id = useId();

  useEffect(() => {
    let lp = new Litepicker({
      autoApply: true,
      element: document.getElementById(id)!,
      format: "DD.MM.YYYY",
      mobileFriendly: true,
      firstDay,
      numberOfColumns,
      singleMode,
      numberOfMonths,
      lang: "de",
      maxDate: maxDate,
      minDate: minDate,
      startDate,
      endDate,
      setup: (picker) => {
        picker.on("selected", (start: Date, end: Date) => {
          onDateSelected(start, end);
        });
      },
    });

    return () => {
      lp.destroy();
    };
  }, [
    id,
    onDateSelected,
    maxDate,
    minDate,
    startDate,
    endDate,
    firstDay,
    numberOfColumns,
    singleMode,
    numberOfMonths,
  ]);

  return <FormControl disabled={disabled} readOnly={true} id={id} />;
}
