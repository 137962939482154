import { useState } from "react";
import Papa from "papaparse";
import { MemberDTO } from "../../dtos/MemberDTO";
import uuid from "react-uuid";
import { NetworkCalls } from "../../NetworkCalls";
import LoadingPageIndicator from "../../components/LoadingPageIndicator";
import HandleHttpResponses from "../../components/NetworkErrorHandling";
import { AxiosError } from "axios";

export default function UploadMemberList() {
  const [loading, isLoading] = useState<boolean>(false);
  const [uploadSuccessful, setUploadSuccessful] = useState<boolean>(false);
  const [error, setError] = useState<AxiosError>();
  const [data, setData] = useState<MemberDTO[]>([]);
  const [preview, setPreview] = useState<MemberDTO[]>([]);

  function convertToDTO(row: any[]) {
    return {
      number: row[0],
      lastname: row[1],
      firstname: row[2],
      street: row[3],
      postalCode: row[4],
      city: row[5],
      memberWork: row[6] === "Ja",
    } as MemberDTO;
  }

  function onChangeFile(file: FileList) {
    const reader = new FileReader();

    reader.onload = async ({ target }) => {
      if (target && target.result) {
        // @ts-ignore
        const csv = Papa.parse(target.result, { header: false });
        // @ts-ignore
        const parsedData = csv?.data;

        if (parsedData) {
          let amountOfLines = parsedData?.length;
          if (parsedData?.length >= 10) {
            amountOfLines = 10;
          }

          let dataToShow: MemberDTO[] = [];
          let dataToUpload: MemberDTO[] = [];
          for (let i = 0; i < parsedData?.length; i++) {
            if (i < amountOfLines) {
              dataToShow.push(convertToDTO(parsedData[i]));
            }
            dataToUpload.push(convertToDTO(parsedData[i]));
          }
          setPreview(dataToShow);
          setData(dataToUpload);
        }
      }
    };

    reader.readAsText(file[0]);
  }

  function upload() {
    isLoading(true);
    NetworkCalls.updateMemberList(data)
      .then((result) => {
        isLoading(false);
        setUploadSuccessful(true);
        setPreview([]);
        setData([]);
      })
      .catch((error) => {
        isLoading(false);
        setError(error);
      });
  }

  function showUploadBtn() {
    return (
      <div className="mt-3">
        <button className="btn btn-primary" rel="noopener" onClick={upload}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="icon icon-tabler icon-tabler-cloud-upload"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            strokeWidth="2"
            stroke="currentColor"
            fill="none"
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
            <path d="M7 18a4.6 4.4 0 0 1 0 -9a5 4.5 0 0 1 11 2h1a3.5 3.5 0 0 1 0 7h-1"></path>
            <path d="M9 15l3 -3l3 3"></path>
            <path d="M12 12l0 9"></path>
          </svg>
          Mitgliederliste hochladen
        </button>
      </div>
    );
  }

  function showPreview() {
    return (
      <div className="row row-cards">
        <div className="col-md-12">
          <div className="card">
            <div className="card-body">
              <h3 className="card-title">Vorschau der CSV Datei</h3>
              <div className="alert alert-primary alert-dismissible fade show">
                Die Vorschau dient der Kontrolle, dass die Daten in der CSV
                korrekt gepflegt sind. Es werden maximal 10 Einträge als
                Vorschau angezeigt. Führende 0 bei der Mitgliedsnummer werden
                beim Hochladen entfernt.
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="alert"
                ></button>
              </div>
              {showTable()}
            </div>
          </div>
        </div>
      </div>
    );
  }

  function showUploadSuccessful() {
    return (
      <div className="alert alert-primary alert-dismissible fade show">
        Die CSV Datei wurde erfolgreich eingespielt
        <button
          type="button"
          className="btn-close"
          data-bs-dismiss="alert"
        ></button>
      </div>
    );
  }

  function showTable() {
    return (
      <table className="table table-hover">
        <thead>
          <tr key={uuid()}>
            <th scope="col">Mitgliedsnummer</th>
            <th scope="col">Vorname</th>
            <th scope="col">Nachname</th>
            <th scope="col">Straße</th>
            <th scope="col">PLZ</th>
            <th scope="col">Ort</th>
            <th scope="col">Arbeitsdienstpflichtig</th>
          </tr>
        </thead>
        <tbody id="table-content">{createRows()}</tbody>
      </table>
    );
  }

  function createRows() {
    return preview.map((elem) => {
      return (
        <tr key={uuid()} className="user-select-auto">
          <td>{elem.number}</td>
          <td> {elem.firstname}</td>
          <td>{elem.lastname}</td>
          <td>{elem.street}</td>
          <td>{elem.postalCode}</td>
          <td>{elem.city}</td>
          <td>{elem.memberWork ? "Ja" : "Nein"}</td>
        </tr>
      );
    });
  }

  function showContent() {
    // @ts-ignore
    return (
      <div className="page-body">
        {uploadSuccessful ? showUploadSuccessful() : ""}
        <div className="container-xl">
          <div className="row row-cards">
            <div className="col-md-12">
              <div className="card">
                <div className="card-body">
                  <h3 className="card-title">Mitgliederliste hochladen</h3>
                  <div className="mb-3">
                    <label htmlFor="formFile" className="form-label">
                      Bitte Mitgliederliste hochladen
                    </label>
                    <input
                      className="form-control"
                      type="file"
                      id="formFile"
                      onChange={(e) => {
                        // @ts-ignore
                        onChangeFile(e.target.files);
                      }}
                    />
                  </div>
                  {data.length > 0 ? showUploadBtn() : ""}
                </div>
              </div>
            </div>
          </div>
          {data.length > 0 ? showPreview() : ""}
        </div>
      </div>
    );
  }

  return (
    <div>
      {loading ? (
        <LoadingPageIndicator />
      ) : error ? (
        HandleHttpResponses(error)
      ) : (
        showContent()
      )}
    </div>
  );
}
