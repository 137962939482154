import {Organisation} from "../dtos/Organisation";
import {Button, Col, Form, Row, Spinner} from "react-bootstrap";
import {ChangeEvent, useEffect, useId, useState} from "react";
import {NetworkCalls} from "../NetworkCalls";
import {useNavigate} from "react-router-dom";
import {getData, storeData} from "../utils/storage/Storage";
import {StorageKey} from "../utils/storage/Keys";
import {CatchReportUser} from "../dtos/CatchReportUser";

export default function CatchReportMemberInputForm({organisation}: {
    organisation: Organisation,
}) {

    const [memberNumber, setMemberNumber] = useState<string>('');
    const [lastName, setLastName] = useState<string>('');
    const [saveInputs, setSaveInputs] = useState<boolean>(true);

    const [validated, setValidated] = useState<boolean>(false);

    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<string>('');

    const navigate = useNavigate();

    const helpId = useId();

    useEffect(() => {
        const dataStringUser = getData(StorageKey.CATCH_REPORT_USER) || '{}';
        const users = JSON.parse(dataStringUser);
        const user = users[organisation.organisationToken] as CatchReportUser;
        if (user?.saveInputs) {
            setMemberNumber(user.memberNumber);
            setLastName(user.lastName);
        }
    }, [organisation.organisationToken]);

    const handleSubmit = (event: ChangeEvent<HTMLFormElement>) => { // ChangeEvent
        const form = event.currentTarget;

        event.preventDefault();
        event.stopPropagation();

        let checkValidity = form.checkValidity();

        setValidated(true);

        if (checkValidity) {
            setLoading(true);
            setError('');
            let trimmedNumber = memberNumber.trim();
            let trimmedLastName = lastName.trim();
            NetworkCalls.getUserCatchReports({
                organisationToken: organisation.organisationToken,
                memberNumber: trimmedNumber,
                lastName: trimmedLastName
            })
                .then(_ => {
                    const users = JSON.parse(getData(StorageKey.CATCH_REPORT_USER) || '{}');
                    users[organisation.organisationToken] = {memberNumber: trimmedNumber, lastName: trimmedLastName, saveInputs};
                    storeData(StorageKey.CATCH_REPORT_USER, JSON.stringify(users));
                    navigate("./member", {relative: 'path'});
                })
                .catch(error => {
                    console.error(error);
                    if (error.response.status === 404) {
                        setError('Die Kombination aus Verein, Mitgliedsnummer und Nachname wurde nicht gefunden.');
                    }
                })
                .finally(() => {
                    setLoading(false);
                });
        }
    };

    return <>
        <Form noValidate validated={validated} onSubmit={handleSubmit}>
            <Form.Group as={Row} className='mb-3' controlId='member-number'>
                <Form.Label column lg={2} className='required'>Ihre Mitgliedsnummer</Form.Label>
                <Col lg={10}>
                    <Form.Control type='text' placeholder='Mitgliedsnummer' required size='lg'
                                  value={memberNumber} onChange={e => setMemberNumber(e.target.value)} autoFocus={true}
                                  aria-describedby={helpId}/>
                    <Form.Control.Feedback type='invalid'>Bitte Ihre Mitgliedsnummer eingeben.</Form.Control.Feedback>
                    <Form.Text muted id={helpId}>Ihre Mitgliedsnummer im {organisation.organisationName}</Form.Text>
                </Col>
            </Form.Group>
            <Form.Group as={Row} className='mb-3' controlId='member-last-name'>
                <Form.Label column lg={2} className='required'>Ihr Nachname</Form.Label>
                <Col lg={10}>
                    <Form.Control type='text' placeholder='Nachname' required size='lg' value={lastName}
                                  onChange={e => setLastName(e.target.value)}/>
                    <Form.Control.Feedback type='invalid'>Bitte Ihren Nachnamen eingeben.</Form.Control.Feedback>
                </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-3" controlId="member-save-inputs">
                <Col lg={{span: 10, offset: 2}}>
                    <Form.Check label="Eingaben für nächsten Besuch speichern?" defaultChecked={saveInputs}
                                onChange={e => setSaveInputs(e.target.checked)}/>
                </Col>
            </Form.Group>
            <Form.Group as={Row}>
                <Col lg={{span: 10, offset: 2}} className='d-grid gap-2'>
                    <Button variant='primary' type='submit' disabled={loading || !lastName || !memberNumber}>
                        Weiter
                        <Spinner animation="border" role="status" className={'ms-2' + (loading ? '' : ' d-none')}>
                            <span className="visually-hidden">Lade...</span>
                        </Spinner>
                    </Button>
                    <span className='text-red'>
                        {error}
                    </span>
                </Col>
            </Form.Group>
        </Form>
    </>;
}
