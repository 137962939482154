import GeoCoordinatesDTO from "../../../dtos/GeoCoordinatesDTO";

export interface Umrandung {
  visible: boolean;
  key: number;
  shape: Shape;
  coordinates: GeoCoordinatesDTO[];
  shapeType: ShapeType;
  startProtection?: string;
  endProtection?: string;
}

export enum Shape {
  SHAPE_POLYLINE = "SHAPE_POLYLINE",
  SHAPE_POLYGON = "SHAPE_POLYGON",
  PROTECTION_POLYLINE = "PROTECTION_POLYLINE",
  PROTECTION_POLYGON = "PROTECTION_POLYGON",
  UNKNOWN = "UNKNOWN",
}

export enum ShapeType {
  UMRANDUNG = "UMRANDUNG",
  SCHONGEBIET = "SCHONGEBIET"
}