import React, {useEffect, useId, useState} from "react";
import {Button, Form, Modal} from "react-bootstrap";

const AddProtectionTimeToShape = ({
                                      beginn,
                                      ende,
                                      show,
                                      onDateChange,
                                      onClose,
                                  }: {
    beginn?: string;
    ende?: string;
    show: boolean;
    onDateChange: (start?: string, end?: string) => void;
    onClose: () => void;
}) => {
    const modalId = useId();

    const [enabled, setEnabled] = useState<boolean>(false);
    const [start, setStart] = useState<string | undefined>(undefined);
    const [end, setEnd] = useState<string | undefined>(undefined);

    useEffect(() => {
        if (beginn && ende) {
            setStart(beginn);
            setEnd(ende);
            setEnabled(true);
        }
    }, [beginn, ende]);

    const showContent = () => {
        return (
            <Modal show={show} onHide={onClose} id={modalId}>
                <Modal.Header closeButton>
                    <Modal.Title>Partielles Schongebiet</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group>
                            <Form.Label>Aktiv?</Form.Label>
                            <Form.Switch checked={enabled} onChange={(e) => setEnabled(e.target.checked)}></Form.Switch>
                        </Form.Group>
                        <Form.Group>
                            <Form.Label className="required">Von</Form.Label>
                            <Form.Control
                                id="start"
                                type="date"
                                value={start}
                                onChange={(e) => setStart(e.target.value)}
                                disabled={!enabled}
                            />
                        </Form.Group>
                        <Form.Group>
                            <Form.Label className="required">Bis</Form.Label>
                            <Form.Control
                                id="end"
                                type="date"
                                value={end}
                                onChange={(e) => setEnd(e.target.value)}
                                disabled={!enabled}
                            />
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="primary"
                        disabled={enabled && (start === undefined || end === undefined || start === "" || end === "" || start > end)}
                        onClick={() => {
                            if (!enabled) {
                                onDateChange(undefined, undefined);
                            } else {
                                onDateChange(start, end);
                            }
                            onClose();
                        }}
                    >
                        OK
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    };

    return showContent();
};

export default AddProtectionTimeToShape;
