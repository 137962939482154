import React from "react";
import ReactDOM from "react-dom/client";
import "./index.scss";
import App from "./App";
import { json, RouterProvider } from "react-router";
import { createBrowserRouter } from "react-router-dom";
import DefaultError from "./routes/default-error";
import Auth from "./Auth";
import SelectOrganisation from "./login-area/SelectOrganisation";
import Overview from "./login-area/Overview";
import UploadMemberList from "./login-area/administration/UploadMemberList";
import MemberList from "./login-area/administration/MemberList";
import MemberDetails from "./login-area/administration/MemberDetails";
import MemberDetailsEdit from "./login-area/administration/MemberDetailsEdit";
import InspectionAreaOverview from "./login-area/inspection-area/InspectionAreaOverview";
import InspectionDetail from "./login-area/inspection-area/InspectionDetail";
import CatchReports from "./login-area/catch-reports/CatchReports";
import GuestCardPaymentForm from "./guest-cards/GuestCardPaymentForm";
import CheckoutOverview from "./guest-cards/CheckoutOverview";
import PaymentSuccessful from "./guest-cards/PaymentSuccessful";
import Payments from "./login-area/guest-cards/Payments";
import GuestCardOverview from "./guest-cards/GuestCardOverview";
import EditCatchYear from "./login-area/catch-reports/EditCatchYear";
import FeatureConfiguration from "./login-area/fangkarte-admin/FeatureConfiguration";
import CatchReport from "./catch-report/CatchReport";
import CatchReportMemberPage from "./catch-report/CatchReportMemberPage";
import { NetworkCalls } from "./NetworkCalls";
import axios from "axios";
import { Organisation } from "./dtos/Organisation";
import CatchReportGuestCardPage from "./catch-report/CatchReportGuestCardPage";
import UserList from "./login-area/administration/user/UserList";
import MemberWork from "./login-area/member-work/MemberWork";
import ActivateEmailVerification from "./activate/ActivateEmailVerification";
import MemberWorkExtended from "./login-area/member-work-extended/MemberWorkExtended";
import MemberWorkReport from "./login-area/reports/MemberWorkReport";
import ForgotPassword from "./auth/ForgotPassword";
import ResetPassword from "./auth/ResetPassword";
import WaterListOverview from "./login-area/water-administration/WaterListOverview";
import AppointmentCategories from "./login-area/fangkarte-admin/AppointmentCategories";
import Appointments from "./login-area/administration/Appointments";
import News from "./login-area/administration/News";
import GuestCardAdmin from "./login-area/guest-cards/GuestCardAdmin";
import EditGuestCard from "./login-area/guest-cards/EditGuestCard";
import AddGuestCard from "./login-area/guest-cards/AddGuestCard";
import DocumentManagement from "./login-area/administration/document-management/DocumentManagement";

axios.defaults.baseURL = process.env.REACT_APP_BASE_URL;
axios.defaults.withCredentials = true;

document.body.style.removeProperty("background");

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement,
);

let organisations: Organisation[] = [];

function getOrganisationTokenLoader() {
  return async ({ params }: { params: any }) => {
    try {
      if (!organisations[params.organisationToken]) {
        organisations[params.organisationToken] =
          await NetworkCalls.getOrganisationByToken(params.organisationToken);
      }

      return json(organisations[params.organisationToken]);
    } catch (e) {
      throw new Response("Organisation nicht gefunden", { status: 404 });
    }
  };
}

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    errorElement: <DefaultError />,
    children: [
      {
        path: "guest-card/:organisationId",
        element: <GuestCardPaymentForm />,
      },
      {
        path: "guest-card/",
        element: <GuestCardOverview />,
        children: [
          // TODO
        ],
      },
      {
        path: "catch-report/:organisationToken",
        element: <CatchReport />,
        loader: getOrganisationTokenLoader(),
      },
      {
        path: "catch-report/:organisationToken/member",
        element: <CatchReportMemberPage />,
        loader: getOrganisationTokenLoader(),
      },
      {
        path: "catch-report/:organisationToken/guest-card/:guestCardToken",
        element: <CatchReportGuestCardPage />,
        loader: getOrganisationTokenLoader(),
      },
      {
        path: "checkout",
        element: <CheckoutOverview />,
      },
      {
        path: "activate/:activationToken",
        element: <ActivateEmailVerification />,
      },
      {
        path: "payment-successful",
        element: <PaymentSuccessful />,
      },
      {
        path: "login",
        element: <Auth />,
      },
      {
        path: "forgot-password",
        element: <ForgotPassword />,
      },
      {
        path: "reset-password",
        element: <ResetPassword />,
      },
      {
        path: "reset-password/:resetToken",
        element: <ResetPassword />,
      },
      {
        path: "select-organisation",
        element: <SelectOrganisation />,
      },
      {
        path: "dashboard",
        element: <Overview />,
        children: [
          {
            path: "administration/member-list/upload",
            element: <UploadMemberList />,
          },
          {
            path: "administration/member-list",
            element: <MemberList />,
          },
          {
            path: "administration/member-list/:memberId",
            element: <MemberDetails />,
          },
          {
            path: "administration/member-list/:memberId/edit",
            element: <MemberDetailsEdit />,
          },
          {
            path: "administration/guest-cards",
            element: <GuestCardAdmin />,
          },
          {
            path: "administration/guest-cards/add",
            element: <AddGuestCard />,
          },
          {
            path: "administration/guest-cards/edit/:cardId",
            element: <EditGuestCard />,
          },
          {
            path: "termine/verwalten",
            element: <Appointments />,
          },
          {
            path: "news/verwalten",
            element: <News />,
          },
          {
            path: "water-administration/water-list",
            element: <WaterListOverview />,
          },
          {
            path: "inspections",
            element: <InspectionAreaOverview />,
          },
          {
            path: "inspections/details/:id",
            element: <InspectionDetail />,
          },
          {
            path: "catch-report/catch-year",
            element: <EditCatchYear />,
          },
          {
            path: "catch-report/list",
            element: <CatchReports />,
          },
          {
            path: "guest-cards/payments",
            element: <Payments />,
          },
          {
            path: "administration-area/feature-configuration",
            element: <FeatureConfiguration />,
          },
          {
            path: "administration-area/organisation-categories",
            element: <AppointmentCategories />,
          },
          {
            path: "administration/user/user-list",
            element: <UserList />,
          },
          {
            path: "member-work",
            element: <MemberWork />,
          },
          {
            path: "member-work-extended",
            element: <MemberWorkExtended />,
          },
          {
            path: "member-work-report",
            element: <MemberWorkReport />,
          },
          {
            path: "dokumentenverwaltung",
            element: <DocumentManagement />,
          },
        ],
      },
    ],
  },
]);

root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
