import React, { useEffect, useState } from "react";
import { WaterContactPersonDTO } from "../../dtos/WaterContactPersonDTO";
import uuid from "react-uuid";
import { Button } from "react-bootstrap";
import DeleteDialog from "../../components/DeleteDialog";
import {
  DeepRequired,
  FieldErrorsImpl,
  GlobalError,
  UseFormRegister,
  UseFormSetValue,
  UseFormUnregister,
} from "react-hook-form";
import { WaterDTO } from "../../dtos/WaterDTO";

const renderContactPersonLine = (
  waterContactPerson: WaterContactPersonDTO,
  idx: number,
  prepareDeletion: any,
  register: UseFormRegister<WaterDTO>,
  setValue: UseFormSetValue<WaterDTO>,
  errors?: Partial<FieldErrorsImpl<DeepRequired<WaterDTO>>> & {
    root?: Record<string, GlobalError> & GlobalError;
  }
) => {
  const nameId = uuid();
  const phoneId = uuid();
  const typeId = uuid();
  const btnId = uuid();
  setValue(`contactPersons.${idx}.name`, waterContactPerson.name);
  setValue(`contactPersons.${idx}.type`, waterContactPerson.type);
  setValue(
    `contactPersons.${idx}.telephoneNumber`,
    waterContactPerson.telephoneNumber
  );

  return (
    <div className={"form-row"} style={{ display: "flex" }} key={uuid()}>
      <div className="form-group col-md-3  m-lg-2" key={uuid()}>
        <label htmlFor={nameId}>Name</label>
        <input
          id={nameId}
          className={"form-control"}
          {...register(`contactPersons.${idx}.name`, {
            required: true,
            maxLength: 255,
          })}
          aria-invalid={errors?.contactPersons?.[idx]?.name ? "true" : "false"}
        />
        {errors?.contactPersons?.[idx]?.name && (
          <p className={"text-danger"}>Name fehlt</p>
        )}
      </div>
      <div className="form-group col-md-3 m-lg-2" key={uuid()}>
        <label htmlFor={phoneId}>Telefonnummer</label>
        <input
          id={phoneId}
          type="tel"
          className={"form-control"}
          {...register(`contactPersons.${idx}.telephoneNumber`, {
            required: true,
            maxLength: 255,
          })}
          aria-invalid={
            errors?.contactPersons?.[idx]?.telephoneNumber ? "true" : "false"
          }
        />
        {errors?.contactPersons?.[idx]?.name && (
          <p className={"text-danger"}>Telefonnummer fehlt</p>
        )}
      </div>
      <div className="form-group col-md-3 m-lg-2" key={uuid()}>
        <label htmlFor={typeId}>Typ</label>
        <select
          id={typeId}
          className="form-control"
          {...register(`contactPersons.${idx}.type`, {
            required: true,
            maxLength: 255,
          })}
          aria-invalid={errors?.contactPersons?.[idx]?.type ? "true" : "false"}
        >
          <option></option>
          <option value={"0"}>Gewässerobmann</option>
          <option value={"1"}>Gewässerwart</option>
          <option value={"2"}>Hüttenwart</option>
          <option value={"3"}>Fischereiaufsicht</option>
        </select>
        {errors?.contactPersons?.[idx]?.type && (
          <p className={"text-danger"}>Typ fehlt</p>
        )}
      </div>
      <div className="form-group col-md-1 m-lg-2" key={uuid()}>
        <label htmlFor={btnId}>Löschen</label>
        <Button
          id={btnId}
          variant={"danger"}
          onClick={() => {
            prepareDeletion(waterContactPerson, idx);
          }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="icon icon-tabler icon-tabler-trash"
            width="44"
            height="44"
            viewBox="0 0 24 24"
            strokeWidth="1.5"
            stroke="#ffffff"
            fill="none"
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
            <path d="M4 7l16 0" />
            <path d="M10 11l0 6" />
            <path d="M14 11l0 6" />
            <path d="M5 7l1 12a2 2 0 0 0 2 2h8a2 2 0 0 0 2 -2l1 -12" />
            <path d="M9 7v-3a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v3" />
          </svg>
        </Button>
      </div>
    </div>
  );
};

export default function WaterContactPersonVerwaltung({
  contactPersons,
  visible,
  register,
  unregister,
  setValue,
  errors,
}: {
  contactPersons: WaterContactPersonDTO[] | undefined;
  visible: boolean;
  register: UseFormRegister<WaterDTO>;
  unregister: UseFormUnregister<WaterDTO>;
  setValue: UseFormSetValue<WaterDTO>;
  errors?: Partial<FieldErrorsImpl<DeepRequired<WaterDTO>>> & {
    root?: Record<string, GlobalError> & GlobalError;
  };
}) {
  const [waterContactPersons, setWaterContactPersons] = useState<
    WaterContactPersonDTO[]
  >([]);
  const [show, setShow] = useState<boolean>(false);
  const [deleteWaterContactPersonIdx, setDeleteWaterContactPersonIdx] =
    useState<number>(-1);

  useEffect(() => {
    if (contactPersons) {
      setWaterContactPersons(contactPersons);
    } else {
      setWaterContactPersons([]);
    }
  }, [contactPersons]);

  const prepareDeletion = (
    waterContactPerson: WaterContactPersonDTO,
    idx: number
  ) => {
    setShow(true);
    setDeleteWaterContactPersonIdx(idx);
  };

  const addNewWaterContactPerson = () => {
    if (contactPersons) {
      let newArr = [...waterContactPersons];
      newArr.push({
        id: "",
        name: "",
        type: "",
        water: "",
        telephoneNumber: "",
        description: "",
      });

      setWaterContactPersons(newArr);
    }
  };

  return (
    <>
      {visible ? (
        <div id={uuid()}>
          {waterContactPersons?.map((person, idx) => {
            return renderContactPersonLine(
              person,
              idx,
              prepareDeletion,
              register,
              setValue,
              errors
            );
          })}
          <Button variant={"light"} onClick={addNewWaterContactPerson}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="icon icon-tabler icon-tabler-plus"
              width="44"
              height="44"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="#000000"
              fill="none"
              strokeLinecap="round"
              strokeLinejoin="round"
            >
              <path stroke="none" d="M0 0h24v24H0z" fill="none" />
              <path d="M12 5l0 14" />
              <path d="M5 12l14 0" />
            </svg>
          </Button>
          <DeleteDialog
            onDeleteConfirm={() => {
              if (deleteWaterContactPersonIdx > -1) {
                for (let i = 0; i < waterContactPersons.length; i++) {
                  unregister(`contactPersons.${i}.name`);
                  unregister(`contactPersons.${i}.telephoneNumber`);
                  unregister(`contactPersons.${i}.type`);
                }
                let newArr = [...waterContactPersons];

                newArr.splice(deleteWaterContactPersonIdx, 1);
                setWaterContactPersons(newArr);
              }
              setShow(false);
              setDeleteWaterContactPersonIdx(-1);
            }}
            onHandleClose={() => {
              setShow(false);
              setDeleteWaterContactPersonIdx(-1);
            }}
            title="Löschen"
            message="Datensatz wirklich löschen?"
            show={show}
          />
        </div>
      ) : (
        ""
      )}
    </>
  );
}
