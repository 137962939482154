import { NetworkCalls } from "../NetworkCalls";
import React, { useEffect, useState } from "react";
import { UserCatchReport } from "../dtos/UserCatchReport";
import CatchesTable from "./CatchesTable";

export default function LoadAndShowCatchReports({
  organisationToken,
  memberNumber,
  lastName,
}: {
  organisationToken: string | undefined;
  memberNumber: string | undefined;
  lastName: string | undefined;
}) {
  const [catches, setCatches] = useState<UserCatchReport[]>([]);
  useEffect(() => {
    if (organisationToken && memberNumber && lastName) {
      const fetchData = async () => {
        const catchData = await NetworkCalls.getUserCatchReports({
          organisationToken,
          memberNumber,
          lastName,
        });
        catchData.sort((a, b) => b.catchTime - a.catchTime);
        setCatches(catchData);
      };
      fetchData().then();
    }
  }, [organisationToken, memberNumber, lastName]);

  return (
    <>
      <CatchesTable catches={catches} />
    </>
  );
}
