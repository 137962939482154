import { Button, Modal } from "react-bootstrap";
import React, { useId } from "react";

export default function DeleteDialog({
  onDeleteConfirm,
  onHandleClose,
  title,
  message,
  show,
}: {
  onDeleteConfirm: () => void;
  onHandleClose: () => void;
  title: string;
  message: string;
  show: boolean;
}) {
  const DeleteModalNew = () => {
    const modalId = useId();
    return (
      <Modal show={show} onHide={onHandleClose} id={modalId} z>
        <Modal.Header closeButton>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{message}</Modal.Body>
        <Modal.Footer>
          <Button variant="danger" onClick={onDeleteConfirm}>
            Löschen
          </Button>
          <Button variant="secondary" onClick={onHandleClose}>
            Abbrechen
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  return DeleteModalNew();
}
