import "./App.css";
import { Navigate, Outlet, useNavigate } from "react-router-dom";
import axios from "axios";
import { useEffect } from "react";
import { NetworkCalls } from "./NetworkCalls";

export default function App() {
  axios.defaults.baseURL = process.env.REACT_APP_BASE_URL;
  axios.defaults.withCredentials = true;

  let navigate = useNavigate();

  useEffect(() => {
    axios.interceptors.response.use(
      (response) => {
        NetworkCalls.handleDates(response.data);
        return response;
      },
      (error) => {
        switch (error.response?.status) {
          case 401:
            return navigate("/login");
          case 404:
            return Promise.reject(error);
          case 500:
            return <Navigate to="/ups" replace={true} />;
        }
      }
    );
  }, [navigate]);

  return (
    <>
      <div id="details">
        <Outlet />
      </div>
    </>
  );
}
