import logo from "../img/no_data.svg";
import React from "react";
import { Button } from "react-bootstrap";

export default function EmptyPage({
  onItemAdd,
  addNewElement = false,
  text = "Keine Einträge vorhanden",
}: {
  onItemAdd?: (any: any) => void;
  addNewElement?: boolean;
  text?: string;
}) {
  const addItemBtn = () => {
    return (
      <div>
        <p className="empty-subtitle text-secondary">
          Aktuell gibt es keine Datensätze für diesen Bereich.
        </p>
        <div className="empty-action">
          <Button variant="primary" onClick={onItemAdd}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="icon"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              strokeWidth="2"
              stroke="currentColor"
              fill="none"
              strokeLinecap="round"
              strokeLinejoin="round"
            >
              <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
              <path d="M12 5l0 14"></path>
              <path d="M5 12l14 0"></path>
            </svg>
            Füge einen neuen Datensatz hinzu
          </Button>
        </div>
      </div>
    );
  };

  const emptyPage = () => {
    return (
      <div className="container-xl d-flex flex-column justify-content-center">
        <div className="empty">
          <div className="empty-img">
            <img src={logo} height="128" alt="Kein Daten Symbolbild" />
          </div>
          <p className="empty-title">{text}</p>
          {addNewElement ? addItemBtn() : ""}
        </div>
      </div>
    );
  };
  return emptyPage();
}
