import {Link, useNavigate} from 'react-router-dom';
import {Button, Form} from 'react-bootstrap';
import {ChangeEvent, FormEvent, useState} from 'react';
import {NetworkCalls} from '../NetworkCalls';

export default function ForgotPassword() {


    const [validated, setValidated] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [formData, setFormData] = useState<FormData>({email:''});
    const navigate = useNavigate();

    interface FormData {
        email: string;
    }

    const onEmailChange = (event: ChangeEvent<HTMLInputElement>) =>
        setFormData(prevState => {
            return {...prevState, email: event.target.value } as FormData;
        });

    const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        event.stopPropagation();

        const form = event.currentTarget;
        let checkValidity = form.checkValidity();
        setValidated(true);

        if (!checkValidity) {
            return Promise.reject('Ungültige Eingabe');
        }

        // POST /user/resetPassword
        // Request token

        setLoading(true);
        try {
            NetworkCalls.requestPasswordResetMail(formData.email)
                .then(() => {
                    // abc
                    return navigate('/reset-password');
                })
                .catch((r) => {
                    // xyz
                    console.error(r);
                });
        } finally {
            setLoading(false);
        }


        // PUT /user/resetPassword
        // set new password

    };

    return <>
        <div className='Auth-form-container App'>
            <div className='Auth-form w-50'>
                <div className='Auth-form-content'>
                    <h1>
                        Passwort vergessen? Kein Problem!
                    </h1>
                    <h2>
                        Fordern Sie jetzt eine E-Mail an, um Ihr Passwort zurücksetzen zu können.
                    </h2>
                    <div>
                        <Form noValidate validated={validated} onSubmit={handleSubmit}>
                            <Form.Group className='mb-3' controlId='formBasicEmail'>
                                <Form.Text>Sollte die E-Mail-Adresse nicht bei uns im System sein, erhalten Sie auch keine E-Mail.</Form.Text>
                                <Form.Label id='email-label'>Ihre E-Mail-Adresse</Form.Label>
                                <Form.Control type='email' placeholder='Ihre E-Mail-Adresse' required={true} aria-describedby='email-label' onChange={onEmailChange} />
                                <Form.Control.Feedback type='invalid'>
                                    Bitte geben Sie Ihre E-Mail-Adresse an, um ihr Passwort zurücksetzen zu können.
                                </Form.Control.Feedback>
                            </Form.Group>
                            <Button type='submit' variant='primary' disabled={loading}>
                                Passwort zurücksetzen
                            </Button>
                        </Form>
                    </div>
                    <p className='mt-2'>
                        <Link to={'/login'}>Zurück zum Login</Link>
                    </p>
                </div>
            </div>
        </div>
    </>
}
