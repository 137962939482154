import "./gewaesserumrandung.css";
import React, {useEffect, useId, useState} from "react";
import {NetworkCalls} from "../../NetworkCalls";
import GeoCoordinatesDTO from "../../dtos/GeoCoordinatesDTO";
import {APIProvider, Map as GMap} from "@vis.gl/react-google-maps";
import groupBy from "./components/GroupBy";
import {Shape, ShapeType, Umrandung} from "./components/Umrandung";
import Gewaesserumrandung from "./components/Gewaesserumrandung";
import CenterMaps from "./components/CenterMaps";
import DeleteDialog from "../../components/DeleteDialog";
import DrawingManager from "./components/DrawingManager";
import AddProtectionTimeToShape from "../../components/AddProtectionTimeToShape";
import {Button} from "react-bootstrap";
import {UseFormSetValue} from "react-hook-form";
import {WaterDTO} from "../../dtos/WaterDTO";
import {DrawingMode} from "./components/DrawingMode";
import MoveShapeDialog from "../../components/MoveShapeDialog";
import {WaterShapeProtectionTimeDTO} from "../../dtos/WaterShapeProtectionTimeDTO";

export default function GewaesserumrandungVerwaltung({
                                                         visible,
                                                         waterId,
                                                         setValue,
                                                     }: {
    visible: boolean;
    waterId: string | undefined;
    setValue: UseFormSetValue<WaterDTO>;
}) {
    const id = useId();

    const [shapePoly, setShapePoly] = useState<Umrandung[]>([]);
    const [protectionPoly, setProtectionPoly] = useState<Umrandung[]>([]);
    const [geoCoordinates, setGeoCoordinates] = useState<GeoCoordinatesDTO[]>([]);
    const [drawingMode, setDrawingMode] = useState<DrawingMode>(DrawingMode.NONE);

    useEffect(() => {
        let geoCoordinates: GeoCoordinatesDTO[] = [];
        let waterShapeProtectionTime: WaterShapeProtectionTimeDTO[] = [];

        shapePoly.forEach((elem) => {
            if (elem.startProtection && elem.endProtection) {
                const protectionStart = new Date(elem.startProtection);
                const protectionEnd = new Date(elem.endProtection);

                waterShapeProtectionTime.push({
                    startDay: protectionStart.getDate(),
                    startMonth: protectionStart.getMonth(),
                    endDay: protectionEnd.getDate(),
                    endMonth: protectionEnd.getMonth(),
                    waterGroup: elem.coordinates.length > 0 ? elem.coordinates[0].waterGroup : -1
                } as WaterShapeProtectionTimeDTO);
            }
            geoCoordinates = geoCoordinates.concat(elem.coordinates);
        });

        protectionPoly.forEach((elem) => {
            geoCoordinates = geoCoordinates.concat(elem.coordinates);
        });

        setValue("geoCoordinates", geoCoordinates);
        setValue("waterShapeProtectionTimes", waterShapeProtectionTime);

    }, [shapePoly, protectionPoly, setValue]);

    useEffect(() => {
        if (waterId) {
            const fetchData = async () => {
                const networkResult = await NetworkCalls.getGeoCoordinates(waterId);
                const protectionTime = await NetworkCalls.getShapeProtectionZone(waterId);
                setGeoCoordinates(networkResult);

                const shapePoly: Umrandung[] = [];
                const protectionPoly: Umrandung[] = [];

                const result = groupBy(networkResult, "waterGroup");

                for (let key of result.keys()) {
                    const coordinates = result.get(key);
                    if (coordinates && coordinates.length > 0) {
                        const coordinatesType = groupBy(coordinates, "coordinatesType");

                        if (coordinatesType.has(0)) {
                            const waterShape = coordinatesType.get(0);
                            const protectionTimeElement = protectionTime.find(elem => elem.waterGroup === key);
                            let startProtection = undefined;
                            let endProtection = undefined;
                            if (protectionTimeElement) {
                                const currentDate = new Date();
                                startProtection = new Date(`${currentDate.getFullYear()}-${protectionTimeElement.startMonth + 1}-${protectionTimeElement.startDay}`);
                                endProtection = new Date(`${currentDate.getFullYear()}-${protectionTimeElement.endMonth + 1}-${protectionTimeElement.endDay}`);

                                startProtection.setHours(12);
                                endProtection.setHours(12);

                                if (currentDate > endProtection) {
                                    startProtection = new Date(`${currentDate.getFullYear() + 1}-${protectionTimeElement.startMonth + 1}-${protectionTimeElement.startDay}`);
                                    endProtection = new Date(`${currentDate.getFullYear() + 1}-${protectionTimeElement.endMonth + 1}-${protectionTimeElement.endDay}`);
                                    startProtection.setHours(12);
                                    endProtection.setHours(12);
                                }
                            }
                            shapePoly.push({
                                visible: true,
                                coordinates: waterShape || [],
                                key: key,
                                shape: determineShapeStyle(waterShape || []),
                                shapeType: ShapeType.UMRANDUNG,
                                startProtection: startProtection ? `${startProtection.toISOString().split("T")[0]}` : undefined,
                                endProtection: endProtection ? `${endProtection.toISOString().split("T")[0]}` : undefined
                            });
                        }

                        if (coordinatesType.has(1)) {
                            const protectionShape = coordinatesType.get(1);
                            protectionPoly.push({
                                visible: true,
                                coordinates: protectionShape || [],
                                key: key,
                                shape: determineShapeStyle(protectionShape || []),
                                shapeType: ShapeType.SCHONGEBIET,
                            });
                        }
                    }
                }

                shapePoly.sort((a, b) => a.key - b.key);
                setShapePoly(shapePoly);
                protectionPoly.sort((a, b) => a.key - b.key);
                setProtectionPoly(protectionPoly);
            };
            fetchData().then();
        }
    }, [waterId]);

    const determineShapeStyle = (coordinates: GeoCoordinatesDTO[]) => {
        let polygon = false;

        let shapeDeterminition = coordinates.filter((elem) => elem.waterType === 0);
        if (shapeDeterminition.length === coordinates.length) {
            polygon = true;
        } else if (
            shapeDeterminition.length !== coordinates.length &&
            shapeDeterminition.length !== 0
        ) {
            // TODO ERROR
            return Shape.UNKNOWN;
        }

        let protectionZone = false;
        shapeDeterminition = coordinates.filter(
            (elem) => elem.coordinatesType === 1
        );
        if (shapeDeterminition.length === coordinates.length) {
            protectionZone = true;
        } else if (
            shapeDeterminition.length !== coordinates.length &&
            shapeDeterminition.length !== 0
        ) {
            // TODO ERROR
            return Shape.UNKNOWN;
        }

        if (protectionZone && polygon) {
            return Shape.PROTECTION_POLYGON;
        }

        if (!protectionZone && polygon) {
            return Shape.SHAPE_POLYGON;
        }

        if (protectionZone && !polygon) {
            return Shape.PROTECTION_POLYLINE;
        }

        if (!protectionZone && !polygon) {
            return Shape.SHAPE_POLYLINE;
        }

        return Shape.UNKNOWN;
    };

    const handleVisibilityChange = (idx: number, visible: boolean) => {
        const newRows = [...shapePoly];
        newRows[idx].visible = visible;
        setShapePoly(() => [...newRows]);
    };

    const handleProtectionVisibilityChange = (idx: number, visible: boolean) => {
        const newRows = [...protectionPoly];
        newRows[idx].visible = visible;
        setProtectionPoly(() => [...newRows]);
    };

    const organizeGeoCoordinates = () => {
        return (
            <div className={"accordion"}>
                <div className="accordion-item">
                    <h2 className="accordion-header" id="heading-1">
                        <div className={"row"}>
                            <div className={"col-10"}>
                                <button
                                    className="accordion-button collapsed"
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#collapse-1"
                                    aria-expanded="false"
                                >
                                    Umrandungen
                                </button>
                            </div>
                            <div
                                className={"col-auto"}
                                style={{paddingTop: "12px", cursor: "pointer"}}
                                onClick={() => {
                                    setDrawingMode(DrawingMode.SHAPE);
                                }}
                            >
                <span>
                  <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="icon icon-tabler icon-tabler-plus"
                      width="44"
                      height="44"
                      viewBox="0 0 24 24"
                      strokeWidth="1.5"
                      stroke="#000000"
                      fill="none"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                  >
                    <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                    <path d="M12 5l0 14"/>
                    <path d="M5 12l14 0"/>
                  </svg>
                </span>
                            </div>
                        </div>
                    </h2>
                    <div
                        id="collapse-1"
                        className="accordion-collapse collapse"
                        data-bs-parent="#accordion-example"
                    >
                        <div className="accordion-body pt-0">
                            {shapePoly && shapePoly.length > 0
                                ? shapePoly.map((elem, idx) => {
                                    return (
                                        <UmrandungElement
                                            onVisibleChange={(visible) => {
                                                handleVisibilityChange(idx, visible);
                                            }}
                                            onDeleteUmrandung={() => {
                                                const newRows = [...shapePoly];
                                                newRows.splice(idx, 1);
                                                setShapePoly(() => [...newRows]);
                                            }}
                                            onDateChange={(start, end) => {
                                                const newRows = [...shapePoly];
                                                newRows[idx].startProtection = start;
                                                newRows[idx].endProtection = end;
                                                setShapePoly(() => [...newRows]);
                                            }}
                                            umrandung={elem}
                                            waterId={waterId || ""}/>
                                    );
                                })
                                : "Keine Umrandungen vorhanden"}
                        </div>
                    </div>
                </div>
                <div className="accordion-item">
                    <h2 className="accordion-header" id="heading-2">
                        <div className={"row"}>
                            <div className={"col-10"}>
                                <button
                                    className="accordion-button collapsed"
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#collapse-2"
                                    aria-expanded="false"
                                >
                                    Schongebiete
                                </button>
                            </div>
                            <div
                                className={"col-auto"}
                                style={{paddingTop: "12px", cursor: "pointer"}}
                                onClick={() => {
                                    setDrawingMode(DrawingMode.PROTECTION_ZONE);
                                }}
                            >
                <span>
                  <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="icon icon-tabler icon-tabler-plus"
                      width="44"
                      height="44"
                      viewBox="0 0 24 24"
                      strokeWidth="1.5"
                      stroke="#000000"
                      fill="none"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                  >
                    <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                    <path d="M12 5l0 14"/>
                    <path d="M5 12l14 0"/>
                  </svg>
                </span>
                            </div>
                        </div>
                    </h2>
                    <div
                        id="collapse-2"
                        className="accordion-collapse collapse"
                        data-bs-parent="#accordion-example"
                    >
                        <div className="accordion-body pt-0">
                            {protectionPoly && protectionPoly.length > 0
                                ? protectionPoly.map((elem, idx) => (
                                    <UmrandungElement
                                        umrandung={elem}
                                        onVisibleChange={(visible) => {
                                            handleProtectionVisibilityChange(idx, visible);
                                        }}
                                        onDeleteUmrandung={() => {
                                            const newRows = [...protectionPoly];
                                            newRows.splice(idx, 1);
                                            setProtectionPoly(() => [...newRows]);
                                        }}
                                        onDateChange={(start, end) => {
                                            const newRows = [...protectionPoly];
                                            newRows[idx].startProtection = start;
                                            newRows[idx].endProtection = end;
                                            setProtectionPoly(() => [...newRows]);
                                        }}
                                        waterId={waterId || ""}/>
                                ))
                                : "Keine Umrandungen vorhanden"}
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    const showContent = () => {
        return (
            <APIProvider apiKey={process.env.REACT_APP_GOOGLE_MAPS_KEY!}>
                <div className="container">
                    <div className="row">
                        <div className="col-4">{organizeGeoCoordinates()}</div>
                        <div className="col-8">
                            <GMap
                                id={id}
                                style={{
                                    width: "100%",
                                    height: "400px",
                                }}
                                defaultCenter={{lat: 22.54992, lng: 0}}
                                defaultZoom={3}
                                gestureHandling={"greedy"}
                                disableDefaultUI={true}
                            >
                                {shapePoly.map((elem) => (
                                    <Gewaesserumrandung umrandung={elem} visible={elem.visible}/>
                                ))}
                                {protectionPoly.map((elem) => (
                                    <Gewaesserumrandung umrandung={elem} visible={elem.visible}/>
                                ))}
                                <CenterMaps coordinates={geoCoordinates}/>
                                <DrawingManager
                                    drawingMode={drawingMode}
                                    onDrawingFinish={drawingFinish}
                                />
                            </GMap>
                        </div>
                    </div>
                </div>
            </APIProvider>
        );
    };

    const drawingFinish = (geoCoordinates: GeoCoordinatesDTO[]) => {
        if (drawingMode === DrawingMode.SHAPE) {
            let maxGroup = 0;
            let shape = 0;
            shapePoly.forEach((elem) => {
                elem.coordinates.forEach((coordinate) => {
                    if (maxGroup < coordinate.waterGroup) {
                        maxGroup = coordinate.waterGroup;
                    }
                });
            });
            maxGroup = maxGroup + 1;

            geoCoordinates.forEach((coordinate) => {
                coordinate.waterGroup = maxGroup;
                shape = coordinate.waterType;
            });

            const newRows = [...shapePoly];
            newRows.push({
                visible: true,
                coordinates: geoCoordinates,
                key: newRows.length,
                shape: shape === 0 ? Shape.SHAPE_POLYGON : Shape.SHAPE_POLYLINE,
                shapeType: ShapeType.UMRANDUNG,
            });
            setShapePoly(() => [...newRows]);
            setDrawingMode(DrawingMode.NONE);
        }

        if (drawingMode === DrawingMode.PROTECTION_ZONE) {
            let maxGroup = 0;
            let shape = 0;
            protectionPoly.forEach((elem) => {
                elem.coordinates.forEach((coordinate) => {
                    if (maxGroup < coordinate.waterGroup) {
                        maxGroup = coordinate.waterGroup;
                    }
                });
            });
            maxGroup = maxGroup + 1;

            geoCoordinates.forEach((coordinate) => {
                coordinate.waterGroup = maxGroup;
                shape = coordinate.waterType;
            });

            const newRows = [...protectionPoly];
            newRows.push({
                visible: true,
                coordinates: geoCoordinates,
                key: newRows.length,
                shape:
                    shape === 0 ? Shape.PROTECTION_POLYGON : Shape.PROTECTION_POLYLINE,
                shapeType: ShapeType.SCHONGEBIET
            });
            setProtectionPoly(() => [...newRows]);
            setDrawingMode(DrawingMode.NONE);
        }
    };

    return <>{visible ? showContent() : ""}</>;
}

const UmrandungElement = ({
                              umrandung,
                              onVisibleChange,
                              onDeleteUmrandung,
                              onDateChange,
                              waterId,
                          }: {
    umrandung: Umrandung;
    onVisibleChange: (visible: boolean) => void;
    onDeleteUmrandung: () => void;
    onDateChange: (start?: string, end?: string) => void;
    waterId: string;
}) => {
    const [showDeleteDialog, setShowDeleteDialog] = useState(false);
    const [showDescriptionDialog, setShowDescriptionDialog] = useState(false);
    const [showMoveShapeDialog, setShowMoveShapeDialog] = useState(false);

    const addProtectionTimeToShape = () => {
        if (umrandung.shapeType === ShapeType.UMRANDUNG) {
            return <AddProtectionTimeToShape
                beginn={
                    umrandung.startProtection ? umrandung.startProtection : undefined
                }
                ende={umrandung.endProtection ? umrandung.endProtection : undefined}
                show={showDescriptionDialog}
                onClose={() => setShowDescriptionDialog(false)}
                onDateChange={onDateChange}
            />;
        }
        return <></>;
    }

    const  renderUmrandungText = () => {
        if (umrandung.shapeType === ShapeType.UMRANDUNG) {
            return <Button
                className="btn btn-link"
                onClick={() => {
                    setShowDescriptionDialog(true);
                }}
            >
                Umrandung {umrandung.key}
            </Button>;
        }
        return <div className='btn btn-link'>Umrandung {umrandung.key}</div>
    }

    return (
        <div
            className={"row pt-3"}
            style={
                umrandung.startProtection && umrandung.endProtection
                    ? {backgroundColor: "#ffcc00"}
                    : {}
            }
        >
            <DeleteDialog
                onDeleteConfirm={() => {
                    onDeleteUmrandung();
                    setShowDeleteDialog(false);
                }}
                onHandleClose={() => setShowDeleteDialog(false)}
                title={"Datensatz löschen"}
                message={"Datensatz wirklich löschen?"}
                show={showDeleteDialog}
            />
            <MoveShapeDialog
                afterSuccess={() => setShowMoveShapeDialog(false)}
                show={showMoveShapeDialog}
                umrandung={umrandung} waterId={waterId}
                onClose={() => setShowMoveShapeDialog(false)}
            />

            {addProtectionTimeToShape()}

            <div className={"col pt-2"} onClick={() => setShowMoveShapeDialog(true)} style={{cursor: "pointer"}}>
                <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-git-cherry-pick"
                     width="44"
                     height="44" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#000000" fill="none"
                     strokeLinecap="round"
                     strokeLinejoin="round">
                    <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                    <path d="M7 12m-3 0a3 3 0 1 0 6 0a3 3 0 1 0 -6 0"/>
                    <path d="M7 3v6"/>
                    <path d="M7 15v6"/>
                    <path d="M13 7h2.5l1.5 5l-1.5 5h-2.5"/>
                    <path d="M17 12h3"/>
                </svg>
            </div>
            <div className={"col pt-2"}>
        <span
            className="col-auto"
            onClick={() => {
                setShowDeleteDialog(true);
            }}
            style={{cursor: "pointer"}}
        >
          <svg
              xmlns="http://www.w3.org/2000/svg"
              className="icon icon-tabler icon-tabler-trash-x-filled"
              width="44"
              height="44"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="#ff2825"
              fill="none"
              strokeLinecap="round"
              strokeLinejoin="round"
          >
            <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
            <path
                d="M20 6a1 1 0 0 1 .117 1.993l-.117 .007h-.081l-.919 11a3 3 0 0 1 -2.824 2.995l-.176 .005h-8c-1.598 0 -2.904 -1.249 -2.992 -2.75l-.005 -.167l-.923 -11.083h-.08a1 1 0 0 1 -.117 -1.993l.117 -.007h16zm-9.489 5.14a1 1 0 0 0 -1.218 1.567l1.292 1.293l-1.292 1.293l-.083 .094a1 1 0 0 0 1.497 1.32l1.293 -1.292l1.293 1.292l.094 .083a1 1 0 0 0 1.32 -1.497l-1.292 -1.293l1.292 -1.293l.083 -.094a1 1 0 0 0 -1.497 -1.32l-1.293 1.292l-1.293 -1.292l-.094 -.083z"
                strokeWidth="0"
                fill="currentColor"
            />
            <path
                d="M14 2a2 2 0 0 1 2 2a1 1 0 0 1 -1.993 .117l-.007 -.117h-4l-.007 .117a1 1 0 0 1 -1.993 -.117a2 2 0 0 1 1.85 -1.995l.15 -.005h4z"
                strokeWidth="0"
                fill="currentColor"
            />
          </svg>
        </span>
            </div>
            <div className={"col-10"}>
                <label className={"row"}>
          <span className={"col"}>
            {renderUmrandungText()}
          </span>
                    <span className={"col-auto pt-2"}>
            <label className="form-check form-check-single form-switch">
              <input
                  className="form-check-input"
                  type="checkbox"
                  checked={umrandung.visible}
                  onChange={(evt) => {
                      onVisibleChange(evt.target.checked);
                  }}
              />
            </label>
          </span>
                </label>
            </div>
        </div>
    );
};
