import React, { useEffect, useState } from "react";
import { NetworkCalls } from "../../NetworkCalls";
import { BoughtGuestCard } from "../../dtos/BoughtGuestCard";
import LoadingPageIndicator from "../../components/LoadingPageIndicator";
import uuid from "react-uuid";
import TimestampFormat from "../../utils/TimestampFormat";

export default function Payments() {
  const [loading, isLoading] = useState<boolean>(false);
  const [displayDialog, setDisplayDialog] = useState<boolean>(false);
  const [refundLoading, setRefundLoading] = useState<String>();
  const [boughtGuestCards, setBoughtGuestCards] = useState<BoughtGuestCard[]>(
    []
  );
  const [guestCardRefund, setGuestCardRefund] = useState<BoughtGuestCard>();
  const [error, setError] = useState<String>();

  useEffect(() => {
    isLoading(true);
    NetworkCalls.getBoughtGuestCards()
      .then((result) => {
        setBoughtGuestCards(result);
      })
      .finally(() => isLoading(false));
  }, []);

  function showContent() {
    return (
      <div>
        <div
          className="alert alert-danger"
          role="alert"
          style={{ display: error ? "block" : "none" }}
        >
          {error}
        </div>
        <div className="m-5 col row justify-content-center">
          <div className="card border-0">{showBoughtGuestCardsTable()}</div>
        </div>
        <div
          className="modal"
          style={{ display: displayDialog ? "block" : "none" }}
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">
                  Gastkarte erstatten
                </h5>
              </div>
              <div className="modal-body">
                Soll die Gastkarte von {guestCardRefund?.firstName}{" "}
                {guestCardRefund?.lastName} zum Preis von{" "}
                {guestCardRefund?.price}€ wirklich erstattet werden?
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={() => setDisplayDialog(false)}
                >
                  Abbrechen
                </button>
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={() => refund()}
                >
                  Gastkarte erstatten
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  function showBoughtGuestCardsTable() {
    return (
      <table className="table table-hover">
        <thead>
          <tr key={uuid()}>
            <th scope="col">Gastkartennummer</th>
            <th scope="col">Kaufdatum</th>
            <th scope="col">Name</th>
            <th scope="col">Gültigkeit</th>
            <th scope="col">E-Mail</th>
            <th scope="col">Preis</th>
            <th scope="col">Gastkarte</th>
            <th scope="col">Status</th>
            <th scope="col">Rückerstattung</th>
          </tr>
        </thead>
        <tbody id="table-content">{createRows()}</tbody>
      </table>
    );
  }

  function createRows() {
    return boughtGuestCards.map((elem) => {
      return (
        <tr key={uuid()} className="user-select-auto">
          <td>
            <a
              href={`${process.env.REACT_APP_BASE_URL}/guestcard/pdf/${elem.stripeIntentId}`}
              target="_blank"
              rel="noreferrer"
            >
              {elem.stripeIntentId}
            </a>
          </td>
          <td>{TimestampFormat.toDateTimeReadableFormat(elem.dateBought)}</td>
          <td>
            {" "}
            {elem.firstName} {elem.lastName}
          </td>
          <td>
            {TimestampFormat.toDateTimeReadableFormat(elem.startGuestCardDate)}{" "}
            - {TimestampFormat.toDateTimeReadableFormat(elem.endGuestCardDate)}
          </td>
          <td>{elem.email}</td>
          <td>{elem.price}€</td>
          <td>{elem.guestCardId.description}</td>
          <td>{convertStatus(elem.status)}</td>
          <td>
            {elem.status === "REFUNDED" ? (
              ""
            ) : refundLoading === elem.transactionId ? (
              <div className="spinner-border" role="status">
                <span className="sr-only"></span>
              </div>
            ) : (
              <button
                type="button"
                className="btn btn-warning"
                onClick={() => askForRefund(elem)}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="icon icon-tabler icon-tabler-arrow-back-up"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  strokeWidth="2"
                  stroke="currentColor"
                  fill="none"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                  <path d="M9 14l-4 -4l4 -4"></path>
                  <path d="M5 10h11a4 4 0 1 1 0 8h-1"></path>
                </svg>
              </button>
            )}
          </td>
        </tr>
      );
    });
  }

  function askForRefund(guestCard: BoughtGuestCard) {
    setDisplayDialog(true);
    setGuestCardRefund(guestCard);
  }

  function refund() {
    setDisplayDialog(false);
    setError(undefined);
    if (guestCardRefund) {
      setRefundLoading(guestCardRefund.transactionId);
      NetworkCalls.refundGuestCard(guestCardRefund.transactionId)
        .then((result) => {
          boughtGuestCards.forEach((boughtGuestCard) => {
            if (
              boughtGuestCard.transactionId === guestCardRefund.transactionId
            ) {
              boughtGuestCard.status = "REFUNDED";
            }
          });
        })
        .catch((error) => setError("Gastkarte konnte nicht erstattet werden."))
        .finally(() => {
          setRefundLoading(undefined);
          setGuestCardRefund(undefined);
        });
    } else {
      setError("Gastkarte konnte nicht erstattet werden.");
    }
  }

  function convertStatus(status: string) {
    if (status === "REFUNDED") {
      return "Erstattet";
    }

    if (
      status === "payment_intent.succeeded" ||
      status === "charge.succeeded"
    ) {
      return "Zahlung erfolgt";
    }

    if (status === "charge.pending") {
      return "Zahlung ausstehend";
    }
  }

  return <div>{loading ? <LoadingPageIndicator /> : showContent()}</div>;
}
