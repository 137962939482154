import React, { useEffect, useState } from "react";
import { NetworkCalls } from "../../NetworkCalls";
import uuid from "react-uuid";
import ReportMemberWorkDTO from "../../dtos/ReportMemberWorkDTO";
import axios from "axios";
import { OrgaAndRoles } from "../../models/OrgaAndRoles";
import { getData } from "../../utils/storage/Storage";
import { StorageKey } from "../../utils/storage/Keys";
import DateFormat from "../../utils/DateFormat";

export default function MemberWorkReport() {
  const [selectedYear, setSelectedYear] = useState<number>(-1);
  const [years, setYears] = useState<number[]>([]);
  const [reportMemberWork, setReportMemberWork] = useState<
    ReportMemberWorkDTO[]
  >([]);

  useEffect(() => {
    if (selectedYear !== -1) {
      const fetchData = async () => {
        const data = await NetworkCalls.getMemberWorkReport(selectedYear);
        setReportMemberWork(data);
      };
      fetchData().catch(console.error);
    }

    if (years.length === 0) {
      const fetchData = async () => {
        const years = await NetworkCalls.getMemberWorkReportYears();
        setYears(years);

        if (years.length === 1) {
          setSelectedYear(years[0]);
        }
      };
      fetchData().catch(console.error);
    }
  }, [selectedYear, years]);

  const options = years.map((year) => {
    return (
      <option key={year} value={year}>
        {year}
      </option>
    );
  });

  const ShowContent = () => {
    let selectedOrganisation: OrgaAndRoles = JSON.parse(
      getData(StorageKey.SELECTED_ORGANISATION) as string
    );
    return (
      <div className="m-5 col row justify-content-center">
        <div className="card border-0">
          <div className="card-header">
            <div className="row" style={{ minWidth: "100%" }}>
              <div className="col-1">
                <div className="form-inline">
                  <select
                    id="yearControl"
                    title="Jahr"
                    className="form-control"
                    onChange={(elem) =>
                      setSelectedYear(Number(elem.target.value))
                    }
                    defaultValue={selectedYear}
                  >
                    {options}
                  </select>
                </div>
              </div>
              <div className="col-5">
                <h4>Download</h4>
                <a
                  href={`${axios.defaults.baseURL}/reports/member-work/${selectedOrganisation.id}/${selectedYear}/export-csv`}
                  className="link-secondary"
                  title="Download"
                  data-bs-placement="top"
                  data-bs-toggle="tooltip"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="icon icon-tabler icon-tabler-cloud-download"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    strokeWidth="2"
                    stroke="currentColor"
                    fill="none"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  >
                    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                    <path d="M19 18a3.5 3.5 0 0 0 0 -7h-1a5 4.5 0 0 0 -11 -2a4.6 4.4 0 0 0 -2.1 8.4"></path>
                    <path d="M12 13l0 9"></path>
                    <path d="M9 19l3 3l3 -3"></path>
                  </svg>
                </a>
              </div>
            </div>
          </div>
          {showMemberWorkReportTable()}
        </div>
      </div>
    );
  };

  function showMemberWorkReportTable() {
    return (
      <table className="table table-hover">
        <thead>
          <tr key={uuid()}>
            <th scope="col">Mitgliedsnummer</th>
            <th scope="col">Vorname</th>
            <th scope="col">Nachname</th>
            <th scope="col">AD gemacht</th>
            <th scope="col">Arbeitsdienst</th>
            <th scope="col">wann</th>
          </tr>
        </thead>
        <tbody id="table-content">{createRows()}</tbody>
      </table>
    );
  }

  function createRows() {
    return reportMemberWork.map((elem) => {
      if (elem.memberWork) {
        return elem.memberWork.map((mw) => {
          return (
            <tr key={uuid()} className="user-select-auto">
              <td>{elem.memberNumber}</td>
              <td> {elem.firstName}</td>
              <td> {elem.lastName}</td>
              <td>{memberWork(true)}</td>
              <td>{mw.title}</td>
              <td>{DateFormat.toDateTimeReadableFormat(mw.start)}</td>
            </tr>
          );
        });
      } else {
        return (
          <tr key={uuid()} className="user-select-auto">
            <td>{elem.memberNumber}</td>
            <td> {elem.firstName}</td>
            <td> {elem.lastName}</td>
            <td>{memberWork(false)}</td>
            <td></td>
            <td></td>
          </tr>
        );
      }
    });
  }

  const memberWork = (memberWork: boolean | undefined) => {
    if (memberWork) {
      return <span className="status status-green">Ja</span>;
    } else {
      return <span className="status status-red">Nein</span>;
    }
  };

  return <ShowContent />;
}
