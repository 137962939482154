import LoadingPageIndicator from "../../components/LoadingPageIndicator";
import React, { useEffect, useState } from "react";
import { NetworkCalls } from "../../NetworkCalls";
import FangkarteList from "../../components/FangkarteList";
import { FangkarteListItem } from "../../models/FangkarteListItem";
import { MemberWorkMappingDTO } from "../../dtos/MemberWorkMappingDTO";
import uuid from "react-uuid";
import { MemberWorkResponseDTO } from "../../dtos/MemberWorkResponseDTO";
import axios from "axios";

export default function MemberWork() {
  const [loading, setLoading] = useState<boolean>(true);
  const [memberWorkMapping, setMemberWorkMapping] = useState<
    FangkarteListItem[]
  >([]);
  const [name, setName] = useState<string>("");
  const [memberWorkResponse, setMemberWorkResponse] = useState<
    MemberWorkResponseDTO[]
  >([]);

  useEffect(() => {
    const fetchData = async () => {
      const result = await NetworkCalls.getMemberWorkMapping();
      setMemberWorkResponse(await NetworkCalls.getMemberWorkResponse());
      convertData(result);
      setLoading(false);
    };
    fetchData().catch(console.error);
  }, []);

  const convertData = (dto: MemberWorkMappingDTO[]) => {
    setMemberWorkMapping(
      dto.map((memberWorkMapping) => {
        return {
          title: memberWorkMapping.answer,
          subTitle: memberWorkMapping.key,
          originalElement: memberWorkMapping,
        } as FangkarteListItem;
      })
    );
  };

  const deleteMemberWorkMapping = (dto: MemberWorkMappingDTO) => {
    const fetchData = async () => {
      const result = await NetworkCalls.deleteMemberWorkMapping(dto);
      convertData(result);
      setLoading(false);
    };
    fetchData().catch(console.error);
  };

  const showContent = () => {
    return (
      <div className="container">
        <h1>Arbeitsdienst Verwaltung</h1>
        <div className="row">
          <div className="col-5">
            <FangkarteList
              listItems={memberWorkMapping}
              title="Antwortmöglichkeiten"
              exportListEnabled={false}
              addNewElement={true}
              deleteElement={true}
              customDownloadLink={`${axios.defaults.baseURL}/member-work/export-csv`}
              onItemDelete={(elem) => deleteMemberWorkMapping(elem)}
            />
          </div>
          <div className="col-6">
            <div className="card">
              <div className="card-header">
                <h3 className="card-title">Antworten der Mitglieder</h3>
              </div>
              <div className="card-body">
                <table className="table table-hover">
                  <thead>
                    <tr key={uuid()}>
                      <th scope="col">Mitgliedsnummer</th>
                      <th scope="col">Antwort</th>
                      <th scope="col">Jahr</th>
                    </tr>
                  </thead>
                  <tbody id="table-content">{createRows()}</tbody>
                </table>
              </div>
            </div>
          </div>
          {modalDialog()}
        </div>
      </div>
    );
  };

  function createRows() {
    return memberWorkResponse.map((elem) => {
      return (
        <tr key={uuid()} className="user-select-auto">
          <td>{elem.number}</td>
          <td> {elem.answer}</td>
          <td> {elem.year}</td>
        </tr>
      );
    });
  }

  const saveMemberWorkMapping = () => {
    const postData = async () => {
      const result = await NetworkCalls.addMemberWorkMapping({
        answer: name,
        key: "",
        id: "",
      });
      convertData(result);
      setName("");
    };
    postData().catch(console.error);
  };
  const modalDialog = () => {
    return (
      <div className="modal" id="member-work-mapping-modal">
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Arbeitsdiensteintrag hinzufügen</h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              />
            </div>
            <form id="newMemberWorkMapping">
              <div className="modal-body">
                <div className="row mb-3">
                  <div className="col-md-12 pt-3">
                    <label className="form-label text-black">
                      Name des Arbeitsdienst, der dem Angler in der App
                      angezeigt wird (bsp.: AD am Gewässer xy am xx.xx.xx)
                    </label>
                    <input
                      className="form-control"
                      placeholder="Name vergeben"
                      value={name}
                      onChange={(event) => {
                        setName(event.target.value);
                      }}
                    ></input>
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn me-auto"
                  data-bs-dismiss="modal"
                >
                  Abbrechen
                </button>
                <button
                  type="button"
                  className="btn btn-primary"
                  data-bs-dismiss="modal"
                  onClick={saveMemberWorkMapping}
                  disabled={!name}
                >
                  Speichern
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  };

  return <div>{loading ? <LoadingPageIndicator /> : showContent()}</div>;
}
