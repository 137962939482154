import {
  useApiIsLoaded,
  useMap,
  useMapsLibrary,
} from "@vis.gl/react-google-maps";
import { useEffect } from "react";
import GeoCoordinatesDTO from "../../../dtos/GeoCoordinatesDTO";
import {DrawingMode} from "./DrawingMode";

const DrawingManager = ({
  drawingMode,
  onDrawingFinish,
}: {
  drawingMode: DrawingMode;
  onDrawingFinish: (geoCoordinates: GeoCoordinatesDTO[]) => void;
}) => {
  const map = useMap();
  const apiIsLoaded = useApiIsLoaded();
  const drawingLib = useMapsLibrary("drawing");

  useEffect(() => {
    if (!apiIsLoaded) return;
    if (!map) return;
    if (!drawingLib) return;
    if (!drawingMode) return;

    let drawingModes = {};
    switch (drawingMode) {
      case DrawingMode.POINTS:
        drawingModes = {
          drawingModes: [google.maps.drawing.OverlayType.MARKER],
          position: google.maps.ControlPosition.TOP_CENTER,
        };
        break;
      case DrawingMode.SHAPE:
      case DrawingMode.PROTECTION_ZONE:
        drawingModes = {
          drawingControl: true,
          drawingControlOptions: {
            position: google.maps.ControlPosition.TOP_CENTER,
            drawingModes: [
              google.maps.drawing.OverlayType.POLYGON,
              google.maps.drawing.OverlayType.POLYLINE,
            ],
          },
          polylineOptions: {
            strokeColor: "black",
            strokeWeight: 5,
            editable: false,
            zIndex: 1,
            geodesic: true,
          },
        };

        break;
      case DrawingMode.NONE:
        drawingModes = {
          drawingControl: false,
        };
        break;
    }

    const drawingManager = new drawingLib.DrawingManager(drawingModes);
    drawingManager.addListener(
      "overlaycomplete",
      function (event: google.maps.Map) {
        const coordinates: GeoCoordinatesDTO[] = [];
        // @ts-ignore
        switch (event["type"]) {
          case "polygon":
            // @ts-ignore
            event.overlay
              .getPath()
              .getArray()
              // @ts-ignore
              .forEach((path, idx) => {
                coordinates.push({
                  waterType: 0,
                  latitude: path.lat(),
                  longitude: path.lng(),
                  waterGroup: -1,
                  groupPos: idx,
                  coordinatesType:
                    drawingMode === DrawingMode.PROTECTION_ZONE ? 1 : 0,
                });
              });
            break;
          case "polyline":
            // @ts-ignore
            event.overlay
              .getPath()
              .getArray()
              // @ts-ignore
              .forEach((path, idx) => {
                coordinates.push({
                  waterType: 1,
                  latitude: path.lat(),
                  longitude: path.lng(),
                  waterGroup: -1,
                  groupPos: idx,
                  coordinatesType:
                    drawingMode === DrawingMode.PROTECTION_ZONE ? 1 : 0,
                });
              });
            break;
          default:
            break;
        }

        onDrawingFinish(coordinates);
        // @ts-ignore
        event.overlay.setMap(null);
        drawingManager.setMap(null);
      }
    );

    drawingManager.setMap(map);
  }, [map, apiIsLoaded, drawingLib, drawingMode, onDrawingFinish]);

  return <></>;
};

export default DrawingManager;