import SunEditor from "suneditor-react";
import "suneditor/dist/css/suneditor.min.css";
import { useController } from "react-hook-form";

const ButtonCustomList = [
  ["undo", "redo"],
  ["font", "fontSize"],
  ["bold", "underline", "italic", "strike", "subscript", "superscript"],
  ["removeFormat"],
  ["fontColor", "hiliteColor"],
  ["outdent", "indent"],
  ["align", "horizontalRule", "list"],
  ["link"],
  ["fullScreen", "showBlocks", "codeView"],
];

// @ts-ignore
const Editor = ({ control, name, defaultValue, ...props }) => {
  const {
    field: { value, ...inputProps },
  } = useController({
    name,
    control,
    rules: { required: true },
    defaultValue: defaultValue || "",
  });

  return (
    <SunEditor
      {...props}
      {...inputProps}
      defaultValue={value}
      setOptions={{
        height: "200",
        buttonList: ButtonCustomList,
        mode: "balloon",
      }}
    />
  );
};

export default Editor;
