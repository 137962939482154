import React, {useEffect, useState} from "react";
import {Organisation} from "../dtos/Organisation";
import {NetworkCalls} from "../NetworkCalls";
import LoadingPageIndicator from "../components/LoadingPageIndicator";
import {Link} from "react-router-dom";

export default function GuestCardOverview() {

    const [loading, setLoading] = useState<boolean>(true);
    const [organisations, setOrganisations] = useState<Organisation[]>();
    useEffect(() => {
        const fetchData = async () => {
            return await NetworkCalls.listGuestCardsSellingOrganisations();
        };

        fetchData()
            .then(organisationsResult => {
                setOrganisations(organisationsResult);
            })
            .catch(e => {
                // TODO error handling
            })
            .finally(() => {
                setLoading(false);
            });
    }, []);

    const renderOrganisations = () => {
        return (
            <ul className="list-group">
                {organisations?.map(o => (<Link to={`/guest-card/${o.id}`}>
                    <li className="list-group-item">{o.organisationName}</li>
                </Link>))}
            </ul>
        );
    }

    const showContent = () => {
        return (
            <div className="page page-center">
                <div className="container container-tight py-4">
                    <div className="card card-md">
                        <div className="card-body text-center py-4 p-sm-5">
                            <h1 className="mt-5">Gastkarten</h1>
                        </div>
                        {organisations && organisations.length > 0 ? renderOrganisations() : ''}
                    </div>
                </div>
            </div>
        );
    }

    return (<div>
        {loading ? (<LoadingPageIndicator/>) : showContent()}
    </div>);
}