import { Link, useLoaderData } from "react-router-dom";
import { Organisation } from "../dtos/Organisation";
import { Alert, Button, Card, Col, Modal, Row } from "react-bootstrap";
import React, {
  FormEvent,
  useCallback,
  useEffect,
  useId,
  useState,
} from "react";
import { getData, storeData } from "../utils/storage/Storage";
import { StorageKey } from "../utils/storage/Keys";
import { NetworkCalls } from "../NetworkCalls";
import { UserCatchReport } from "../dtos/UserCatchReport";
import { CatchReportUser } from "../dtos/CatchReportUser";
import CatchesTable from "./CatchesTable";
import NewCatchInputForm from "./NewCatchInputForm";
import { UserCatchReportAddFormData } from "../dtos/UserCatchReportAddFormData";
import { CatchYear } from "../dtos/CatchYear";
import TimestampFormat from "../utils/TimestampFormat";

export default function CatchReportMemberPage() {
  const organisation = useLoaderData() as Organisation;

  const [catches, setCatches] = useState<UserCatchReport[]>([]);
  const [user, setUser] = useState<CatchReportUser>({
    lastName: "",
    memberNumber: "",
    saveInputs: false,
  });

  const [validated, setValidated] = useState<boolean>(false);
  const [catchReportAvailable, setCatchReportsAvailable] =
    useState<boolean>(true);

  const [catchYear, setCatchYear] = useState<CatchYear | undefined>();
  // const [reportedCatchYears, setReportedCatchYears] = useState<ReportCatchYearDTO[] | undefined>();

  const [catchYearModal, setCatchYearModal] = useState<boolean>(false);
  const [showAlert, setShowAlert] = useState<boolean>(false);
  const [alertVariant, setAlertVariant] = useState<string | undefined>();
  const [alertText, setAlertText] = useState<string>("");
  const [alertHeaderText, setAlertHeaderText] = useState<string>("");
  const [reloadData, setReloadData] = useState<boolean>(false);

  const formLabelCols = 3;
  const formInputCols = 12 - formLabelCols;

  const updateCatches = useCallback(() => {
    if (!user.memberNumber || !user.lastName) return Promise.resolve();

    return NetworkCalls.getUserCatchReports({
      organisationToken: organisation.organisationToken,
      memberNumber: user.memberNumber,
      lastName: user.lastName,
    }).then((result) => {
      result.sort((a, b) => b.catchTime - a.catchTime);
      setCatches(result);
    });
  }, [user.lastName, user.memberNumber, organisation.organisationToken]);

  useEffect(() => {
    const fetchData = async () => {
      const result = await NetworkCalls.reportCatchYearAvailable({
        organisationId: organisation.id,
      });

      const myReportedYears = await NetworkCalls.listMyReportedCatchYears({
        organisationId: organisation.id,
        memberNumber: user.memberNumber,
        lastName: user.lastName,
      });

      const catchYearReported = myReportedYears.find(
        (elem) => elem.catchYearId === result.id
      );

      const currentDate = new Date();
      setCatchYear(undefined);
      if (catchYearReported !== undefined) {
        if (
          result.startCatchYear < currentDate.getTime() &&
          result.endCatchYear >= currentDate.getTime()
        ) {
          setShowAlert(true);
          setAlertVariant("info");
          setAlertHeaderText("Information");
          setAlertText(
            "Du kannst aktuell keine Fänge eintragen, da du das aktuelle Fangjahr bereits abgeschlossen hast"
          );
          setCatchReportsAvailable(false);
        }
      } else {
        setCatchYear(result);
      }

      // setReportedCatchYears(myReportedYears);
    };
    if (reloadData) {
      setReloadData(false);
      updateCatches()
        .then(() => {
          fetchData().then();
        })
        .catch(console.error);
    }
  }, [
    updateCatches,
    reloadData,
    organisation.id,
    user.lastName,
    user.memberNumber,
  ]);

  useEffect(() => {
    const dataStringUser = getData(StorageKey.CATCH_REPORT_USER) || "{}";
    const users = JSON.parse(dataStringUser);
    if (users[organisation.organisationToken]) {
      setUser(users[organisation.organisationToken]);
      setReloadData(true);
    }
  }, [organisation.organisationToken]);

  const renderH1 = () => {
    return (
      <>
        Fangrückmeldung - {organisation.organisationName} - Vereinsmitglied:{" "}
        {user.lastName} ({user.memberNumber})
      </>
    );
  };

  const clearUserData = () => {
    if (!user.saveInputs) {
      const dataStringUser = getData(StorageKey.CATCH_REPORT_USER) || "{}";
      const users = JSON.parse(dataStringUser);
      if (
        users[organisation.organisationToken] &&
        !users[organisation.organisationToken].saveInputs
      ) {
        users[organisation.organisationToken] = undefined;
        storeData(StorageKey.CATCH_REPORT_USER, JSON.stringify(users));
      }
    }
  };

  const renderCardFooter = () => {
    return (
      <Card.Footer>
        <Link
          to={`/catch-report/${organisation.organisationToken}`}
          className="btn btn-secondary"
          onClick={clearUserData}
        >
          Fertig
        </Link>
      </Card.Footer>
    );
  };

  const onSubmit = (
    event: FormEvent<HTMLFormElement>,
    formData: UserCatchReportAddFormData
  ): Promise<void> => {
    const form = event.currentTarget;

    event.stopPropagation();
    event.preventDefault();

    let checkValidity = form.checkValidity();
    setValidated(true);

    if (!checkValidity) {
      return Promise.reject("Es fehlen Eingaben");
    }

    formData.memberId = user.memberNumber;
    formData.lastName = user.lastName;

    return NetworkCalls.addUserCatchResult(formData).then(async (_) => {
      setValidated(false);
      await updateCatches();
    });
  };

  const showContent = () => {
    return (
      <>
        {CloseCatchYearModal()}
        <div className="page page-center">
          <div className="container py-4">
            <div className="m-5 col row justify-content-center">
              <Alert
                show={showAlert}
                variant={alertVariant}
                onClose={() => setShowAlert(false)}
                dismissible
              >
                <Alert.Heading>{alertHeaderText}</Alert.Heading>
                <p>{alertText}</p>
              </Alert>
              <Card>
                <Card.Header>
                  <div className="d-flex">
                    <h1>{renderH1()}</h1>
                    <Button
                      hidden={catchYear === undefined}
                      className="btn btn-azure"
                      style={{ marginLeft: "1rem" }}
                      onClick={() => setCatchYearModal(true)}
                    >
                      Fangjahr abschließen
                    </Button>
                  </div>
                </Card.Header>
                <Card.Body>
                  <Row>
                    <Col md={6}>
                      <CatchesTable catches={catches} />
                    </Col>
                    <hr className="d-md-none" />
                    <Col md={6} hidden={!catchReportAvailable}>
                      <h2>Neuen Fang eintragen</h2>
                      <NewCatchInputForm
                        onSubmit={onSubmit}
                        validated={validated}
                        formLabelCols={formLabelCols}
                        formInputCols={formInputCols}
                        organisation={organisation}
                      />
                    </Col>
                  </Row>
                </Card.Body>
                {renderCardFooter()}
              </Card>
            </div>
          </div>
        </div>
      </>
    );
  };

  const handleClose = () => {
    setCatchYearModal(false);
  };

  const CloseCatchYearModal = () => {
    const modalId = useId();
    return (
      <Modal
        show={catchYearModal && catchYear !== undefined}
        onHide={handleClose}
        id={modalId}
      >
        <Modal.Header closeButton>
          <Modal.Title>Fangjahr {catchYear?.name} abschließen</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Möchtest du wirklich das Fangjahr {catchYear?.name} abschließen?
          Danach kannst du für den Zeitraum{" "}
          {catchYear !== undefined && catchYear.startCatchYear !== undefined
            ? TimestampFormat.toDateOnlyReadableFormat(
                catchYear.startCatchYear + 60 * 60 * 24 * 1000
              )
            : ""}{" "}
          -{" "}
          {catchYear !== undefined && catchYear.endCatchYear !== undefined
            ? TimestampFormat.toDateOnlyReadableFormat(
                catchYear.endCatchYear + 60 * 60 * 24 * 1000
              )
            : ""}{" "}
          keine Fänge mehr eintragen
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="primary"
            onClick={() => {
              NetworkCalls.reportCatchYear({
                organisationId: organisation.id,
                memberNumber: user.memberNumber,
              })
                .then(() => {
                  setShowAlert(true);
                  setAlertVariant("success");
                  setAlertHeaderText("Erfolgreich");
                  setAlertText(
                    "Das Fangjahr wurde erfolgreich abgeschlossen. Du musst nichts weiter tun"
                  );
                  setReloadData(true);
                })
                .catch((error) => {
                  setShowAlert(true);
                  setAlertVariant("danger");
                  setAlertHeaderText("Fehler");
                  setAlertText(
                    "Es ist ein Fehler aufgetreten. Verscuhe es erneut oder wende dich an info@fangkarte.de"
                  );
                })
                .finally(() => setCatchYearModal(false));
            }}
          >
            Fangjahr abschließen
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  if (!user) {
    return (
      <>
        <Alert variant="danger">Kein Benutzer angegeben</Alert>
        <Link
          to={`/catch-report/${organisation.organisationToken}`}
          className="btn btn-secondary"
        >
          Zurück
        </Link>
      </>
    );
  }
  return <>{showContent()}</>;
}
