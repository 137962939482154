import React, { useEffect, useId, useState } from "react";
import { Button, Col, Form, Modal, Spinner } from "react-bootstrap";
import { NetworkCalls } from "../NetworkCalls";
import TomSelect from "tom-select";
import AppointmentCategoryDTO from "../dtos/AppointmentCategoryDTO";
import { MemberDTO } from "../dtos/MemberDTO";

export default function AddNewMemberDialog({
  show,
  onClose,
  onMemberSaved,
}: {
  show: boolean;
  onClose: () => void;
  onMemberSaved: () => void;
}) {
  const [firstname, setFirstname] = useState<string>("");
  const [lastname, setLastname] = useState<string>("");
  const [number, setNumber] = useState<string>("");
  const [memberWork, setMemberWork] = useState<boolean | undefined>(undefined);
  const [numberExists, setNumberExists] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [categories, setCategories] = useState<AppointmentCategoryDTO[]>([]);

  useEffect(() => {
    const fetchData = async () => {
      if (number) {
        try {
          await NetworkCalls.checkIfMemberExists({
            number,
            firstname,
            lastname,
            street: "",
            postalCode: "",
            city: "",
            memberWork: memberWork !== undefined ? memberWork : false,
            tags: [],
          });

          setNumberExists(true);
        } catch (e) {
          // @ts-ignore
          if (e.response?.status === 404) {
            setNumberExists(false);
          } else {
            // TODO
          }
        }
      }
      const categories = await NetworkCalls.getCategories();
      setCategories(categories);
      const tagsSelect = document.querySelector("#tags");
      // @ts-ignore
      if (tagsSelect != null && !tagsSelect.tomselect) {
        const bla = categories.map((category) => ({
          id: category.id,
          name: category.name,
        }));
        // @ts-ignore
        new TomSelect("#tags", {
          valueField: "id",
          labelField: "name",
          searchField: ["name"],
          // @ts-ignore
          options: bla,
        });
      }
    };

    fetchData().then();
  }, [number, firstname, lastname, memberWork]);

  const ModalAddNewMemberDialog = () => {
    const modalId = useId();
    return (
      <Modal show={show} onHide={onClose} id={modalId}>
        <Modal.Header closeButton>
          <Modal.Title>Neues Mitglied</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group>
              <Form.Label className="required">Vorname</Form.Label>
              <Form.Control
                id="firstname"
                disabled={loading}
                type="text"
                placeholder="Vorname"
                value={firstname}
                onChange={(e) => setFirstname(e.target.value)}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label className="required">Nachname</Form.Label>
              <Form.Control
                id="lastname"
                disabled={loading}
                type="text"
                placeholder="Nachname"
                onChange={(e) => setLastname(e.target.value)}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>
                Mitgliedsnummer {numberExists ? "(bereits vorhanden)" : ""}
              </Form.Label>
              <Col>
                <Form.Control
                  id="number"
                  disabled={loading}
                  type="text"
                  placeholder="Mitgliedsnummer"
                  value={number}
                  onChange={(e) => setNumber(e.target.value)}
                />
              </Col>
            </Form.Group>
            <Form.Group>
              <Form.Label className="required">
                Arbeitsdienstpflichtig {memberWork}
              </Form.Label>
              <Col>
                <Form.Check
                  inline
                  disabled={loading}
                  label="Ja"
                  name="member-work"
                  type={"radio"}
                  id={`inline-radio-1`}
                  onChange={(e) => setMemberWork(e.target.value === "on")}
                />
                <Form.Check
                  inline
                  disabled={loading}
                  label="Nein"
                  name="member-work"
                  type={"radio"}
                  id={`inline-radio-2`}
                  onChange={(e) => setMemberWork(!(e.target.value === "on"))}
                />
              </Col>
            </Form.Group>
            <Form.Group>
              <Col>
                <label className="form-label text-dark">Tags zuordnen</label>
                <select
                  id="tags"
                  className="form-control"
                  multiple
                  placeholder="Wähle Tags aus..."
                ></select>
              </Col>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="primary"
            disabled={
              !firstname ||
              !lastname ||
              !number ||
              memberWork === undefined ||
              numberExists ||
              loading
            }
            onClick={() => {
              setLoading(true);
              let member: MemberDTO = {
                number,
                firstname,
                lastname,
                street: "",
                postalCode: "",
                city: "",
                memberWork: memberWork !== undefined ? memberWork : false,
                tags: [],
              };
              const tagsSelect = document.querySelector("#tags");
              // @ts-ignore
              if (tagsSelect && tagsSelect["tomselect"]) {
                // @ts-ignore
                const tomselect = tagsSelect["tomselect"] as TomSelect;
                member.tags = categories.filter((category) =>
                  tomselect.items.find((id) => id === category.id)
                );
              }
              NetworkCalls.addUserMember(member)
                .then(() => {
                  onClose();
                  onMemberSaved();
                })
                .finally(() => setLoading(false));
            }}
          >
            <Spinner animation="border" role="status" hidden={!loading}>
              <span className="visually-hidden">Loading...</span>
            </Spinner>
            {loading ? "" : "Speichern"}
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  return ModalAddNewMemberDialog();
}
