import { getData } from "../utils/storage/Storage";
import { StorageKey } from "../utils/storage/Keys";
import { MyOrganisation } from "../dtos/MyOrganisation";

export function getOrganisation(): MyOrganisation {
  return JSON.parse(getData(StorageKey.SELECTED_ORGANISATION) as string);
}

export function getBearerToken(): String {
  return getData(StorageKey.TOKEN) as string;
}
