import {Elements} from "@stripe/react-stripe-js";
import React from "react";
import {useLocation} from "react-router";
import {loadStripe} from "@stripe/stripe-js";
import Checkout from "./Checkout";

export default function CheckoutOverview() {
    const {state} = useLocation();
    const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY ?? '', {stripeAccount: state.secret.stripeAccount});
    const convertTimestamp = (timestamp: number) => {
        if (timestamp === undefined) {
            return 'UNDEFINED';
        }
        return new Intl.DateTimeFormat('de-DE', {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            hour: '2-digit',
            minute: '2-digit',
        }).format(timestamp);
    };

    return (
        <Elements stripe={stripePromise} options={{clientSecret: state.secret.clientSecret}}>
            <div className="container-xl pt-5">
                <div className="card">
                    <div className="card-header">
                        <h3 className="card-title">Übersicht</h3>
                    </div>
                    <div className="card-body">
                        <div className="datagrid">
                            <div className="datagrid-item">
                                <div className="datagrid-title">Name</div>
                                <div className="datagrid-content">{state.intent.firstName} {state.intent.lastName}</div>
                            </div>
                            <div className="datagrid-item">
                                <div className="datagrid-title">Adresse</div>
                                <div
                                    className="datagrid-content">{state.intent.street}, {state.intent.postalCode} {state.intent.city}</div>
                            </div>
                            <div className="datagrid-item">
                                <div className="datagrid-title">E-Mail</div>
                                <div className="datagrid-content">{state.intent.email}</div>
                            </div>
                            <div className="datagrid-item">
                                <div className="datagrid-title">Start der Gastkarte</div>
                                <div
                                    className="datagrid-content">{convertTimestamp(state.intent.startGuestCardDate)}</div>
                            </div>
                            <div className="datagrid-item">
                                <div className="datagrid-title">Kaufpreis der Gastkarte</div>
                                <div className="datagrid-content">{state.guestCard.price} Euro</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Checkout/>
        </Elements>
    );
}
