import { MemberDTO } from "../../dtos/MemberDTO";
import {ChangeEvent, FormEvent, useEffect, useState} from "react";
import { NetworkCalls } from "../../NetworkCalls";
import { useParams } from "react-router";
import { useNavigate } from "react-router-dom";
import AppointmentCategoryDTO from "../../dtos/AppointmentCategoryDTO";
import TomSelect from "tom-select";
import DeleteDialog from "../../components/DeleteDialog";
import { Button } from "react-bootstrap";

export default function MemberDetailsEdit() {
  const params = useParams();
  const [loading, isLoading] = useState(false);
  const [showDeleteDialog, setShowDeleteDialog] = useState<boolean>(false);
  // eslint-disable-next-line
  const [error, setError] = useState(undefined);
  const [member, setMember] = useState<MemberDTO>({
    firstname: "",
    lastname: "",
    street: "",
    postalCode: "",
    city: "",
    memberWork: false,
    number: "",
    tags: [],
  });
  const [categories, setCategories] = useState<AppointmentCategoryDTO[]>([]);

  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        isLoading(true);
        const member: MemberDTO = await NetworkCalls.getMember(
          params.memberId as string
        );
        setMember(member);
        const categories = await NetworkCalls.getCategories();
        setCategories(categories);
        const tagsSelect = document.querySelector("#tags");

        // @ts-ignore
        if (tagsSelect != null && !tagsSelect.tomselect) {
          const bla = categories.map((category) => ({
            id: category.id,
            name: category.name,
          }));
          // @ts-ignore
          new TomSelect("#tags", {
            valueField: "id",
            labelField: "name",
            searchField: ["name"],
            // @ts-ignore
            options: bla,
            // @ts-ignore
            items: member.tags?.map((tag) => tag.id),
          });
        }
      } catch (error) {
        // @ts-ignore
        setError(error);
      } finally {
        isLoading(false);
      }
    };

    if (member.number || member.number === "") {
      fetchData().then();
    }
  }, [ params.memberId, member.number ]);

  const save = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    e.stopPropagation();

    isLoading(true);
    const tagsSelect = document.querySelector("#tags");
    // @ts-ignore
    if (tagsSelect && tagsSelect["tomselect"]) {
      // @ts-ignore
      const tomselect = tagsSelect["tomselect"] as TomSelect;
      member.tags = categories.filter((category) =>
        tomselect.items.find((id) => id === category.id)
      );
    }

    try {
      setMember(await NetworkCalls.updateMember(member));
    } catch (error) {
      // @ts-ignore
      setError(error);
      console.log(error);
    } finally {
      isLoading(false);
    }
  };

  const handleMemberChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.type === "checkbox" ? e.target.checked : e.target.value;
    setMember(m => {
      return {
        ...m,
        [e.target.name]: value
      }
    });
  }

  return (
    <div className="page">
      <div className="container container-tight py-4">
        <div className="card card-md">
          <div className="card-body">
            <DeleteDialog
              onDeleteConfirm={() => {
                setShowDeleteDialog(false);
                isLoading(true);
                NetworkCalls.deleteMember(member.number)
                  .then(() => navigate("/dashboard/administration/member-list"))
                  .finally(() => isLoading(false));
              }}
              title="Löschen"
              message="Datensatz wirklich löschen?"
              show={showDeleteDialog}
              onHandleClose={() => setShowDeleteDialog(false)}
            />
            <form onSubmit={save} autoComplete="off">
              <div className="mb-3">
                <label className="form-label text-dark">Vorname</label>
                <input
                  type="text"
                  className="form-control"
                  value={member.firstname}
                  placeholder="Vorname"
                  name="firstname"
                  autoComplete="off"
                  disabled={loading}
                  onChange={handleMemberChange}
                />
              </div>
              <div className="mb-3">
                <label className="form-label text-dark">Nachname</label>
                <input
                  type="text"
                  className="form-control"
                  value={member.lastname}
                  placeholder="Nachname"
                  name="lastname"
                  autoComplete="off"
                  disabled={loading}
                  onChange={handleMemberChange}
                />
              </div>
              <div className="mb-3">
                <label className="form-label text-dark">Straße</label>
                <input
                  type="text"
                  className="form-control"
                  value={member.street}
                  placeholder="Straße"
                  name="street"
                  autoComplete="off"
                  disabled={loading}
                  onChange={handleMemberChange}
                />
              </div>
              <div className="mb-3">
                <label className="form-label text-dark">Postleitzahl</label>
                <input
                  type="text"
                  className="form-control"
                  value={member.postalCode}
                  placeholder="Postleitzahl"
                  name="postalCode"
                  autoComplete="off"
                  disabled={loading}
                  onChange={handleMemberChange}
                />
              </div>
              <div className="mb-3">
                <label className="form-label text-dark">Stadt</label>
                <input
                  type="text"
                  className="form-control"
                  value={member.city}
                  placeholder="Stadt"
                  name="city"
                  autoComplete="off"
                  disabled={loading}
                  onChange={handleMemberChange}
                />
              </div>
              <div className="mb-3">
                <label className="form-check">
                  <input
                      type="checkbox"
                      className="form-check-input"
                      name="memberWork"
                      disabled={loading}
                      onChange={handleMemberChange}
                      checked={member.memberWork}
                  />
                  <span className="form-check-label text-dark">
                    Arbeitsdienstpflichtig
                  </span>
                </label>
              </div>
              <div className="mb-3">
                <label className="form-label text-dark">Tags</label>
                <select
                  id="tags"
                  className="form-control"
                  multiple
                  placeholder="Wähle Tags aus..."
                ></select>
              </div>
              <div className="form-footer">
                <button
                  type="submit"
                  className="btn btn-primary w-100"
                >
                  Speichern
                </button>
                <Button
                  variant={"danger"}
                  className="btn btn-primary w-100 mt-2"
                  onClick={() => setShowDeleteDialog(true)}
                >
                  Löschen
                </Button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
