import React, { useEffect, useId, useState } from "react";
import { Button, Form, Modal, Spinner, Table } from "react-bootstrap";
import { NetworkCalls } from "../NetworkCalls";
import LoadingPageIndicator from "./LoadingPageIndicator";
import { MemberDTO } from "../dtos/MemberDTO";
import uuid from "react-uuid";

export default function SearchMember({
  show,
  onClose,
  onSelectedMembers,
}: {
  show: boolean;
  onClose: () => void;
  onSelectedMembers: (members: MemberDTO[]) => void;
}) {
  const [loading, setLoading] = useState<boolean>(false);
  const [searchValue, setSearchValue] = useState<string | undefined>();
  const [data, setData] = useState<MemberDTO[]>();
  const [selectedData, setSelectedData] = useState<MemberDTO[]>();

  useEffect(() => {
    if (loading && searchValue !== undefined && searchValue !== "") {
      const fetchData = async () => {
        const result = await NetworkCalls.searchMember(searchValue);
        setLoading(false);
        setData(result);
        setSelectedData([]);
      };
      fetchData().then();
    }
  }, [loading, searchValue, data]);

  const search = (value: string) => {
    setSearchValue(value);
    setLoading(true);
  };

  const createTable = () => {
    return (
      <div className={"mt-2"}>
        <Table>
          <thead>
            <tr>
              <th>#</th>
              <th>Mitgliedsnummer</th>
              <th>Vorname</th>
              <th>Nachname</th>
            </tr>
          </thead>
          <tbody>
            {data?.map((elem) => {
              return (
                <tr key={uuid()} className="user-select-auto">
                  <td>
                    <Form.Check // prettier-ignore
                      type={"checkbox"}
                      id={`default-checkbox`}
                      onChange={(checkbox) => {
                        if (checkbox.target.checked) {
                          selectedData?.push(elem);
                          setSelectedData(selectedData);
                        } else {
                          selectedData?.filter((value, index, arr) => {
                            if (value.number === elem.number) {
                              arr.splice(index, 1);
                              return true;
                            }
                            return false;
                          });
                          setSelectedData(selectedData);
                        }
                      }}
                    />
                  </td>
                  <td>{elem.number}</td>
                  <td>{elem.firstname}</td>
                  <td>{elem.lastname}</td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </div>
    );
  };

  const ModalAddNewMemberDialog = () => {
    const modalId = useId();
    return (
      <Modal
        show={show}
        onHide={() => {
          onClose();
          setData([]);
          setSelectedData([]);
        }}
        id={modalId}
        scrollable={true}
      >
        <Modal.Header closeButton>
          <Modal.Title>Mitglied suchen</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group>
              <Form.Control
                id="search"
                type="text"
                placeholder="Suche"
                autoFocus={true}
                onChange={(e) => search(e.target.value)}
              />
            </Form.Group>
            {loading ? <LoadingPageIndicator /> : createTable()}
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="primary"
            onClick={() => {
              onSelectedMembers(selectedData ?? []);
              onClose();
              setData([]);
              setSelectedData([]);
            }}
          >
            <Spinner animation="border" role="status" hidden={!loading}>
              <span className="visually-hidden">Loading...</span>
            </Spinner>
            {loading ? "" : "Hinzufügen"}
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  return ModalAddNewMemberDialog();
}
