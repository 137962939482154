import {Button, Modal} from "react-bootstrap";
import React, {useEffect, useId, useState} from "react";
import {Umrandung} from "../login-area/water-administration/components/Umrandung";
import {WaterDTO} from "../dtos/WaterDTO";
import {NetworkCalls} from "../NetworkCalls";
import {MoveWaterShapeDTO} from "../dtos/MoveWaterShapeDTO";
import Select from 'react-select'

export default function MoveShapeDialog({
                                            umrandung,
                                            waterId,
                                            afterSuccess,
                                            onClose,
                                            show,
                                        }: {
    umrandung: Umrandung,
    waterId: string,
    afterSuccess: () => void,
    onClose: () => void,
    show: boolean;
}) {
    const modalId = useId();

    const [selectedWaterId, setSelectedWaterId] = useState<string | undefined>();
    const [waterList, setWaterList] = useState<WaterDTO[]>();

    useEffect(() => {
        const fetchData = async () => {
            const water = await NetworkCalls.listWater();
            setWaterList(water);
        };
        fetchData().then();
    }, []);


    const onConfirm = () => {
        let dto = {
            waterId: waterId,
            waterGroup: umrandung.key,
            targetWaterId: selectedWaterId
        } as MoveWaterShapeDTO;
        NetworkCalls.moveWaterShape(dto).then(r => {
            afterSuccess();
        });
    }

    const onCloseInternal = () => {
        onClose();
    }

    const waterOption = (water: WaterDTO) => {
        return {value: water.id, label: water.name, isDisabled: waterId === water.id};
    }

    return (
        <Modal show={show} id={modalId} onHide={onCloseInternal}>
            <Modal.Header closeButton>
                <Modal.Title>Umrandung verschieben</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                Zielgewässer auswählen
                {waterList ? (<Select onChange={selection => {
                    if (selection !== null) {
                        setSelectedWaterId(selection.value);
                    }
                }} placeholder={"Gewässerauswahl"} options={waterList.map(waterOption)}/>) : ''}

            </Modal.Body>
            <Modal.Footer>
                <Button variant="danger" onClick={onConfirm}>
                    Verschieben
                </Button>
                <Button variant="secondary" onClick={onCloseInternal}>
                    Abbrechen
                </Button>
            </Modal.Footer>
        </Modal>
    );
}
