import React, { useEffect, useId, useState } from "react";
import uuid from "react-uuid";
import { Button, Col, Form, Modal } from "react-bootstrap";
import SunEditor from "suneditor-react";
import { NetworkCalls } from "../../NetworkCalls";
import TomSelect from "tom-select";
import AppointmentCategoryDTO from "../../dtos/AppointmentCategoryDTO";
import NewsDTO from "../../dtos/NewsDTO";
import DeleteDialog from "../../components/DeleteDialog";

export default function News({ userNews }: { userNews?: NewsDTO[] }) {
  const [show, setShow] = useState<boolean>(false);
  const [showDeleteDialog, setShowDeleteDialog] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [title, setTitle] = useState<string>("");
  const [description, setDescription] = useState<string>("");
  const [categories, setCategories] = useState<AppointmentCategoryDTO[]>([]);
  const [news, setNews] = useState<NewsDTO[]>([]);
  const [selectedNews, setSelectedNews] = useState<NewsDTO | undefined>();

  useEffect(() => {
    const fetchData = async () => {
      if (!loading && categories.length === 0) {
        try {
          setLoading(true);

          const categories = await NetworkCalls.getCategories();
          setCategories(categories);
          const news = await NetworkCalls.getNews();
          setNews(news);
        } catch (error) {
          // @ts-ignore
          setError(error);
        } finally {
          setLoading(false);
        }
      }
    };

    if (userNews === undefined) {
      fetchData().then();
    } else {
      setNews(userNews);
    }
  }, [loading, userNews, categories.length]);

  const showCatchReportTable = () => {
    return (
      <table className="table table-hover">
        <thead>
          <tr key={uuid()}>
            <th scope="col">Titel</th>
            <th scope="col">Veröffentlicht am</th>
            <th scope="col">Sichtbar für</th>
          </tr>
        </thead>
        <tbody id="table-content">{createRows()}</tbody>
      </table>
    );
  };

  const editNews = (news: NewsDTO) => {
    setSelectedNews(news);
    setShow(true);
    setTitle(news.title);
    setDescription(news.description);
  };

  const createRows = () => {
    return news.map((elem) => {
      return (
        <tr
          key={uuid()}
          className="user-select-auto"
          onClick={(e) => {
            if (userNews === undefined) {
              editNews(elem);
            }
          }}
        >
          <td>{elem.title}</td>
          <td>{elem.published}</td>
          <td>{newsVisibleFor(elem)}</td>
        </tr>
      );
    });
  };

  const newsVisibleFor = (news: NewsDTO) => {
    if (news.tags === null || news.tags?.length === 0) {
      return "Alle";
    } else {
      return news.tags?.map((tag) => tag.name).join(", ");
    }
  };

  const addNews = (dto: NewsDTO) => {
    setLoading(true);
    NetworkCalls.addNews(dto).then((news) => {
      setNews(news);
      setLoading(false);
      setShow(false);
    });
  };

  const updateNews = (dto: NewsDTO) => {
    setLoading(true);
    NetworkCalls.updateNews(dto).then((news) => {
      setNews(news);
      setLoading(false);
      setShow(false);
    });
  };

  const handleClose = () => {
    setShow(false);
    setSelectedNews(undefined);
  };

  const onEntered = () => {
    const tagsSelect = document.querySelector("#tags");

    // @ts-ignore
    if (tagsSelect != null && !tagsSelect.tomselect) {
      const bla = categories.map((category) => ({
        id: category.id,
        name: category.name,
      }));
      // @ts-ignore
      new TomSelect("#tags", {
        valueField: "id",
        labelField: "name",
        searchField: ["name"],
        // @ts-ignore
        options: bla,
        // @ts-ignore
        items: selectedNews?.tags?.map((tag) => tag.id),
      });
    }
  };

  const ModalDialog = () => {
    const modalId = useId();
    return (
      <Modal
        show={show}
        onHide={handleClose}
        id={modalId}
        onEntered={onEntered}
        size={"xl"}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {title !== "" ? "News bearbeiten" : "News hinzufügen"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group>
              <Form.Label className="required">Titel</Form.Label>
              <Form.Control
                id="title"
                disabled={loading}
                type="text"
                placeholder="Titel"
                value={title}
                maxLength={255}
                onChange={(e) => setTitle(e.target.value)}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label className="required">Beschreibung</Form.Label>
              <SunEditor
                setContents={description}
                onChange={(description) => setDescription(description)}
              />
            </Form.Group>
            <Form.Group>
              <Col>
                <label className="form-label text-dark">Tags zuordnen</label>
                <select
                  id="tags"
                  className="form-control"
                  multiple
                  placeholder="Wähle Tags aus..."
                ></select>
              </Col>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant={"danger"}
            disabled={!selectedNews}
            onClick={(e) => {
              setShowDeleteDialog(true);
              setShow(false);
            }}
          >
            Löschen
          </Button>
          <Button
            variant="primary"
            disabled={title === "" || description === ""}
            onClick={() => {
              if (title !== "" && description !== "") {
                let news: NewsDTO = {
                  title,
                  description,
                  tags: [],
                };
                if (selectedNews !== undefined) {
                  news.id = selectedNews.id;
                }
                const tagsSelect = document.querySelector("#tags");
                // @ts-ignore
                if (tagsSelect && tagsSelect["tomselect"]) {
                  // @ts-ignore
                  const tomselect = tagsSelect["tomselect"] as TomSelect;
                  news.tags = categories.filter((category) =>
                    tomselect.items.find((id) => id === category.id),
                  );
                }

                if (selectedNews !== undefined) {
                  updateNews(news);
                } else {
                  addNews(news);
                }
              }
            }}
          >
            Speichern
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  const showContent = () => {
    return (
      <div className="m-5 col row justify-content-center">
        <div className="card border-0">
          <div className="card-header">
            <div className="row" style={{ minWidth: "100%" }}>
              <div className="col-5">
                {userNews === undefined ? (
                  <Button
                    variant="primary"
                    onClick={(elem) => {
                      setTitle("");
                      setDescription("");
                      setShow(true);
                    }}
                  >
                    News hinzufügen
                  </Button>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        </div>
        {showCatchReportTable()}
        {ModalDialog()}
        <DeleteDialog
          onDeleteConfirm={async () => {
            setShowDeleteDialog(false);
            if (selectedNews !== undefined) {
              setLoading(true);
              setNews(await NetworkCalls.deleteNews(selectedNews));
              setSelectedNews(undefined);
              setLoading(false);
            }
          }}
          title="Löschen"
          message="Datensatz wirklich löschen?"
          show={showDeleteDialog}
          onHandleClose={() => setShowDeleteDialog(false)}
        />
      </div>
    );
  };

  return <div>{showContent()}</div>;
}
