import { NetworkCalls } from "../../../NetworkCalls";
import DocumentDTO from "../../../dtos/DocumentDTO";

export default function DocumentUpload({
  onDocumentsUpdate,
}: {
  onDocumentsUpdate: (documents: DocumentDTO[]) => void;
}) {
  const onFileChange = (event: any) => {
    // Update the state
    const file = event.target.files[0];
    NetworkCalls.uploadDocument(file).then((documents) => {
      onDocumentsUpdate(documents);
    });
  };

  return (
    <label className="btn btn-primary ms-auto" style={{ cursor: "pointer" }}>
      <input type="file" style={{ display: "none" }} onChange={onFileChange} />
      Dokument hochladen
    </label>
  );
}
