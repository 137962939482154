import React from "react";
import WaterFishDTO from "../../dtos/WaterFishDTO";
import { UseFormRegister, UseFormSetValue } from "react-hook-form";
import { WaterDTO } from "../../dtos/WaterDTO";

export default function WaterFishOccurenceView({
  fishes,
  visible,
  register,
  setValue,
}: {
  fishes: WaterFishDTO[] | undefined;
  visible: boolean;
  register: UseFormRegister<WaterDTO>;
  setValue: UseFormSetValue<WaterDTO>;
}) {
  const fishCheckbox = (fish: WaterFishDTO, idx: number) => {
    setValue(`waterFishes.${idx}.available`, fish.available);
    setValue(`waterFishes.${idx}.orgaFishId`, fish.orgaFishId);
    return (
      <div>
        <label className={"form-check"}>
          <input
            className={"form-check-input"}
            type={"checkbox"}
            defaultChecked={fish.available}
            {...register(`waterFishes.${idx}.available`)}
          />
          <span className={"form-check-label"}>{fish.label}</span>
        </label>
      </div>
    );
  };

  return (
    <>
      {visible ? (
        <>
          {fishes
            ?.sort((a, b) => a.label.localeCompare(b.label))
            .map((fish, idx) => fishCheckbox(fish, idx))}
        </>
      ) : (
        ""
      )}
    </>
  );
}
