import GeoCoordinatesDTO from "../../../dtos/GeoCoordinatesDTO";

const GroupBy = (coordinates: GeoCoordinatesDTO[], key: string) => {
  let result = new Map<number, GeoCoordinatesDTO[]>();

  coordinates.forEach((elem: GeoCoordinatesDTO) => {
    const value = elem[key];
    const coordinates: GeoCoordinatesDTO[] = result.get(value) || [];

    coordinates.push(elem);
    result.set(value, coordinates);
  });

  return result;
};

export default GroupBy;
