import React, {useEffect, useState} from "react";
import {WaterDTO} from "../../dtos/WaterDTO";
import {NetworkCalls} from "../../NetworkCalls";
import FangkarteList from "../../components/FangkarteList";
import {FangkarteListItem} from "../../models/FangkarteListItem";
import EmptyPage from "../../components/EmptyPage";
import {Button} from "react-bootstrap";
import "suneditor/dist/css/suneditor.min.css";
import WaterContactPersonVerwaltung from "./WaterContactPersonVerwaltung";
import {Link} from "react-router-dom";
import WaterNameAndDescriptionView from "./WaterNameAndDescriptionView";
import {SubmitHandler, useForm} from "react-hook-form";
import {OrgaAndRoles} from "../../models/OrgaAndRoles";
import {getData} from "../../utils/storage/Storage";
import {StorageKey} from "../../utils/storage/Keys";
import WaterFishOccurenceView from "./WaterFishOccurenceView";
import GewaesserumrandungVerwaltung from "./GewaesserumrandungVerwaltung";
import AddNewWaterDialog from "../../components/AddNewWaterDialog";

export default function WaterListOverview() {
    const {
        control,
        register,
        unregister,
        formState: {errors},
        setValue,
        handleSubmit,
    } = useForm<WaterDTO>();
    const [waterList, setWaterList] = useState<WaterDTO[]>();
    const [selectedWater, setSelectedWater] = useState<WaterDTO | undefined>();
    const [tabIndex, setTabIndex] = useState<number>(0);
    const [showAddWaterDialog, setShowAddWaterDialog] = useState<boolean>(false);

    const onSubmit: SubmitHandler<WaterDTO> = async (data) => {
        let organisation: OrgaAndRoles = JSON.parse(
            getData(StorageKey.SELECTED_ORGANISATION) as string
        );
        data.organisationId = organisation.id;
        data.id = selectedWater?.id!;

        setSelectedWater(await NetworkCalls.persistWater(data));
        if (waterList) {
            let newArr = [...waterList];
            newArr[newArr.findIndex((water) => water.id === data.id)] = data;
            setWaterList(newArr);
        }
    };
    const inactiveClasses = "nav-link";
    const activeClasses = "nav-link active";

    useEffect(() => {
        const fetchData = async () => {
            const water = await NetworkCalls.listWater();
            setWaterList(water);
        };
        fetchData().then();
    }, []);

    const createListItems = () => {
        if (waterList) {
            return waterList?.map((dto) => {
                return {
                    title: dto.name,
                    subTitle: "",
                    originalElement: dto,
                } as FangkarteListItem;
            });
        } else {
            return [];
        }
    };

    const getWaterDetails1 = () => {
        return (
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className={"w-100"}>
                    <div className="card">
                        <div className="card-header">
                            <ul className="nav nav-tabs card-header-tabs">
                                <li className="nav-item">
                                    <Link
                                        to="#"
                                        className={tabIndex === 0 ? activeClasses : inactiveClasses}
                                        onClick={() => setTabIndex(0)}
                                    >
                                        Allgemein
                                    </Link>
                                </li>
                                <li className="nav-item">
                                    <Link
                                        to="#"
                                        className={tabIndex === 1 ? activeClasses : inactiveClasses}
                                        onClick={() => setTabIndex(1)}
                                    >
                                        Umrandungen
                                    </Link>
                                </li>
                                <li className="nav-item">
                                    <Link
                                        to="#"
                                        className={tabIndex === 2 ? activeClasses : inactiveClasses}
                                        onClick={() => setTabIndex(2)}
                                    >
                                        Kontaktpersonen
                                    </Link>
                                </li>
                                <li className="nav-item">
                                    <Link
                                        to="#"
                                        className={tabIndex === 3 ? activeClasses : inactiveClasses}
                                        onClick={() => setTabIndex(3)}
                                    >
                                        Fischvorkommen
                                    </Link>
                                </li>
                            </ul>
                        </div>
                        <div className="card-body" style={{width: "900px"}}>
                            <WaterNameAndDescriptionView
                                register={register}
                                setValue={setValue}
                                control={control}
                                visible={tabIndex === 0}
                                water={selectedWater}
                                errors={errors}
                            />
                            <GewaesserumrandungVerwaltung
                                setValue={setValue}
                                visible={tabIndex === 1}
                                waterId={selectedWater?.id}
                            />
                            <WaterContactPersonVerwaltung
                                contactPersons={selectedWater?.contactPersons}
                                visible={tabIndex === 2}
                                register={register}
                                unregister={unregister}
                                setValue={setValue}
                                errors={errors}
                            />
                            <WaterFishOccurenceView
                                fishes={selectedWater?.waterFishes}
                                visible={tabIndex === 3}
                                register={register}
                                setValue={setValue}
                            />
                        </div>
                        <div className="card-footer">
                            <div className="d-flex">
                                <Button className="mt-3" type={"submit"}>
                                    Speichern
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        );
    };

    const getContent = () => {
        return (
            <div className="page-body">
                <div className="container-xl pt-5">
                    <div className="row row-deck row-cards">
                        <div className="col-4">
                            <AddNewWaterDialog afterSuccess={() => {
                            }} onClose={() => {
                                setShowAddWaterDialog(false)
                            }} show={showAddWaterDialog}/>
                            <FangkarteList
                                onItemAdd={() => {
                                    setShowAddWaterDialog(true)
                                }}
                                addNewElement={true}
                                showSwitch={false}
                                listItems={createListItems()}
                                onItemClicked={(item) => {
                                    setSelectedWater(item);
                                }}
                                title="Gewässerübersicht"
                                exportListEnabled={false}
                            />
                        </div>
                        <div className="col-8">
                            {selectedWater ? (
                                getWaterDetails1()
                            ) : (
                                <EmptyPage
                                    addNewElement={false}
                                    text="Bitte wähle ein Gewässer aus"
                                />
                            )}
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    return getContent();
}
