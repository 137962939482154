import React, { useEffect, useId, useState } from "react";
import { Button, Col, Form, Modal, Spinner } from "react-bootstrap";
import DateRangeLitePicker from "../../components/DateRangeLitePicker";
import { NetworkCalls } from "../../NetworkCalls";
import { CatchYear } from "../../dtos/CatchYear";
import { Mode } from "../../dtos/Mode";

export default function AddEditCatchYear({
  show,
  onClose,
  editMode,
  selectedCatchYear,
}: {
  show: boolean;
  onClose: (result?: CatchYear[]) => void;
  editMode: Mode;
  selectedCatchYear?: CatchYear;
}) {
  const [startReport, setStartReport] = useState<Date | undefined>();
  const [endReport, setEndReport] = useState<Date | undefined>();
  const [startCatchYear, setStartCatchYear] = useState<Date | undefined>();
  const [endCatchYear, setEndCatchYear] = useState<Date | undefined>();
  const [name, setName] = useState<string | undefined>();
  const [loading, isLoading] = useState<boolean>(false);

  useEffect(() => {
    if (show && selectedCatchYear && !name) {
      setStartReport(new Date(selectedCatchYear.startReport));
      setEndReport(new Date(selectedCatchYear.endReport));
      setStartCatchYear(new Date(selectedCatchYear.startCatchYear));
      setEndCatchYear(new Date(selectedCatchYear.endCatchYear));
      setName(selectedCatchYear.name);
    }
  }, [show, name, selectedCatchYear]);

  const resetData = () => {
    setStartReport(undefined);
    setEndReport(undefined);
    setStartCatchYear(undefined);
    setEndCatchYear(undefined);
    setName(undefined);
  };

  function saveMyCatchYear() {
    if (startReport && endReport && startCatchYear && endCatchYear && name) {
      isLoading(true);
      if (editMode === Mode.ADD) {
        NetworkCalls.saveCatchYears({
          startReport: startReport.getTime(),
          endReport: endReport.getTime(),
          startCatchYear: startCatchYear.getTime(),
          endCatchYear: endCatchYear.getTime(),
          name: name,
        }).then((result) => {
          isLoading(false);
          onClose(result);
          resetData();
        });
      } else if (editMode === Mode.EDIT && selectedCatchYear) {
        NetworkCalls.updateCatchYear({
          startReport: startReport.getTime(),
          endReport: endReport.getTime(),
          startCatchYear: startCatchYear.getTime(),
          endCatchYear: endCatchYear.getTime(),
          name: name,
          id: selectedCatchYear.id,
        }).then((result) => {
          isLoading(false);
          onClose(result);
          resetData();
        });
      }
    }
  }

  const AddEditItem = () => {
    const modalId = useId();
    return (
      <Modal show={show} onHide={onClose} id={modalId}>
        <Modal.Header closeButton>
          <Modal.Title>
            {editMode === Mode.ADD
              ? "Neuen Datensatz anlegen"
              : "Datensatz bearbeiten"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group>
              <Form.Label className="required">
                Zeitraum, in dem die Angler ihr Fangjahr abschließen dürfen
              </Form.Label>
              <DateRangeLitePicker
                numberOfColumns={2}
                numberOfMonths={2}
                singleMode={false}
                disabled={loading}
                onDateSelected={(start, end) => {
                  setStartReport(start);
                  setEndReport(end);
                }}
                startDate={startReport ? startReport : undefined}
                endDate={endReport ? endReport : undefined}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label className="required">
                Zeitraum für das Fangjahr (In der Regel das Kalenderjahr)
              </Form.Label>
              <DateRangeLitePicker
                numberOfColumns={2}
                numberOfMonths={2}
                disabled={loading}
                singleMode={false}
                onDateSelected={(start, end) => {
                  setStartCatchYear(start);
                  setEndCatchYear(end);
                }}
                startDate={startCatchYear ? startCatchYear : undefined}
                endDate={endCatchYear ? endCatchYear : undefined}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label className="required">
                Name für den Eintrag bsp. Kalenderjahr 2023
              </Form.Label>
              <Col>
                <Form.Control
                  id="nu"
                  type="text"
                  placeholder="Name vergeben"
                  value={name}
                  disabled={loading}
                  onChange={(e) => {
                    setName(e.target.value);
                  }}
                />
              </Col>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="primary"
            onClick={saveMyCatchYear}
            disabled={
              !startReport ||
              !endReport ||
              !startCatchYear ||
              !endCatchYear ||
              !name
            }
          >
            <Spinner animation="border" role="status" hidden={!loading}>
              <span className="visually-hidden">bitte warten...</span>
            </Spinner>
            {loading ? "" : "Speichern"}
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  return AddEditItem();
}
