import React, { useEffect, useState } from "react";
import NewsDTO from "../../../dtos/NewsDTO";
import { NetworkCalls } from "../../../NetworkCalls";
import { MemberDTO } from "../../../dtos/MemberDTO";
import News from "../News";

export default function ShowNewsForMember({ member }: { member?: MemberDTO }) {
  const [news, setNews] = useState<NewsDTO[]>([]);

  useEffect(() => {
    const fetchData = async () => {
      if (member) {
        setNews(await NetworkCalls.getMemberNews(member));
      } else {
        setNews([]);
      }
    };
    fetchData().then();
  }, [member]);

  return <News userNews={news} />;
}
