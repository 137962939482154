import React, {useEffect, useState} from "react";
import {NetworkCalls} from "../../NetworkCalls";
import {Link} from "react-router-dom";
import {Button, FormCheck} from "react-bootstrap";
import {useSelectedOrganisation} from "../../custom-hooks/useSelectedOrganisation";
import LoadingPageIndicator from "../../components/LoadingPageIndicator";
import HandleHttpResponses from "../../components/NetworkErrorHandling";
import {AxiosError} from "axios";
import {GuestCardDTO} from "../../dtos/GuestCardDTO";
import DeleteDialog from "../../components/DeleteDialog";

export default function GuestCardAdmin() {

    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<AxiosError | undefined>();

    const [guestCards, setGuestCards] = useState<GuestCardDTO[]>([]);

    const [showDeleteDialog, setShowDeleteDialog] = useState<boolean>(false);
    const [selectedGuestCard, setSelectedGuestCard] = useState<string | undefined>(undefined);

    const selectedOrganisation = useSelectedOrganisation();

    useEffect(() => {
        if (!selectedOrganisation) {
            return;
        }

        setError(undefined);
        setLoading(true);
        const fetch = async () => {
            try {
                let result = await NetworkCalls.listGuestCardsByOrganisationId();
                result = result.sort((a, b) => a.description.localeCompare(b.description));
                setGuestCards(result);
            } catch (error: any) {
                console.error(error);
                if (error as AxiosError)
                    setError(error);
            } finally {
                setLoading(false)
            }
        }

        fetch().then();
    }, [selectedOrganisation]);

    const renderPrice = (price: number) => {
        return <>{price.toFixed(2)} €</>;
    }

    const deleteCard = (id: string) => {
        setSelectedGuestCard(id);
        setShowDeleteDialog(true);
    }

    const renderGuestCardRow = (card: GuestCardDTO) => {
        return <>
            <tr key={`gc-${card.id}`}>
                <td>{card.description}</td>
                <td>{renderPrice(card.price)}</td>
                <td><FormCheck checked={card.active} readOnly={true}></FormCheck></td>
                <td className='space-x-3'>
                    <Link to={`edit/${card.id}`} className='btn btn-outline-primary'>
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="icon icon-tabler icon-tabler-pencil"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            strokeWidth="2"
                            stroke="currentColor"
                            fill="none"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        >
                            <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                            <path d="M4 20h4l10.5 -10.5a2.828 2.828 0 1 0 -4 -4l-10.5 10.5v4"></path>
                            <path d="M13.5 6.5l4 4"></path>
                        </svg>
                        Bearbeiten
                    </Link>
                    <Button variant='outline-danger' onClick={() => deleteCard(card.id)}>
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="icon icon-tabler icon-tabler-trash"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            strokeWidth="2"
                            stroke="currentColor"
                            fill="none"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        >
                            <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                            <path d="M4 7l16 0"></path>
                            <path d="M10 11l0 6"></path>
                            <path d="M14 11l0 6"></path>
                            <path d="M5 7l1 12a2 2 0 0 0 2 2h8a2 2 0 0 0 2 -2l1 -12"></path>
                            <path d="M9 7v-3a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v3"></path>
                        </svg>
                        Löschen
                    </Button>
                </td>
            </tr>
        </>;
    }

    const showGuestCards = () => {
        if (guestCards.length <= 0) {
            return <>Kein Gastkarten</>;
        } else {
            return <>
                <div>
                    <div className="m-5 col row justify-content-center">

                        <table className="table table-hover" key={'gc-list'}>
                            <thead>
                            <tr>
                                <th scope='col'>Name</th>
                                <th scope='col'>Preis</th>
                                <th scope='col'>Aktiv?</th>
                                <th scope='col'>Aktionen</th>
                            </tr>
                            </thead>
                            <tbody>
                            {guestCards.map(e => renderGuestCardRow(e))}
                            </tbody>
                        </table>
                    </div>
                    <DeleteDialog
                        onDeleteConfirm={() => {
                            setShowDeleteDialog(false);
                            if (selectedGuestCard) {
                                setLoading(true);
                                NetworkCalls
                                    .deleteGuestCard(selectedGuestCard)
                                    .then(() => {
                                        setGuestCards(list => list.filter(i => i.id !== selectedGuestCard))
                                        setShowDeleteDialog(false);
                                    })
                                    .catch(setError)
                                    .finally(() => {
                                        setLoading(false);
                                        setSelectedGuestCard(undefined);
                                    });
                            }
                        }}
                        title="Löschen"
                        message="Datensatz wirklich löschen?"
                        show={showDeleteDialog}
                        onHandleClose={() => setShowDeleteDialog(false)}
                    />
                </div>
            </>;
        }
    }

    const showContent = () => {
        return (
            <div>
                <div className="m-5 col row justify-content-center">
                    <div className="card border-0">
                        <div className="card-header">
                            <h1>Übersicht Gastkarten</h1>
                        </div>
                        <div className="card-body border-bottom">
                            <div className="d-flex">
                                <div className="ms-auto text-secondary">
                                    <div className="ms-2 d-inline-block">
                                        <Link to={'add'} className='btn btn-primary'>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                                 viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
                                                 stroke-linecap="round" stroke-linejoin="round"
                                                 className="icon icon-tabler icons-tabler-outline icon-tabler-file-plus">
                                                <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                                <path d="M14 3v4a1 1 0 0 0 1 1h4"/>
                                                <path
                                                    d="M17 21h-10a2 2 0 0 1 -2 -2v-14a2 2 0 0 1 2 -2h7l5 5v11a2 2 0 0 1 -2 2z"/>
                                                <path d="M12 11l0 6"/>
                                                <path d="M9 14l6 0"/>
                                            </svg>
                                            Neue Gastkarte anlegen
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {showGuestCards()}
                    </div>
                </div>
            </div>
        );
    }

    return <>
        <div>
            {loading ? (
                <LoadingPageIndicator/>
            ) : error ? (
                HandleHttpResponses(error)
            ) : (
                showContent()
            )}
        </div>
    </>

}