import React, { useEffect, useState } from "react";
import { NetworkCalls } from "../../NetworkCalls";
import LoadingPageIndicator from "../../components/LoadingPageIndicator";
import uuid from "react-uuid";
import HandleHttpResponses from "../../components/NetworkErrorHandling";
import axios, { AxiosError } from "axios";
import { CatchReport } from "../../dtos/CatchReport";
import TimestampFormat from "../../utils/TimestampFormat";
import { OrgaAndRoles } from "../../models/OrgaAndRoles";
import { getData } from "../../utils/storage/Storage";
import { StorageKey } from "../../utils/storage/Keys";
import { Button } from "react-bootstrap";
import DeleteDialog from "../../components/DeleteDialog";

export default function CatchReports() {
  const [loading, isLoading] = useState<boolean>(false);
  const [catchReport, setCatchReport] = useState<CatchReport[]>([]);
  const [error, setError] = useState<AxiosError>();
  const [selectedYear, setSelectedYear] = useState<number>(-1);
  const [years, setYears] = useState<number[]>([]);
  const [showDeleteDialog, setShowDeleteDialog] = useState<boolean>(false);
  const [selectedCatchReport, setSelectedCatchReport] = useState<
    CatchReport | undefined
  >(undefined);

  useEffect(() => {
    isLoading(true);
    NetworkCalls.getCatchReportYears()
      .then((result) => {
        const years = result.sort(sortByYear);
        setYears(years);
        setSelectedYear(years[0]);
      })
      .finally(() => isLoading(false));
  }, []);

  useEffect(() => {
    if (selectedYear > 2010) {
      isLoading(true);
      NetworkCalls.getCatchReports(selectedYear)
        .then((result) => {
          setCatchReport(result);
        })
        .catch((error) => {
          setError(error);
        })
        .finally(() => isLoading(false));
    }
  }, [selectedYear]);

  function sortByYear(a: number, b: number) {
    return b - a;
  }

  const options = years.map((year) => {
    return (
      <option key={year} value={year}>
        {year}
      </option>
    );
  });

  function showContent() {
    let selectedOrganisation: OrgaAndRoles = JSON.parse(
      getData(StorageKey.SELECTED_ORGANISATION) as string
    );
    return (
      <div className="m-5 col row justify-content-center">
        <div className="card border-0">
          <div className="card-header">
            <div className="row" style={{ minWidth: "100%" }}>
              <div className="col-1">
                <div className="form-inline">
                  <select
                    id="yearControl"
                    title="Jahr"
                    className="form-control"
                    onChange={(elem) =>
                      setSelectedYear(Number(elem.target.value))
                    }
                    defaultValue={selectedYear}
                  >
                    {options}
                  </select>
                </div>
              </div>
              <div className="col-5">
                <h4>Excel Download</h4>
                <a
                  href={`${axios.defaults.baseURL}/catch/getCatchReportExcel?organisationId=${selectedOrganisation.id}&year=${selectedYear}`}
                  className="link-secondary"
                  title="Download"
                  data-bs-placement="top"
                  data-bs-toggle="tooltip"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="icon icon-tabler icon-tabler-cloud-download"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    strokeWidth="2"
                    stroke="currentColor"
                    fill="none"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  >
                    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                    <path d="M19 18a3.5 3.5 0 0 0 0 -7h-1a5 4.5 0 0 0 -11 -2a4.6 4.4 0 0 0 -2.1 8.4"></path>
                    <path d="M12 13l0 9"></path>
                    <path d="M9 19l3 3l3 -3"></path>
                  </svg>
                </a>
              </div>
              <div
                className="col-5"
                hidden={
                  !(
                    selectedOrganisation.id ===
                    "d225ca9958a567ee0158abb195000023"
                  )
                }
              >
                <h4>iSL CSV Datei Download</h4>
                <a
                  href={`${axios.defaults.baseURL}/usercatch/${selectedOrganisation.id}/catch-report-list/isl-export-csv?year=${selectedYear}`}
                  className="link-secondary"
                  title="Download"
                  data-bs-placement="top"
                  data-bs-toggle="tooltip"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="icon icon-tabler icon-tabler-cloud-download"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    strokeWidth="2"
                    stroke="currentColor"
                    fill="none"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  >
                    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                    <path d="M19 18a3.5 3.5 0 0 0 0 -7h-1a5 4.5 0 0 0 -11 -2a4.6 4.4 0 0 0 -2.1 8.4"></path>
                    <path d="M12 13l0 9"></path>
                    <path d="M9 19l3 3l3 -3"></path>
                  </svg>
                </a>
              </div>
            </div>
          </div>
          {showCatchReportTable()}
        </div>
        <DeleteDialog
          onDeleteConfirm={async () => {
            setShowDeleteDialog(false);
            if (selectedCatchReport !== undefined) {
              isLoading(true);
              await NetworkCalls.deleteCatchReport(selectedCatchReport);
              setCatchReport(await NetworkCalls.getCatchReports(selectedYear));
              setSelectedCatchReport(undefined);
              isLoading(false);
            }
          }}
          title="Löschen"
          message="Datensatz wirklich löschen?"
          show={showDeleteDialog}
          onHandleClose={() => setShowDeleteDialog(false)}
        />
      </div>
    );
  }

  function showCatchReportTable() {
    return (
      <table className="table table-hover">
        <thead>
          <tr key={uuid()}>
            <th scope="col">Mitgliedsnummer</th>
            <th scope="col">Gewässer</th>
            <th scope="col">Fisch</th>
            <th scope="col">Zeitpunkt</th>
            <th scope="col">Länge (cm)</th>
            <th scope="col">Gewicht (gramm)</th>
            <th scope="col">Aktionen</th>
          </tr>
        </thead>
        <tbody id="table-content">{createRows()}</tbody>
      </table>
    );
  }

  function createRows() {
    return catchReport.map((elem) => {
      return (
        <tr key={uuid()} className="user-select-auto">
          <td>{elem.memberId ? elem.memberId : elem.guestCardTransaction}</td>
          <td> {elem.waterName}</td>
          <td> {elem.fishName}</td>
          <td>{TimestampFormat.toDateTimeReadableFormat(elem.catchTime)}</td>
          <td>{elem.fishLength}</td>
          <td>{elem.fishWeight}</td>
          <td>
            <Button
              variant={"danger"}
              onClick={async (e) => {
                setShowDeleteDialog(true);
                setSelectedCatchReport(elem);
              }}
            >
              Löschen
            </Button>
          </td>
        </tr>
      );
    });
  }

  return (
    <div>
      {loading ? (
        <LoadingPageIndicator />
      ) : error ? (
        HandleHttpResponses(error)
      ) : (
        showContent()
      )}
    </div>
  );
}
