import React, { useEffect, useState } from "react";
import { NetworkCalls } from "../../NetworkCalls";
import AppointmentCategoryDTO from "../../dtos/AppointmentCategoryDTO";
import DeleteDialog from "../../components/DeleteDialog";
import { Button } from "react-bootstrap";

export default function AppointmentCategories() {
  const [category, setCategory] = useState<string>("");
  const [showDeleteDialog, setShowDeleteDialog] = useState<boolean>(false);
  const [categories, setCategories] = useState<AppointmentCategoryDTO[]>([]);
  const [selectedCategoryToDelete, setSelectedCategoryToDelete] = useState<
    AppointmentCategoryDTO | undefined
  >();
  useEffect(() => {
    const fetchData = async () => {
      setCategories(await NetworkCalls.getCategories());
    };

    fetchData().then();
  }, []);

  const renderContent = () => {
    return (
      <div className="page-body">
        <div className="container-xl">
          <div className="row row-cards">
            <div className="col 12">
              <div className="card-header">
                <h4 className="card-title">
                  Kategorien (Kategorien für "Appointments")
                </h4>
              </div>
              <div className="card-body">
                <div className="row">
                  <div className="col-12">
                    <div className="mb-3">
                      <div className="card">
                        <div className="card-header">
                          <h3 className="card-title">
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Neue Kategorie"
                              value={category}
                              onChange={(event) => {
                                setCategory(event.target.value);
                              }}
                              onKeyUp={(e) => {
                                console.log(e.type);
                                if (e.key === "Enter") {
                                  NetworkCalls.saveCategory({
                                    id: "",
                                    name: category,
                                  }).then((result) => setCategories(result));
                                }
                              }}
                            />
                          </h3>
                        </div>
                        {categories.map((category) => {
                          return (
                            <div className="list-group list-group-flush">
                              <Button
                                className="list-group-item list-group-item-action active"
                                aria-current="true"
                                onClick={(elem) => {
                                  setShowDeleteDialog(true);
                                  setSelectedCategoryToDelete(category);
                                }}
                              >
                                {category.name}
                              </Button>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <DeleteDialog
          onDeleteConfirm={async () => {
            setShowDeleteDialog(false);
            if (selectedCategoryToDelete) {
              setCategories(
                await NetworkCalls.deleteCategory(selectedCategoryToDelete)
              );
            }

            setSelectedCategoryToDelete(undefined);
          }}
          title="Löschen"
          message="Datensatz wirklich löschen?"
          show={showDeleteDialog}
          onHandleClose={() => setShowDeleteDialog(false)}
        />
      </div>
    );
  };

  return renderContent();
}
