import { useParams } from "react-router";
import React, { useEffect, useState } from "react";
import { NetworkCalls } from "../../NetworkCalls";
import { MemberDTO } from "../../dtos/MemberDTO";
import { AxiosError } from "axios";
import LoadingPageIndicator from "../../components/LoadingPageIndicator";
import HandleHttpResponses from "../../components/NetworkErrorHandling";
import { Tabs } from "react-bootstrap";
import { OrgaAndRoles } from "../../models/OrgaAndRoles";
import { getData } from "../../utils/storage/Storage";
import { StorageKey } from "../../utils/storage/Keys";
import LoadAndShowCatchReports from "../../catch-report/LoadAndShowCatchReports";
import Tab from "react-bootstrap/Tab";
import ShowNewsForMember from "./user/ShowNewsForMember";

export default function MemberDetails() {
  const params = useParams();
  const [loading, isLoading] = useState<boolean>(false);
  const [member, setMember] = useState<MemberDTO>();
  const [error, setError] = useState<AxiosError>();
  const [organisation, setOrganisation] = useState<OrgaAndRoles | undefined>();

  useEffect(() => {
    isLoading(true);
    setOrganisation(
      JSON.parse(getData(StorageKey.SELECTED_ORGANISATION) as string)
    );
    NetworkCalls.getMember(params.memberId as string)
      .then((result) => {
        setMember(result);
        isLoading(false);
      })
      .catch((error) => {
        isLoading(false);
        setError(error);
      });
  }, [params.memberId]);

  function showContent() {
    return (
      <div className="page-body">
        <div className="container-xl">
          <div className="card">
            <div className="card-header">
              <h3 className="card-title">Mitglied Details</h3>
            </div>
            <div className="card-body">
              <div className="datagrid">
                <div className="datagrid-item">
                  <div className="datagrid-title">Name</div>
                  <div className="datagrid-content">
                    {member?.firstname} {member?.lastname}
                  </div>
                </div>
                <div className="datagrid-item">
                  <div className="datagrid-title">Adresse</div>
                  <div className="datagrid-content">
                    {member?.street} {member?.postalCode} {member?.city}
                  </div>
                </div>
                <div className="datagrid-item">
                  <div className="datagrid-title">Mitgliedsnummer</div>
                  <div className="datagrid-content">{member?.number}</div>
                </div>
                <div className="datagrid-item">
                  <div className="datagrid-title">Arbeitsdienst</div>
                  {memberWork(member?.memberWork)}
                </div>
                <div className="datagrid-item">
                  <div className="datagrid-title">Zugewiesene Tags</div>
                  {member?.tags?.map((tag) => tag.name).join(", ")}
                </div>
              </div>
            </div>
          </div>
          {example()}
        </div>
      </div>
    );
  }

  function memberWork(memberWork: boolean | undefined) {
    if (memberWork) {
      return <span className="status status-green">Ja</span>;
    } else {
      return <span className="status status-red">Nein</span>;
    }
  }

  const handleSelect = (eventKey: string | null) => {};
  const example = () => {
    return (
      <Tabs
        className="mb-3"
        defaultActiveKey={"fangmeldungen"}
        onSelect={handleSelect}
        style={{ marginTop: "1rem" }}
      >
        <Tab eventKey={"fangmeldungen"} title={"Fangmeldungen"}>
          <LoadAndShowCatchReports
            organisationToken={organisation?.organisationToken}
            memberNumber={member?.number}
            lastName={member?.lastname}
          />
        </Tab>
        <Tab eventKey={"news"} title={"News"}>
          <ShowNewsForMember member={member} />
        </Tab>
      </Tabs>
    );
  };

  return (
    <div>
      {loading ? (
        <LoadingPageIndicator />
      ) : error ? (
        HandleHttpResponses(error)
      ) : (
        showContent()
      )}
    </div>
  );
}
