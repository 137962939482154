export default class TimestampFormat {
  static toDateTimeReadableFormat(timestamp: number) {
    return new Intl.DateTimeFormat(
      "de",
      TimestampFormat.getDateTimeOptions()
    ).format(new Date(timestamp));
  }

  static toDateOnlyReadableFormat(timestamp: number) {
    return new Intl.DateTimeFormat(
      "de",
      TimestampFormat.getDateOnlyOptions()
    ).format(new Date(timestamp));
  }

  static toDateValueFormat(date: Date) {
    const month = TimestampFormat.pad(String(date.getMonth() + 1), 2);
    return `${date.getFullYear()}-${month}-${date.getDate()}`;
  }

  private static pad(num: string, size: number) {
    while (num.length < size) num = "0" + num;
    return num;
  }

  private static getDateTimeOptions() {
    return {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
      hour12: false,
      timeZone: "Europe/Berlin",
    } as Intl.DateTimeFormatOptions;
  }

  private static getDateOnlyOptions() {
    return {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour12: false,
      timeZone: "Europe/Berlin",
    } as Intl.DateTimeFormatOptions;
  }
}
