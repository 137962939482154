import { useEffect, useState } from "react";
import { NetworkCalls } from "../../NetworkCalls";
import { Features } from "../../dtos/Features";
import FeatureDTO from "../../dtos/FeatureDTO";

export default function FeatureConfiguration() {
  const [features, setFeatures] = useState<FeatureDTO[]>();
  useEffect(() => {
    const fetchData = async () => {
      let result = await NetworkCalls.getFeatures();

      let featuresToRender: FeatureDTO[] = [];

      Object.keys(Features).forEach((key) => {
        let myKey: Features = key as Features;
        let res = result.find((elem) => elem.feature === myKey);

        featuresToRender.push({
          feature: myKey,
          enabled: res?.enabled ?? false,
        });
      });

      setFeatures(featuresToRender);
    };

    fetchData().catch(console.error);
  }, []);

  // @ts-ignore
  const handleChange = (event) => {
    if (event) {
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      const id = event.target.id;
      features
        ?.filter((feature) => feature.feature === id)
        .forEach((feature) => {
          NetworkCalls.setFeature({
            feature: Features[feature.feature],
            enabled: !feature.enabled,
          }).then((result) => {
            setFeatures(result);
          });
        });
      console.log(JSON.stringify(features));
    }
  };

  const featureEntry = features?.map((elem) => {
    return (
      <div>
        <label className="row">
          <span className="col text-primary">${elem.feature.toString()}</span>
          <span className="col-auto">
            <label className="form-check form-check-single form-switch">
              <input
                className="form-check-input"
                type="checkbox"
                id={elem.feature}
                onChange={handleChange}
                checked={elem.enabled}
              />
            </label>
          </span>
        </label>
      </div>
    );
  });

  const renderContent = () => {
    return (
      <div className="page-body">
        <div className="container-xl">
          <div className="row row-cards">
            <div className="col 12">
              <div className="card-header">
                <h4 className="card-title">Featurezugänge (STILL TODO)</h4>
              </div>
              <div className="card-body">
                <div className="row">
                  <div className="col-12">
                    <div className="mb-3">
                      <div className="divide-y">{featureEntry}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return <div>{features ? renderContent() : ""}</div>;
}
