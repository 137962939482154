import { useState } from "react";
import { storeData } from "./utils/storage/Storage";
import { useNavigate } from "react-router-dom";
import { StorageKey } from "./utils/storage/Keys";
import { NetworkCalls } from "./NetworkCalls";
import { NetworkErrorHandling } from "./components/NetworkErrorHandling";
import { OrgaAndRoles } from "./models/OrgaAndRoles";
import { Jwt } from "./dtos/Jwt";
import { AxiosError } from "axios";

export default function Auth() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  // eslint-disable-next-line
  const [error, setError] = useState(undefined);
  const navigate = useNavigate();

  async function login() {
    setLoading(true);
    try {
      let result = await NetworkCalls.login(email, password);
      handleLoginSuccess(result);
    } catch (error) {
      NetworkErrorHandling.handle(error as AxiosError);
      // TODO generisch
      //setError(HttpErrorHandler.extractError(error));
    } finally {
      setLoading(false);
    }
  }

  const handleLoginSuccess = (result: Jwt) => {
    storeData(StorageKey.TOKEN, result.accessToken);
    const map1 = new Map<string, OrgaAndRoles>();

    result.role.forEach((elem) => {
      if (!map1.get(elem.organisationId)) {
        map1.set(elem.organisationId, {
          id: "",
          organisationName: "",
          organisationToken: "",
          roles: [],
        });
      }

      const orgaObject = map1.get(elem.organisationId);
      if (orgaObject) {
        orgaObject.id = elem.organisationId;
        orgaObject.organisationName = elem.organisationName;
        orgaObject.organisationToken = elem.organisationToken;
        orgaObject.roles.push(elem.userRole);
        map1.set(elem.organisationId, orgaObject);
      } else {
        console.error("orgaObject is undefined");
      }
    });

    const myOrganisations = [];
    // @ts-ignore
    // eslint-disable-next-line
    for (let [key, value] of map1) {
      myOrganisations.push(value);
    }

    storeData(StorageKey.ORGA_AND_ROLES, JSON.stringify(myOrganisations));
    if (myOrganisations.length > 1) {
      setTimeout(() => {
        navigate("/select-organisation");
      }, 1);
    } else {
      storeData(
        StorageKey.SELECTED_ORGANISATION,
        JSON.stringify(myOrganisations[0])
      );
      navigate("/dashboard");
    }
  };

  // @ts-ignore // eslint-disable-next-line
  const handleEmailChange = (e) => {
    e.preventDefault(); // prevent the default action
    setEmail(e.target.value); // set name to e.target.value (event)
  };

  // @ts-ignore // eslint-disable-next-line
  const handlePasswordChange = (e) => {
    e.preventDefault(); // prevent the default action
    setPassword(e.target.value); // set name to e.target.value (event)
  };

  return (
    <div className="Auth-form-container App">
      <div className="Auth-form">
        <div className="Auth-form-content">
          <h3 className="Auth-form-title text-color-sidebar-primary">
            Anmelden
          </h3>
          <span className={!error ? "d-none" : "d-block badge-error"}>
            {error}
          </span>
          <div className="form-group mt-3">
            <label>E-Mail Adresse</label>
            <input
              type="email"
              className="form-control mt-1"
              placeholder="E-Mail"
              value={email}
              onChange={handleEmailChange}
              disabled={loading}
            />
          </div>
          <div className="form-group mt-3">
            <label>Passwort</label>
            <input
              type="password"
              className="form-control mt-1"
              placeholder="Passwort"
              value={password}
              onChange={handlePasswordChange}
              disabled={loading}
            />
          </div>
          <div className={loading ? "d-none" : "d-block d-grid gap-2 mt-3"}>
            <button
              type="submit"
              className="btn btn-primary fk-color"
              onClick={login}
            >
              Anmelden
            </button>
          </div>
          <div className={!loading ? "d-none" : " d-block d-grid gap-2 mt-3"}>
            <button className="btn btn-primary fk-color" type="button" disabled>
              <span
                className="spinner-grow spinner-grow-sm"
                role="status"
                aria-hidden="true"
              ></span>
              Bitte warten...
            </button>
          </div>
          <p className="forgot-password text-right mt-2">
            <button className="btn btn-link" onClick={() => navigate('/forgot-password')}>Passwort vergessen?</button>
          </p>
        </div>
      </div>
    </div>
  );
}
