import React, { useEffect, useState } from "react";
import { NetworkCalls } from "../../NetworkCalls";
import { useParams } from "react-router";
import { AxiosError } from "axios";
import { Inspection } from "../../dtos/Inspection";
import LoadingPageIndicator from "../../components/LoadingPageIndicator";
import HandleHttpResponses from "../../components/NetworkErrorHandling";
import TimestampFormat from "../../utils/TimestampFormat";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import { getOrganisation } from "../../service/LoadStorageData";
import { Link } from "react-router-dom";

export default function InspectionDetail() {
  const params = useParams();
  const [loading, isLoading] = useState<boolean>(false);
  const [inspection, setInspection] = useState<Inspection>();
  const [error, setError] = useState<AxiosError>();

  useEffect(() => {
    isLoading(true);
    NetworkCalls.getInspection(params.id as string)
      .then((result) => {
        setInspection(result);
        isLoading(false);
      })
      .catch((error) => {
        isLoading(false);
        setError(error);
      });
  }, [params.id]);

  function showContent() {
    return (
      <div className="page-body">
        <div className="container-xl">
          <div className="card">
            <div className="card-header">
              <h3 className="card-title">Kontrolle Details</h3>
            </div>
            <div className="card-body">
              {modalDialog()}
              <div className="datagrid">
                <div className="datagrid-item">
                  <div className="datagrid-title">Aufseher</div>
                  <div className="datagrid-content">
                    {inspection?.firstName} {inspection?.lastName}
                  </div>
                </div>
                <div className="datagrid-item">
                  <div className="datagrid-title">Kontrollierte Person</div>
                  <div className="datagrid-content">
                    {inspection?.userNumber
                      ? inspection?.userNumber
                      : inspection?.userNumber}
                  </div>
                </div>
                <div className="datagrid-item">
                  <div className="datagrid-title">Gewässer</div>
                  <div className="datagrid-content">
                    {inspection?.water.waterName}
                  </div>
                </div>
                <div className="datagrid-item">
                  <div className="datagrid-title">Datum</div>
                  <div className="datagrid-content">
                    {inspection?.inspectionTime
                      ? TimestampFormat.toDateTimeReadableFormat(
                          inspection?.inspectionTime
                        )
                      : ""}
                  </div>
                </div>
                {showAttachment(inspection!)}
                <div className="datagrid-item">
                  <div className="datagrid-title">Bemerkung</div>
                  <div className="datagrid-content">
                    {inspection?.description}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  const modalDialog = () => {
    return (
      <div className="modal" id="image-carousel-modal">
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Fangjahr hinzufügen</h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              />
            </div>
            {prepareImages()}
            <div className="modal-footer">
              <button
                type="button"
                className="btn me-auto"
                data-bs-dismiss="modal"
              >
                Schließen
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  };

  function showAttachment(inspection: Inspection) {
    if (inspection?.hasPhoto) {
      return (
        <div className="datagrid-item">
          <div className="datagrid-title">Fotos</div>
          <Link
            to="#"
            data-bs-toggle="modal"
            data-bs-target="#image-carousel-modal"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="icon icon-tabler icon-tabler-files"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              strokeWidth="2"
              stroke="currentColor"
              fill="none"
              strokeLinecap="round"
              strokeLinejoin="round"
            >
              <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
              <path d="M15 3v4a1 1 0 0 0 1 1h4"></path>
              <path d="M18 17h-7a2 2 0 0 1 -2 -2v-10a2 2 0 0 1 2 -2h4l5 5v7a2 2 0 0 1 -2 2z"></path>
              <path d="M16 17v2a2 2 0 0 1 -2 2h-7a2 2 0 0 1 -2 -2v-10a2 2 0 0 1 2 -2h2"></path>
            </svg>
          </Link>
        </div>
      );
    } else {
      return;
    }
  }

  function prepareImages() {
    return (
      <Carousel showArrows={true}>
        {inspection?.photos.map((photo) => (
          <div>
            <img
              src={`${
                process.env.REACT_APP_BASE_URL
              }/inspection/list-inspections/${
                inspection?.id
              }/image/${photo}?organisationId=${getOrganisation().id}`}
              alt="Foto"
            />
          </div>
        ))}
      </Carousel>
    );
  }

  return (
    <div>
      {loading ? (
        <LoadingPageIndicator />
      ) : error ? (
        HandleHttpResponses(error)
      ) : (
        showContent()
      )}
    </div>
  );
}
