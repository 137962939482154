import React, {useState} from "react";
import {Button, Card} from "react-bootstrap";
import {useLoaderData} from "react-router-dom";
import {Organisation} from "../dtos/Organisation";
import {useParams} from "react-router";
import CatchReportGuestInputForm from "./CatchReportGuestInputForm";
import CatchReportMemberInputForm from "./CatchReportMemberInputForm";

enum ShowCatchReportInputs {
    Initial,
    Guest,
    Member
}

export default function CatchReport() {
    const {organisationToken} = useParams();
    const organisation = useLoaderData() as Organisation;
    const [showState, setShowState] = useState<ShowCatchReportInputs>(ShowCatchReportInputs.Initial);

    function renderShowStateName() {
        switch (showState) {
            case ShowCatchReportInputs.Initial:
                return <></>;
            case ShowCatchReportInputs.Guest:
                return <> - Gastangler</>;
            case ShowCatchReportInputs.Member:
                return <> - Vereinsmitglied</>;
        }
    }

    function renderH1() {
        return <>
            Fangrückmeldung{organisation ? ` - ${organisation.organisationName}` : ''}{renderShowStateName()}
        </>;
    }

    function renderH2() {
        if (!organisation)
            return <>Bitte nutzen Sie den vom Verein bereitgestellten Link.</>;

        switch (showState) {
            case ShowCatchReportInputs.Initial:
                return <>Wie möchten Sie Ihre Fänge melden?</>;
            case ShowCatchReportInputs.Guest:
                return <>Bitte geben Sie Ihre Gastkartennummer ein</>;
            case ShowCatchReportInputs.Member:
                return <>Bitte geben Sie Ihre Mitgliedsdaten ein</>;
        }
    }

    function renderBackButton() {
        if (showState !== ShowCatchReportInputs.Initial)
            return <Button variant="secondary" onClick={showInitial}>Zurück</Button>;
        return <></>;
    }

    function renderCardFooter() {
        if (showState === ShowCatchReportInputs.Initial)
            return <></>;
        return <>
            <Card.Footer>
                {renderBackButton()}
            </Card.Footer>
        </>;
    }

    function showContent() {
        return <>
            <div className="page page-center">
                <div className="container py-4">
                    <div className="m-5 col row justify-content-center">
                        <Card>
                            <Card.Header>
                                <h1>{renderH1()}</h1>
                            </Card.Header>
                            <Card.Body>
                                <h2 className="text-center">
                                    {renderH2()}
                                </h2>
                                {renderContent()}
                            </Card.Body>
                            {renderCardFooter()}
                        </Card>
                    </div>
                </div>
            </div>
        </>;
    }

    function renderContent() {
        if (!organisation)
            return <></>;
        switch (showState) {
            case ShowCatchReportInputs.Initial:
                return renderInitialSelect();
            case ShowCatchReportInputs.Member:
                return renderMemberInputs();
            case ShowCatchReportInputs.Guest:
                return renderGuestInputs();
        }
    }

    function showInitial() {
        setShowState(ShowCatchReportInputs.Initial);
    }

    function showGuest() {
        setShowState(ShowCatchReportInputs.Guest);
    }

    function showMember() {
        setShowState(ShowCatchReportInputs.Member);
    }

    function renderInitialSelect() {
        return <>
            <div className="row">
                <div className="col-sm-6 text-center mb-3">
                    <Button variant="primary" onClick={showGuest}>Als Gastangler</Button>
                </div>
                <div className="col-sm-6 text-center">
                    <Button variant="primary" onClick={showMember}>Als Vereinsmitglied</Button>
                </div>
            </div>
        </>;
    }

    function renderMemberInputs() {
        if (organisation && organisationToken)
            return <CatchReportMemberInputForm organisation={organisation}></CatchReportMemberInputForm>;
        return <>Bitte geben Sie eine Organisation bzw. einen Verein an.</>;
    }

    function renderGuestInputs() {
        if (organisation)
            return <CatchReportGuestInputForm organisation={organisation}></CatchReportGuestInputForm>;
        return <>Bitte geben Sie eine Organisation bzw. einen Verein an.</>;
    }

    return <div>
        {showContent()}
    </div>;
}
