import { useEffect, useId, useState } from "react";
import { FormControl } from "react-bootstrap";
import { Litepicker } from "litepicker";

export default function LitePicker({
  onDateSelected,
  value,
  maxDate = new Date(),
  minDate = undefined,
  disabled = false,
}: {
  onDateSelected: (date: Date) => void;
  value: Date;
  maxDate?: Date | number | undefined;
  minDate?: Date | number | undefined;
  disabled?: boolean;
}) {
  const id = useId();

  const [innerValue, setInnerValue] = useState<string>("");

  useEffect(() => {
    setInnerValue(
      value.toLocaleDateString("de", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
      })
    );
  }, [value]);

  useEffect(() => {
    let lp = new Litepicker({
      autoApply: true,
      element: document.getElementById(id)!,
      format: "DD.MM.YYYY",
      mobileFriendly: true,
      singleMode: true,
      lang: "de",
      maxDate: maxDate,
      minDate: minDate,
      startDate: value,
      setup: (picker) => {
        picker.on("selected", (s, _) => {
          onDateSelected(s.dateInstance);
        });
      },
    });

    return () => {
      lp.destroy();
    };
  }, [id, onDateSelected, maxDate, minDate, value]);

  return (
    <FormControl
      disabled={disabled}
      readOnly={true}
      id={id}
      value={innerValue}
      onChange={(e) => {
        setInnerValue(e.target.value);
      }}
    />
  );
}
